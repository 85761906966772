import dayjs from "dayjs";
import {metaData} from "../config/constants";

export function deliveryTime(zone, page, startDate, endDate, onlyData) {
  const startH = startDate && dayjs(startDate, 'hh:mm a').get('hour')
  const startM = startDate && dayjs(startDate, 'hh:mm a').get('minute')
  const endH = endDate && dayjs(endDate, 'hh:mm a').get('hour')
  const endM = endDate && dayjs(endDate, 'hh:mm a').get('minute')

  const formatType = "MM/DD/YYYY h:mm a"
  if (zone && zone.time && (zone.time !== "0" && zone.time !== "")) {
    let rageType = ""
    let prefixAt = "by"
    let splitedZone = zone.time.split("-")
    if (zone.zone_type === 1) {
      let addedDay = dayjs().add(Number(zone.time),'days')
      let zoneTime = addedDay.format(formatType)
      let start = addedDay.set({hour: startH, minute: startM}).format(formatType);
      let end = addedDay.set({hour: endH, minute: endM}).format(formatType);

      if((dayjs(start).diff(dayjs(zoneTime), 'minute') < 0) && (dayjs(end).diff(dayjs(zoneTime), 'minute') > 0)) {
        rageType = dayjs(zoneTime).format(formatType)
      } else {
        rageType = dayjs(zoneTime).add(1,'days').set({hour: 22, minute: 0}).format(formatType)
      }
    }
    else if (zone.zone_type === 2) {
      let addedMinutes = dayjs().add(Number(Number(splitedZone[1])),'minutes')
      let zoneTimeMinutes = dayjs(addedMinutes).format(formatType)
      let start = addedMinutes.set({hour: startH, minute: startM}).format(formatType);
      let end = addedMinutes.set({hour: endH, minute: endM}).format(formatType);

      if((dayjs(start).diff(dayjs(zoneTimeMinutes), 'minute') < 0) && (dayjs(end).diff(dayjs(zoneTimeMinutes), 'minute') > 0)) {
        rageType = zoneTimeMinutes
      } else {
        rageType = dayjs(zoneTimeMinutes).set({hour: endH, minute: endM})
        if((dayjs(end).diff(dayjs(zoneTimeMinutes), 'minute') < 0) && (dayjs(rageType).diff(dayjs(), "days") === 0) ) {
          rageType.add(1,'days')
        }
        rageType = rageType.format(formatType)
      }
    }  else {
      let addedHour = dayjs().add(Number(Number(splitedZone[1])),'hour')
      let zoneTime = dayjs(addedHour).format(formatType)
      let start = addedHour.set({hour: startH, minute: startM}).format(formatType);
      let end = addedHour.set({hour: endH, minute: endM}).format(formatType);

      if((dayjs(start).diff(dayjs(zoneTime), 'minute') < 0) && (dayjs(end).diff(dayjs(zoneTime), 'minute') > 0)) {
        rageType = zoneTime
      } else {
        rageType = dayjs(zoneTime).set({hour: endH, minute: endM})
        if((dayjs(end).diff(dayjs(zoneTime), 'minute') < 0) && (dayjs(rageType).diff(dayjs(), "days") === 0) ) {
          rageType.add(1,'days')
        }
        rageType = rageType.format(formatType)
      }
    }
    return onlyData ? rageType : ` ${prefixAt} ${rageType}`
  } else {
    return ""
  }

}


export const metaTagData = (metaData.length > 0) ? metaData[0] : false