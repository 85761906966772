
const initialState = { loading: false } ;

export function loadingCheckout(state = initialState, action) {
    switch (action.type) {
        case 'REQUEST_CHECKOUT':
            return {
                loading: true,
            };
        case 'SUCCESS_CHECKOUT':
            return {
                loading: false,
            };
        case 'FAILURE_CHECKOUT':
            return {
                loading: false,
            };
        default:
            return state
    }
}
