import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import StyledHeroPromo from "./HeroPromo.styled";

const HeroPromoFlower = React.memo(props => {
    const { copy } = props;
    return (
        <StyledHeroPromo>
            <div className="hero-promo__wrap">
                <p className="t-promo-display hero-promo__copy"><span className="hero-promo__copy-wrap t-font-Moderat-Bold">{copy}</span> <Link to="/promotions" className='t-font-Moderat-Mono-Regular'>See All Deals</Link></p>
            </div>
        </StyledHeroPromo>
    );
});

HeroPromoFlower.propTypes = {
    copy: PropTypes.array.isRequired,
};

export default HeroPromoFlower;
