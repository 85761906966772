

const charactersSpace = (text1, text2, w = 1, count= 48) => {
    return count - (text1.length + text2.length) * w
}
export const onSendMessageApi = (printParams) => {
    if(printParams){
        // eslint-disable-next-line no-undef
        let builder = new StarWebPrintBuilder();

        let request = '';

        request += builder.createTextElement({characterspace:0});

        request += builder.createAlignmentElement({position:'center'});
        request += builder.createLogoElement({number:1});
        request += builder.createTextElement({data:'Powered by Logickit\n'});
        request += builder.createAlignmentElement({position:'left'});

        request += builder.createTextElement({data:'\n'});
        if(printParams.orderNumber){
            request += builder.createTextElement({data:'Order'});
            request += builder.createTextElement({data: ' '.repeat(charactersSpace("Order", '#' + printParams.orderNumber +'\n'))});
            request += builder.createTextElement({data: '#' + printParams.orderNumber +'\n'});
        }

        if(printParams.orderCreate ){
            request += builder.createAlignmentElement({position:'right'});
            request += builder.createTextElement({data:`Order created ${printParams.orderCreate} \n`});
        }

        request += builder.createAlignmentElement({position:'left'});
        request += builder.createTextElement({data:'\n'});
        if(printParams.retailer){
            request += builder.createTextElement({data:'Retailer'});
            request += builder.createTextElement({data: ' '.repeat(charactersSpace("Retailer", printParams.retailer +'\n'))});
            request += builder.createTextElement({data: printParams.retailer +'\n'});
        }
        request += builder.createTextElement({data:'\n'});
        request += builder.createTextElement({data:'\n'});

        if(printParams.userId){
            request += builder.createTextElement({data:'Customer'});
            request += builder.createTextElement({data: ' '.repeat(charactersSpace("Customer", '#' + printParams.userId +'\n'))});
            request += builder.createTextElement({data: '#' + printParams.userId +'\n'});
        }
        if(printParams.userName && printParams.address){
            request += builder.createAlignmentElement({position:'right'});
            request += builder.createTextElement({data:printParams.userName +'\n'});
            request += builder.createTextElement({data:printParams.address +'\n'});
        }
        request += builder.createAlignmentElement({position:'left'});
        request += builder.createTextElement({data:'\n'});
        request += builder.createTextElement({data: '-'.repeat(48)});
        request += builder.createTextElement({data:'\n'});

        request += builder.createTextElement({data:'Item'});
        request += builder.createTextElement({data: ' '.repeat(charactersSpace("Item", "QTY", 1, 35))});
        request += builder.createTextElement({data: "QTY"});
        request += builder.createTextElement({data: ' '.repeat(charactersSpace("QTY", "Price", 1, 16))});
        request += builder.createTextElement({data: "Price\n"});

        request += builder.createTextElement({data: '-'.repeat(48)});
        request += builder.createTextElement({data:'\n'});


        if(printParams.products){
            printParams.products.forEach(item=>{
                request += builder.createTextElement({data:item.categories});
                request += builder.createTextElement({data: ' '.repeat(charactersSpace(item.categories, item.quantity.toString(), 1, 33))});
                request += builder.createTextElement({data: item.quantity});
                request += builder.createTextElement({data: ' '.repeat(charactersSpace(item.quantity.toString(), `$${item.price}`, 1, 16))});
                request += builder.createTextElement({data: `$${item.price}\n`});
                request += builder.createTextElement({data: item.name + '\n\n'});
            })

        }

        request += builder.createTextElement({data: '-'.repeat(48)});
        request += builder.createTextElement({data:'\n'});

        if(printParams.quantityCount){
            request += builder.createTextElement({data:'Total number of items'});
            request += builder.createTextElement({data: ' '.repeat(charactersSpace("Total number of items",  printParams.quantityCount.toString()))});
            request += builder.createTextElement({data: printParams.quantityCount});
            request += builder.createTextElement({data:'\n'});
        }
        request += builder.createTextElement({data: '-'.repeat(48)});
        request += builder.createTextElement({data:'\n'});

        if(printParams.subtotal > 0){
            request += builder.createTextElement({data:'Subtotal'});
            request += builder.createTextElement({data: ' '.repeat(charactersSpace("Subtotal",   `$${printParams.subtotal}`))});
            request += builder.createTextElement({data: `$${printParams.subtotal}\n`});
        }
        if(printParams.salesTax > 0){
            request += builder.createTextElement({data:'Sales Tax'});
            request += builder.createTextElement({data: ' '.repeat(charactersSpace("Sales Tax",  `$${printParams.salesTax}`))});
            request += builder.createTextElement({data: `$${printParams.salesTax}\n`});
        }
        if(printParams.exciseTax > 0){
            request += builder.createTextElement({data:'Excise Tax'});
            request += builder.createTextElement({data: ' '.repeat(charactersSpace("Excise Tax",  `$${printParams.exciseTax}`))});
            request += builder.createTextElement({data: `$${printParams.exciseTax}\n`});
        }
        // if(printParams.cityTax > 0){
        //     request += builder.createTextElement({data:'City Tax'});
        //     request += builder.createTextElement({data: ' '.repeat(charactersSpace("City Tax",  `$${printParams.cityTax}`))});
        //     request += builder.createTextElement({data: `$${printParams.cityTax}\n`});
        // }
        if(printParams.discount > 0){
            request += builder.createTextElement({data:'Discount'});
            request += builder.createTextElement({data: ' '.repeat(charactersSpace("Discount",  `$${printParams.discount}`))});
            request += builder.createTextElement({data:`$${printParams.discount}\n`});
        }
        if(printParams.payFee > 0){
            request += builder.createTextElement({data:'Payment Processing Fee'});
            request += builder.createTextElement({data: ' '.repeat(charactersSpace("Payment Processing Fee",  `$${printParams.payFee}`))});
            request += builder.createTextElement({data: `$${printParams.payFee}\n`});
        }
        if(printParams.tip > 0){
            request += builder.createTextElement({data:'Tip'});
            request += builder.createTextElement({data: ' '.repeat(charactersSpace("Tip", `$${printParams.tip}`))});
            request += builder.createTextElement({data: `$${printParams.tip}\n`});
        }

        request += builder.createTextElement({data:'\n'});
        request += builder.createTextElement({data:'\n'});

        if(printParams.total){
            request += builder.createTextElement({emphasis:true});
            request += builder.createTextElement({width:2});
            request += builder.createTextElement({data:'Total'});
            request += builder.createTextElement({data: ' '.repeat(charactersSpace("Total",  `$${printParams.total}`, 1, 24))});
            request += builder.createTextElement({data: `$${printParams.total}\n`});
            request += builder.createTextElement({width:1});
            request += builder.createTextElement({emphasis:false});
        }
        request += builder.createTextElement({data:'\n'});
        if(printParams.paymentType){
            request += builder.createTextElement({data:'Payment Type'});
            request += builder.createTextElement({data: ' '.repeat(charactersSpace("Payment Type",  printParams.paymentType))});
            request += builder.createTextElement({data: printParams.paymentType +'\n'});
        }
        request += builder.createTextElement({data: '-'.repeat(48)});
        request += builder.createTextElement({data:'\n'});
        if(printParams.licenseNumber){
            request += builder.createTextElement({data: `License #${printParams.licenseNumber}\n`});
        }

        request += builder.createTextElement({characterspace:0});

        request += builder.createCutPaperElement({feed:true});

        // return  request

        // return s
        return new Promise((resolve, reject) => {resolve(sendMessageApi(request))})
    }

}


export const sendMessageApi = (request) => {
    const ip = JSON.parse(localStorage.getItem('printer'))
    const url = `http://${ip}/StarWebPRNT/SendMessage`

    // eslint-disable-next-line no-undef
    const trader = new StarWebPrintTrader({url:url});
    let responsePrint = {};

    return new Promise((resolve, reject) => {
        trader.onReceive = function (response) {

            let msg = '- onReceive -\n\n';

            msg += 'TraderSuccess : [ ' + response.traderSuccess + ' ]\n';

//      msg += 'TraderCode : [ ' + response.traderCode + ' ]\n';

            msg += 'TraderStatus : [ ' + response.traderStatus + ',\n';
            let error='';

            if (trader.isCoverOpen            ({traderStatus:response.traderStatus})) {msg += '\tCoverOpen,\n'; error= "CoverOpen";}
            if (trader.isOffLine              ({traderStatus:response.traderStatus})) {msg += '\tOffLine,\n';error= "CoverOpen";}
            if (trader.isCompulsionSwitchClose({traderStatus:response.traderStatus})) {msg += '\tCompulsionSwitchClose,\n';error= "Compulsion Switch Close";}
            if (trader.isEtbCommandExecute    ({traderStatus:response.traderStatus})) {msg += '\tEtbCommandExecute,\n';error= "Etb Command Execute";}
            if (trader.isHighTemperatureStop  ({traderStatus:response.traderStatus})) {msg += '\tHighTemperatureStop,\n';error= "High Temperature Stop";}
            if (trader.isNonRecoverableError  ({traderStatus:response.traderStatus})) {msg += '\tNonRecoverableError,\n';error= "Non Recoverable Error";}
            if (trader.isAutoCutterError      ({traderStatus:response.traderStatus})) {msg += '\tAutoCutterError,\n';error= "Auto Cutter Error"}
            if (trader.isBlackMarkError       ({traderStatus:response.traderStatus})) {msg += '\tBlackMarkError,\n';error= "Black Mark Error";}
            if (trader.isPaperEnd             ({traderStatus:response.traderStatus})) {msg += '\tPaperEnd,\n';error= "Paper End";}
            if (trader.isPaperNearEnd         ({traderStatus:response.traderStatus})) {msg += '\tPaperNearEnd,\n';error= "Paper Near End";}

            msg += '\tEtbCounter = ' + trader.extractionEtbCounter({traderStatus:response.traderStatus}).toString() + ' ]\n';

//      msg += 'Status : [ ' + response.status + ' ]\n';
//
//      msg += 'ResponseText : [ ' + response.responseText + ' ]\n';
            if(error){
                responsePrint.status = 'error'
                responsePrint.message = error;
            }
            else{
                responsePrint.status = 'success'
            }


            // alert(msg);
            resolve( responsePrint )


        }

        trader.onError = function (response) {

            let msg = '- onError -\n\n';

            msg += '\tStatus:' + response.status + '\n';

            msg += '\tResponseText:' + response.responseText;
            responsePrint.status = "error";
            responsePrint.message = "Printer connection problem";
            responsePrint.text = response.responseText;
            // responsePrint.status = "error";
            // alert(msg);
            reject( responsePrint )
        }


        trader.sendMessage({request:request})


    });


}
export default onSendMessageApi;