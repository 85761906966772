import { userService } from '../services';
import { GET_NOTIFICATIONS } from '../config/actionTypes';

export const notificationActions = {
    getNotifications
};

function getNotifications() {
    return dispatch => {
        userService.GetInfo('/users/notification', '')
            .then(
                notifications => {
                    dispatch(success(notifications));
                }
            );
    };
    function success(notifications) { return { type: GET_NOTIFICATIONS, notifications } }
}
