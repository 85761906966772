import React, {useEffect} from "react";
import PropTypes from "prop-types";
import StyledMobileModal from "./MobileModal.styled";
import SVGIcon from "../utility/SVGIcon"

const MobileModal = React.memo(props => {
  const { className, shown, modalId, onChange } = props;

  const toggleShown = () => onChange ? onChange(false) :'';

  useEffect(() => {
    shown ? document.body.classList.add("no-scroll") : document.body.classList.remove("no-scroll");
  }, [shown])

  return (
    <StyledMobileModal shown={shown}>
      <div role="dialog" className={`mobile-modal ${className}`}  data-testId={modalId}>
        {onChange &&
        <button onClick={toggleShown} className="mobile-modal__button">
          <SVGIcon name="close" className="mobile-modal__close" />
          <span className="t-hidden">Close modal</span>
        </button>
        }
        <div className="mobile-modal__content" role="document">
          {props.children}
        </div>
      </div>

      <div className="background" onClick={toggleShown}/>
    </StyledMobileModal>
  );
});

MobileModal.propTypes = {
  className: PropTypes.string,
  shown: PropTypes.bool,
  onChange: PropTypes.func,
  modalId: PropTypes.string,

};

export default MobileModal;