import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import StyledBrandSpotlight from "./BrandSpotlight.styled";
import { Link } from "react-router-dom";
import useGAEventTracker from "../../hooks/useGAEventTracker";
import {ThemePramsContext} from "../../App";

const BrandSpotlight = React.memo(props => {
  const { className, heading, subHeading, paragraph, imgSrc, buttonURL, buttonText } = props;
  const [mobileImage, setMobileImage] = useState('');
  const [desktopImage, setDesktopImage] = useState('');
  const GAEventsTracker = useGAEventTracker('Brand Spotlight')
  const {homeConfig: {brandSpotlightConfig}} = useContext(ThemePramsContext);
  useEffect(()=>{
    if(imgSrc){
      const mobile  = imgSrc.filter(item=> item.type === "Mobile")
      const desktop  = imgSrc.filter(item=> item.type === "General")
      desktop && setDesktopImage(desktop[0].image)
      mobile && setMobileImage(mobile[0].image)
    }
  },[imgSrc])
  return (
    <StyledBrandSpotlight
        themeProps={brandSpotlightConfig.styles}
        className={className}
        imgSrc={imgSrc[0]?.image}>
        <div className="brand-spotlight__content-wrap">
          {!brandSpotlightConfig.presentation && <div className="brand-spotlight__img" role="presentation"/>}
          {brandSpotlightConfig.subHeading && <h3 className={`brand-spotlight__subheading ${brandSpotlightConfig.className1}`}>{subHeading}</h3>}
          <h2 className={`brand-spotlight__heading ${brandSpotlightConfig.className2}`}>{heading}</h2>
          <p className={`brand-spotlight__copy ${brandSpotlightConfig.className3}`}>{paragraph}</p>
          <Link className={`brand-spotlight__shop-link ${brandSpotlightConfig.className4}`} to={`/shop?${buttonURL}`} onClick={() => GAEventsTracker(subHeading, `/shop?${buttonURL}`)}>{buttonText}</Link>
        </div>

      {brandSpotlightConfig.presentation && <div className="brand-spotlight__img" role="presentation">
        <img src={mobileImage} className="mobile-banner-image" alt=""/>
        <img src={desktopImage} className="desktop-banner-image" alt=""/>
      </div>}
    </StyledBrandSpotlight>
  );
});

BrandSpotlight.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
  buttonURL: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  imgSrc: PropTypes.array.isRequired,
};

export default BrandSpotlight;