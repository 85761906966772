import {
    CLEAR_RATE,
    GET_USER_ORDER_BY_ID,
    GET_USER_ORDER_BY_ID_SUCCESS,
    GET_USER_ORDERS,
    GET_USER_ORDERS_SUCCESS,
    POST_ORDER_DRIVER_RATE,
    POST_ORDER_DRIVER_RATE_FAIL,
    POST_ORDER_DRIVER_RATE_SUCCESS
} from '../config/actionTypes';

export function userOrders(state = {}, action) {
    switch (action.type) {
        case GET_USER_ORDERS:
            return {
                orders: null,
                loading: true
            };
        case GET_USER_ORDERS_SUCCESS:
            return {
                orders: action.orders,
                loading: false
            };
        default:
            return state
    }
}
export function userOrderById(state = {}, action) {
    switch (action.type) {
        case GET_USER_ORDER_BY_ID:
            return {
                orderById: null,
                loading: true
            };
        case GET_USER_ORDER_BY_ID_SUCCESS:
            return {
                orderById: action.orderById,
                loading: false
            };
        default:
            return state
    }
}
export function orderDriverRate(state = {}, action) {
    switch (action.type) {
        case POST_ORDER_DRIVER_RATE:
            return {
                rate: null,
                loading: true
            };
        case POST_ORDER_DRIVER_RATE_SUCCESS:
            return {
                rate: action.rate,
                loading: false
            };
        case CLEAR_RATE:
            return {
                rate: null,
                loading: false
            };
        case POST_ORDER_DRIVER_RATE_FAIL:
            return {
                err: action.err,
                loading: false
            };
        default:
            return state
    }
}