import { 
        GET_FAST_LINK,
        GET_FAST_LINK_LOADING,
        AEROPAY_USER_BANK_INFO,
        AEROPAY_USER_BANK_INFO_LOADING,
        EMPTY_FAST_LINK,
        EMPTY_AEROPAY_USER_BANK_INFO,
        END_LOADING_CHANGE_AEROPAY,
        START_LOADING_CHANGE_AEROPAY
    } from '../config/actionTypes';

export function fastLink(state = {}, action) {
    switch (action.type) {
        case GET_FAST_LINK:
            return {
                fastLink: action.fastLink,
                loading: action.loading
            };
        case EMPTY_FAST_LINK:
            return {
                fastLink: undefined,
                loading: action.loading
            };
        case GET_FAST_LINK_LOADING:
            return {
                ...state,
                loading: action.loading
            };
        default:
            return state
    };
};

export function accountAggregate(state = {}, action) {
    switch (action.type) {
        case AEROPAY_USER_BANK_INFO:
            return {
                aeropayUserBankInfo: action.bankInfo,
                loading: action.loading
            };
        case EMPTY_AEROPAY_USER_BANK_INFO:
            return {
                aeropayUserBankInfo: undefined,
                loading: action.loading
            };
        case AEROPAY_USER_BANK_INFO_LOADING:
            return {
                aeropayUserBankInfo: null,
                loading: action.loading
            };
        default:
            return state
    };
};
export function loadingChangeAeropay(state = {loading: false}, action) {
    switch (action.type) {
        case START_LOADING_CHANGE_AEROPAY:
            return {
                loading: true
            };
        case END_LOADING_CHANGE_AEROPAY:
            return {
                loading: false
            };
        default:
            return state
    };
};
