import { userService } from '../services';
import { history } from '../helpers';
import {alertActions, globals, locationActions} from './';
import * as toast from "../components/utility/toast";
import {POST_USER_AVATAR, POST_SEND_EMAIL, PATCH_USERS_WALLET_ADDRESS, GET_USER_NFT} from "../config/actionTypes";

export const userActions = {
    subscribeEmail,
    subscribeEmailFooter,
    userAvatarUpload,
    updateUser,
    updateUserOptInMessage,
    postUserLocation,
    updateUserMedicalID,
    sendEmail,
    changeDiscreteDelivery,
    subscribeFirebase,
    patchUserEthAddress,
    getUserNFT,
}

function subscribeEmail(data) {
    return dispatch => {
        userService.PostInfo('/email_subscribe', data)
            .then(
                () => {
                    dispatch(alertActions.success('Success'));
                    history.push('/');
                    toast.success({ text: "Thanks Bud, we promise not to abuse our inbox privileges" })
                },
                error => {
                    if(error.errorCode !== 403){
                        toast.error({ text: error.message })
                    }
                }
            );
    };
}
function subscribeEmailFooter(data) {
    return dispatch => {
        userService.PostInfo('/email_subscribe', data)
            .then(
                () => {
                    dispatch(alertActions.success('Success'));
                    toast.success({ text: "Thanks Bud, we promise not to abuse our inbox privileges" })
                },
                error => {
                    if(error.errorCode !== 403){
                        toast.error({ text: error.message })
                    }
                }
            );

    };
}

function userAvatarUpload(data) {
  return dispatch => {
    userService.PostFile('/attachments/avatar', data)
      .then(
        data => dispatch(success(data))

      ).then(
      () => dispatch(globals.userInfo())
    );
  };
  function success(avatar) { return { type: POST_USER_AVATAR, avatar } }
}


function postUserLocation(data) {
  return dispatch => {
    userService.PostInfo('/locations/selected', data)
      .then(
        () => {
            dispatch(locationActions.selectedLocation());
            dispatch(globals.userInfo())
        }
      );
  };
}

function changeDiscreteDelivery(data) {
  return dispatch => {
    userService.PatchInfo('/users/discrete-delivery', data)
      .then(
        () => dispatch(globals.userInfo()),
        error => {
          if(error.errorCode !== 403){
            dispatch(alertActions.verify_error(error.errors));
          }
        }
      );
  };
}

function updateUser(data) {
  return dispatch => {
    userService.PatchInfo('/users/info', data)
      .then(
        () => dispatch(globals.userInfo()),
        error => {
          if(error.errorCode !== 403){
            dispatch(alertActions.verify_error(error.errors));
          }
        }
      );
  };
}
function updateUserOptInMessage(data) {
  return () => {
    userService.PatchInfo('/users/info', data)
      .then(
        // data => dispatch(globals.userInfo())
      );
  };
}

function updateUserMedicalID(data) {
  return dispatch => {
      return userService.PatchInfo('/users/medical-id', data)
      .then(
        () => dispatch(globals.userInfo())
      );
  };
}

function sendEmail(data) {
  return dispatch => {
    userService.PostInfo('/locations/send-contact', data)
      .then((data) => {
          dispatch(success(data))
          toast.success({ text: "Thank you for your email" })
        }
      );
  };
  function success(data) { return { type: POST_SEND_EMAIL, data } }
}

function subscribeFirebase(data) {
    return dispatch => { return  userService.PutInfo('/firebase/token', data)}
}

function patchUserEthAddress(data) {
  return dispatch => {
    userService.PatchInfo('/users/wallet-address', data)
      .then(
        (data) => {
            dispatch(success(data))
            dispatch(userActions.getUserNFT());
            toast.success({ text: "You have a NFT membership!" })
        }
      );
  };
  function success(data) { return { type: PATCH_USERS_WALLET_ADDRESS, data } }
}

function getUserNFT (data) {
  return dispatch => {
    userService.GetInfo('/users/nft', '')
      .then(
        userNFT => {
            dispatch(success(userNFT))
        }
      );
  };
  function success(userNFT) { return { type: GET_USER_NFT, userNFT } }
};
