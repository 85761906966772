import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import Textra from 'react-textra'
import StyledHeroPromoAnimated from "./HeroPromoAnimated.styled";

const HeroPromoAnimatedFlower = React.memo(({promoBar}) => {
    const promoBarTitlesList = promoBar.map((item => <p className="hero-promo__copy-wrap t-body-small t-font-Moderat-Bold">{item.title}</p>));
    return (
        <StyledHeroPromoAnimated>
            <Textra
                data={promoBarTitlesList}
                effect="simple"
                duration={500}
                stopDuration={7000}
            />
            <Link to="/promotions" className="hero-promo__link t-font-Moderat-Mono-Regular">See All Deals</Link>
        </StyledHeroPromoAnimated>
    );
});

HeroPromoAnimatedFlower.propTypes = {
    promoBar: PropTypes.array.isRequired
};

export default HeroPromoAnimatedFlower;
