import PropTypes from 'prop-types';

export const featuresPT = PropTypes.exact({
  aeropayFee: PropTypes.bool,
  allowSyncMetrcTransfer: PropTypes.bool,
  californiaTaxCalculation: PropTypes.bool,
  cashDrawer: PropTypes.bool,
  customIdUpload: PropTypes.bool,
  debitCardFee: PropTypes.bool,
  disableAeroPay: PropTypes.bool,
  disableCashPayment: PropTypes.bool,
  disablePayTender: PropTypes.bool,
  displayVerification: PropTypes.bool,
  dutchieTax: PropTypes.bool,
  dutchieZones: PropTypes.bool,
  idRequiredForCheckout: PropTypes.bool,
  inventoryUpdate: PropTypes.bool,
  massSms: PropTypes.bool,
  packedStatus: PropTypes.bool,
  paidOrder: PropTypes.bool,
  pushNotifications: PropTypes.bool,
  recreationalFee: PropTypes.bool,
  referralProgram: PropTypes.bool,
  removeExciseTax: PropTypes.bool,
  shipping: PropTypes.bool,
  showOrderSequenceInTheStatusPage: PropTypes.bool,
  enableCannabisAccessoriesTaxCalculation: PropTypes.bool,
  weedmapFee: PropTypes.bool,
});

export const storeInfoPT = PropTypes.exact({
  features: featuresPT,
  logo: PropTypes.string,
  name: PropTypes.string,
  show_footer: PropTypes.bool,
  marketplace_logo: PropTypes.string,
  work_end_at: PropTypes.string,
  work_start_at: PropTypes.string,
});

