import styled from "styled-components";
const StyledModal = styled.div`
    .address-modal {
        display: flex;
        flex-direction: column;
        color: ${({theme}) => theme.colors.brandDark};
    }

    .address-modal__heading {
        margin-bottom: 1rem;
    }
`;
export default StyledModal;