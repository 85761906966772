import enjoyableTheme from "../../styles/themes/EnjoyableTheme";
import {FaChevronDown} from "react-icons/fa";
import React from "react";
import ButtonSecondary from "../../components/ui/ButtonSecondary";
// import MobileMenu from "../../components/MobileMenu/MobileMenu";
// import MainMenu from "../../components/MainMenu/MainMenu";
import ButtonOutline from "../../components/ui/ButtonOutline";
import HeaderDeliveryInputEnjoyable from "../../components/HeaderDeliveryInputEnjoyable/HeaderDeliveryInputEnjoyable";
import flowerTheme from "../../styles/themes/FlowerTheme";
import MobileMenuEverscore from "../../components/MobileMenu/MobileMenuEverscore/MobileMenuEverscore";
import MainMenuEverscore from "../../components/MainMenu/MainMenuEverScore/MainMenuEverScore";
import MainMenuFlower from "../../components/MainMenu/MainMenuFlower/MainMenuFlower";
import MobileMenuFlower from "../../components/MobileMenu/MobileMenuFlower/MobileMenuFlower";
import ButtonPrimary from "../../components/ui/ButtonPrimary";
import rebudTheme from "../../styles/themes/DefaultTheme";
import everscoreTheme from "../../styles/themes/EverscoreTheme";
import Button from "../../components/ui/Button";
import HeaderDeliveryInput from "../../components/HeaderDeliveryInput/HeaderDeliveryInput";

const configEnjoyable = {
    contactUsConfig: {
        background:'',
        colorFonts:'',
        classMainFont: '',
        classNameTitleFont: '',
        classNameDescription: '',
        color: 'brandDark',
        pstText: 'PST',
        mainPageHeadClass1: 't-section-title',
        mainPageHeadClass2: 't-link',
        mainPageHeadClass3: '',
        mainPageHeadClass4: '',
        contactFormFont: '',
        contactFormClass: 't-section-heading',
        buttonFont: '',
        buttonColor: "",
    },
    checkoutConfig: {
        headingClass: 't-section-heading',
        classNameFont: '',
        fontName1:  '',
        iconName1:  'checked',
        iconName2:  'check',
        steps: {
               font1: '',
               font2: 't-subtitle-large',
               font3: '',
               font4: '',
               font5: '',
               font6: '',
               font7: '',
               color1: enjoyableTheme.colors.gray2,
               color2: '',
               color3: enjoyableTheme.colors.brandDark,
               color4: enjoyableTheme.colors.gray3,
               classNameFont1: '',
        },
        simpleModal: {
            color1: enjoyableTheme.colors.white,
            color2: ''
        },
        mainFont1: 't-title-small',
        mainFont2: '',
        mainFont3: '',
        mainFont4: '',
        iconName3: 'delivery-circle-active',
        mainFont5: '',
        iconName4: 'delivery-normal-box',
        mainFont6: '',
        dark: true,
        mainFont7: '',
        mainFont8: '',
        mainFont9: 't-font-lato-regular',
        mainText1: 'Rebud',
        inputAuth1: {
            font: '',
            color1: '#f5f5f5',
            color2: enjoyableTheme.colors.gray5,
            color3: enjoyableTheme.colors.gray2,
            color4: enjoyableTheme.colors.white,
            color5: enjoyableTheme.colors.brandDark,
            color6: enjoyableTheme.colors.gray1,
            color7: enjoyableTheme.colors.gray3,
            color8: enjoyableTheme.colors.brandDark,
            font1: '',
            error: enjoyableTheme.colors.error,
            color9: enjoyableTheme.colors.gray3,
            color10: "#fff",
            color11: enjoyableTheme.colors.gray3,
        },
        dropUpload: {
            color1: '',
            color2: enjoyableTheme.colors.gray1,
            color3: '',
            color4: enjoyableTheme.colors.webGrey4,
            iconName1: 'upload-icon',
            error: enjoyableTheme.colors.webGrey4,
            font: "",
            errorText: enjoyableTheme.colors.newErrorForm  ,
        },
        themeLocationSearchInput: {
            font1: 't-form-input',
            font2: '',
            font3: '',
            zipColor1: enjoyableTheme.colors.white,
            zipColor2: enjoyableTheme.colors.gray3,
            padding: '1.375rem 16px',
            height1: '64px',
            height2: '64px',
            borderRadius: '4px',
            border: `solid 1px ${enjoyableTheme.colors.gray2}`,
            borderBottom: ``,
            font4: '',
            color1: '#ffffff',
            color2: ''
        },
        iconName5: 'store-pickup',
        checkoutStyles: {
            color1: '',
            font1: '',
            color2: '',
            color3: enjoyableTheme.colors.gray4,
            color4: enjoyableTheme.colors.brandDark,
            color5: enjoyableTheme.colors.white,
            color6: enjoyableTheme.colors.brand,
            color7: enjoyableTheme.colors.white,
            color8: '',
            color9: '',
            color10: '',
            color11: enjoyableTheme.colors.webGrey4,
            color12: enjoyableTheme.colors.gray5,
            color13: "#ccc",
            color14: enjoyableTheme.colors.gray1,
            color15: enjoyableTheme.colors.primary2,
            color16: enjoyableTheme.colors.black,
            color17: enjoyableTheme.colors.black,
            color18: enjoyableTheme.colors.brandDark,
            color19: '',
            ontSize1: '',
            color20:  enjoyableTheme.colors.gray3,
            color21: enjoyableTheme.colors.brandDark,
            color22: '',
            color23: enjoyableTheme.colors.brandDark,
            color24: enjoyableTheme.colors.brand,
            color25: '',
            color26: enjoyableTheme.colors.gray3,
            color27: enjoyableTheme.colors.gray4,
            color28: '',
            color29: enjoyableTheme.colors.gray1,
            color30: enjoyableTheme.colors.gray2,
            color31: enjoyableTheme.colors.black,
            color32: enjoyableTheme.colors.white,
            margin: '0 auto',
            overflow: 'initial',
            color33: '#757575',
            color34: enjoyableTheme.colors.brandDark,
            error: enjoyableTheme.colors.error,
            maxWidth: 'initial',
            font2: '',
        },
        spinner: {
            font1: '',
            font2: '',
            color1: ''
        },
        summaryConfig: {
            font1: '',
            font2: '',
            font3: '',
            font4: '',
        },
        priceItemColor: '#222'

    },
    headerConfig:{
        icon1: "enjoyable-watermark",
        icon2: (props) => <FaChevronDown {...props} />,
        className1: 't-header-link',
        icon3: "cart",
        className2: '',
        toggleMenu: true,
        visibleCartIcon: true,
        styling: {
            color5: enjoyableTheme.colors.primary2,
            fontFamily: "",
            borderBottom1: `1px solid ${enjoyableTheme.colors.gray2}`,
            bgColor1: enjoyableTheme.colors.bgColorMain,
            fontSize3: "",
            padding1: "10px 8px 10px 0",
            padding2: "0 32px",
            margin2: "",
            width1: "86px",
            height1: "40px",
            width2: "116px",
            height2: "50px",
            margin: "initial",
            fontSize1: "",
            margin1: "10px",
            positionRight: "-10px",
            positionTop: "0",
            padding3: "4px",
            minWidth: "20px",
            height3: "20px",
            color: enjoyableTheme.colors.bgColorMain,
            bgColor2: "#fff",
            borderRadius: "10px",
            fontSize2: "12px",
            padding4: "8px",
            marginLeft: "20px",
            padding: "1.25rem 12px",
            marginLeft1: "50px",
            bgColor3: enjoyableTheme.colors.background,
            padding5: "",
            alignSelf: "",
        },
        inputSearchAhead: {
            color6: enjoyableTheme.colors.primary2,
            icon: "search",
            button1: (props) => <ButtonSecondary {...props} > SEARCH ALL PRODUCTS </ButtonSecondary>,
            padding: "2rem",
            svgPadding: "8px",
            svgWidth: "22px",
            svgHeight: "22px",
            bgColor: "",
            borderBottom: `solid 1px ${enjoyableTheme.colors.gray4}`,
            inputPositionTop: "50%",
            inputPositionRight: "0",
            color1: enjoyableTheme.colors.gray3,
            color2: enjoyableTheme.colors.gray4,
            color3: "",
            bgColor2: enjoyableTheme.colors.gray1,
            color4: "",
            color5: "",
            bgColor3: "",
        },
        profileDropDownConfig:{
            icon: 'user',
            rewards: false,
            className1: "t-body-small ",
            text: "",
            width: "40px",
            fontFamily: "",
            color: enjoyableTheme.colors.brand,
            bgColor: ""
        },
        headerMainSecondaryConfig:{
            borderBottom: `1px solid ${enjoyableTheme.colors.gray2}`,
            padding: `6px 32px`,
            bgColor4: "#fff",
            headerDeliveryInput: ()=> <HeaderDeliveryInput/>
        },
        mainMenu: (props) => <MainMenuEverscore {...props} />,
        mobileMenu: (props) => <MobileMenuFlower {...props} colorButton />,

    },
    modalAddressConfig: {
        icon1: "enjoyable-watermark-colored",
        icon3: "delivery-circle-active",
        helloText: true,
        verifyText: <div className="t-sub-heading verification__form__label-text">Where should we deliver your stuff?</div>,
        icon2: "closeCircle",
        headingText1: true,
        headingText2: <p className="t-sub-heading verification__form__age-copy">Are you 21+? or 18+ with a medical
            card?</p>,
        color1: "var(--color-everscoreBrandConst)",
        button: (props) => <ButtonOutline  className="verification__age__button" {...props}/>,
        placeholder: '',
        className1: 't-sub-heading',
        className2: '',
        className3: "",
        styling: {
            color10: enjoyableTheme.colors.brand,
            minHeight1: "250px",
            padding1: "2.5rem 16px 1.8125rem",
            minHeight2: "",
            backgroundRepeat: "",
            padding2: "3rem 16px 3.625rem",
            marginBottom1: "1.5rem",
            width1: "140",
            height1: "",
            marginBottom2: "1.5rem",
            marginTop3: "0",
            width2: "240px",
            height2: "",
            paddingBottom1: '6rem',
            color: enjoyableTheme.colors.gray3,
            padding3: "14px 31px",
            bgColor1: enjoyableTheme.colors.white,
            borderColor1: "",
            color1: enjoyableTheme.colors.brandDark,
            fontSize1: "var(--type-scale-16)",
            width: "",
            bgColor2: enjoyableTheme.colors.brand,
            borderColor2: enjoyableTheme.colors.brand,
            bgColor3: enjoyableTheme.colors.white,
            borderColor3: enjoyableTheme.colors.gray3,
            color2: enjoyableTheme.colors.gray3,
            marginTop: ".5rem",
            marginLeft: "16px",
            width3: "",
            fontSize2: "",
            padding: "",
            maxWidth: "512px",
            marginBottom3: "1.5rem",
            marginTop1: "",
            maxWidth1: "",
            marginBottom4: "2rem",
            color3: enjoyableTheme.colors.brandDark,
            color4: "inherit",
            fontWeight: "",
            marginBottom5: "1rem",
            marginBottom6: "1.25rem",
            borderBottom2: "",
            lineHeight: "",
            fontFamily: "",
            fontSize3: "",
            linHeight2: "",
            color5: "#183f31",
            right: "14px",
            top: "1.625rem",
            fill: enjoyableTheme.colors.gray3,
            width4: "inherit",
            height4: "inherit",
            top2: "",
            margin: "0 8px 3% 8px",
            maxWidth2: "724px",
            bgColor: enjoyableTheme.colors.white,
            border: "",
            borderRadius: "",
            maxWidth3: "",
            width5: "100%",
            minHeight3: "",
            width6: "144px",
            minHeight4: "",
            border2: `1px solid ${enjoyableTheme.colors.brandDark}`,
            marginLeft2: "",
            borderRadius2: "",
            color6: enjoyableTheme.colors.white,
            bgColor4: "transparent",
            color7: "",
            border3: "",
            borderColor: enjoyableTheme.colors.brand,
            paddingRight: "40px",
            padding5: "",
            width7: "",
            height: "",
            fontSize: '',
            padding4: "",
            width8: "",
            height5: "",
            borderRadius3: "",
            fontSize5: "",
            margin3: "",
            paddingLeft: "",
            marginBottom: ".75rem",
            marginBottom7: "",
            fontSize4: "",
            fontSize6: "",
            fontSize7: "",
            padding6: "",
            fontSize8: "",
            padding7: "",
            width9: "16px",
            height6: "16px",
            alignSelf: "center",
            top3: "",
            right3: "",
            fill2: "",
            width10: "",
            height7: "",
            width11: "",
            height8: "",
            marginBottom8: "",
            fontSize9: "",
            bgImage: "",
            fontFamily5: "",
            marginLeft3: "",
            fontFamily1: "",
            fontWeight1: "",
            radius: "4px",
            color12: enjoyableTheme.colors.brandDark,
            color13: ""

        }
    },
    cartItemsStyles:{
        color1: enjoyableTheme.colors.webGrey4,
        color2: enjoyableTheme.colors.gray4,
    },
    cartConfig: {
        mainConfig: {
            button1: (props) => <ButtonPrimary {...props} />,
            className1: "t-shelf-heading",
            className2: "t-eyebrow",
            className3: "t-card-copy",
            className4: "",
            className5: "t-body-small",
            className6: "",
            className7: "checkoutBtn",
            dark: false,
            className8: "t-footnote",
            className9: "",
            className10: "",
            promoComponent: true,
        },
        mainStyled:{
            font1: "",
            bgColor: "",
            color1: enjoyableTheme.colors.gray3,
            color2: "",
            bgColor2: "",
            bgColor3: "",
            color3: "",
            color4: enjoyableTheme.colors.gray1,
            color5: "",
            borderBottom: `1px solid ${enjoyableTheme.colors.gray2}`,
            font2: "",
            color6: "",
            color7: enjoyableTheme.colors.gray3,
            color8: enjoyableTheme.colors.gray4,
            bgColor4: enjoyableTheme.colors.gray1,
            color9: enjoyableTheme.colors.brand,
            bgColor5: "",
            color10: "",
            color11: "#888888",
        },
        emptyCart: {
            className1: "",
            className2: "",
            color1: enjoyableTheme.colors.gray3,
        },
        addOnsProps: {
            className1: "t-eyebrow",
            className2: "",
            color1: "",
            color2: enjoyableTheme.colors.gray3,
        },
        addOnsShelfProps: {
            className1: "t-card-copy",
            bgColor: enjoyableTheme.colors.white
        },
        cartItemProps: {
            className1: "",
            className2: "",
            className3: "",
            className4: "t-title-small",
            className5: "t-body-small",
            className6: "t-footnote",
            className7: "",
        },
        productCounterProps: {
            color1: enjoyableTheme.colors.gray2,
            color2: "#fff",
        },
        cartItemPromotionProps: {
            className1: "t-body",
            button: (props) => <ButtonSecondary {...props} />,
            className2: "t-footnote",
            fontFamily: "",
            bgColor: enjoyableTheme.colors.highLightLight,
            bgColor2: enjoyableTheme.colors.brandHighLight,
            color1: "",
            color2: "",

            fill: enjoyableTheme.colors.brandDark,
            bgColor3: "var(--background-color)",
        }
    },
    productDetailsConfig:{
        viewPersonality: false,
        button: (props) => <Button {...props} dark />,
        className1: "t-paragraph-everscore",
        className2: "t-shelf-heading-multishop",
        className3: "t-eyebrow-everscore",
        className4: "t-footer-everscore",
        className5: "tt-footer-everscore",
        className6: "t-eyebrow-everscore",
        className7: "t-shelf-heading-multishop",
        className8: "t-paragraph-everscore",
        className9: "t-lineage-everscore",
        className10: "t-paragraph-everscore",
        className11: "t-shelf-heading-multishop",
        className12: "t-font-Moderat-Medium",
        cardText: "ADD TO BAG",
        productDetailsConfigStyled: {
            font: "var(--font-NHaasGroteskDSPro-45Lt) !important",
            marginTop: "2rem",
            marginBottom: ".5rem",
            margin: "",
            color1: "",
            color2: "",
            color3: "",
            color4: "",
            color5: "",
        },
        isFirst: "Personality",
        productDescriptionDetailsConfigStyled: {
            font: "var(--font-Moderat-Bold)",
            fontWeight: "var(--weight-bold)",
            lineHeight1: "1.17",
            fontSize1: "var(--type-scale-22)",
            lineHeight2: "1.33",
            fontSize2: "var(--type-scale-24)",
            lineHeight3: "",
            letterSpacing: ".5px",
            transition: "var(--button-transition)",
            font2: "var(--font-Moderat-Medium)",
            fontSize3: "var(--type-scale-16)",
            fontSize4: "var(--type-scale-18)",
        },
        productCardConfig:{
            className1: "t-footer-everscore",
            className2: "t-footer-everscore",
            className3: "t-footer-everscore",
            className4: "t-eyebrow-everscore",
            className5: "t-paragraph-everscore",
            className6: "t-shop-link-everscore",
            className7: "",
            className8: "",
            className9: "",
            className10: "t-eyebrow-everscore",
            productCardConfigStyled: {
                color1: "",
                paddingLeft: "",
                paddingRight: "",
                marginRight: "6px",
                color2: enjoyableTheme.colors.gray7,
                borderRadius: "50px",
                font: "var(--font-Moderat-Medium)",
                bgColor1: enjoyableTheme.colors.everscoreGray10,
                borderColor1: enjoyableTheme.colors.brandDark,
                color: enjoyableTheme.colors.white,
                bgColor2: enjoyableTheme.colors.everscoreGray10,
                color3: enjoyableTheme.colors.brandDark,
                lineHeight: 1.8,
                color4: "",
                color5: "",
                color6: "",
                color7: "",
                color8: enjoyableTheme.colors.everscoreGray9,
            },
            cardText: "ADD TO BAG",
            className11: "",
        },

    },
    homeConfig: {
        personalityActive: false,
        dynamicShelfActive: false,
        heroBannerActive:  false,
        brandSpotlightActive:  false,
        productSalesActive:  true,
        staffPicksActive:  true,
        discoverAllActive:  true,
        styles: {
            bgColor: enjoyableTheme.colors.white,
            fontFamily: '',
            gridTemplate: "repeat(auto-fill, minmax(222px, 1fr))",
        },
        heroBanner:{
            bannerText: true,
            styles:{
                display: "grid",
                gridTemplateColumns: "1fr min(1808px, 100%) 1fr",
                marginTop: "1.5rem",
                minHeight: "394px",
                marginTop1: "3rem",
                marginBottom: "3rem",
                marginLeft: "16px",
                width: "calc(100% - 16px)",
                marginLeft1: "32px",
                width1: "calc(100% - 32px)",
                hover: "scale(1.02)",
                maxWidth: "1744px",
            }
        },
        sectionShelfGridConfig: {
            className1: "t-shelf-heading-multishop",
            className2: "t-shelf-link-mono-everscore",
            styles:{
                fontFamily: "PPMori",
                marginTop: "4.813em",
                color: enjoyableTheme.colors.bgColorMain,
                display: "inline-flex",
                paddingBottom: "",
                backgroundImage: ``,
                backgroundPosition: "", /*OR bottom left*/
                backgroundSize: "",
                backgroundRepeat: "",
                transition: "",
                position: "",
                color1: enjoyableTheme.colors.bgColorMain,
                margin1: "8px",
                backgroundPosition1: "",
                backgroundSize1: "",
                textDecoration: "underline",
                marginTop1: "4px",
                borderColor: enjoyableTheme.colors.bgColorMain,
                fontSize: "",
                fontFamily1: "",
                fontSize1: "",
                fontSize2: "",
            }
        },
        brandSpotlightConfig:{
            subHeading: true,
            presentation: true,
            className1: "t-footer-everscore",
            className2: "t-shelf-heading-multishop",
            className3: "t-paragraph-everscore",
            className4: "t-paragraph-everscore",
            styles: {
                bgColor: enjoyableTheme.colors.brandDark,
                marginLeft: "0",
                width: "calc(100% - 32px)",
                maxWidth: "100%",
                marginLeft1: "0",
                padding: "0 0 0 54px",
                maxWidth1: "1744px",
                width1: "calc(100% - 64px)",
                gridTemplateColumns: "2fr 3fr",
                gridColumnGap: "50px",
                marginLeft2: "0",
                maxWidth2: "1744px",
                width2: "100%",
                padding1: "",
                gridColumn: 1,
                color: enjoyableTheme.colors.primary2,
                fontFamily: "",
                textTransform: "",
                paddingBottom: "1.5rem",
                marginBottom: "auto",
                color1: "#fff",
                fontSize: "",
                fontFamily1: "",
                fontSize1: "",
                padding2: "12px 10px",
                maxWidth3: "225px",
                width3: "100%",
                border: `1px solid ${enjoyableTheme.colors.primary2}`,
                borderRadius: "25px",
                fontSize2: "",
                color3: "",
                bgColor1: "",
                width4: "",
                padding3: "",
                fontSize3: "",
                bgColor2: enjoyableTheme.colors.brand,
                gridColumn2: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding4: "4.75rem 0 5.625rem 4.475rem",
                paddingTop: "",
                color2: enjoyableTheme.colors.primary2,
                lineHeight: "24px",
                color4: enjoyableTheme.colors.primary2,
                borderColor: enjoyableTheme.colors.brand,
                color5: enjoyableTheme.colors.white
            }

        },
        brandCardConfig:{
            className1: "t-footer-everscore",
            visibleBrandDesc: true,
            styles: {
                paddingLeft : "8px",
                color: enjoyableTheme.colors.gray3,
                color1: enjoyableTheme.colors.brandDark,
                border: ``,
                borderRadius: "",
                width: "100%",
                margin: "",
                paddingLeft1: "",
                marginLeft1: "",
                fontSize: "18px",
                fontFamily: "",
            }
        }
    },
    privacyConfig:{
        className1: 't-section-heading',
        fontFamily: '',
        className2: 't-section-subheading',
    },
    termsConfig:{
        className1: 't-section-heading',
        fontFamily: '',
        className2: 't-section-subheading',
        className3: 't-font-NHaasGroteskDSPro-65Md',
        className4: 't-font-NHaasGroteskDSPro-65Md',
    },
    orderDetailsConfig: {
        mainConfig: {
            className1: '',
            className2: '',
            className3: '',
        },
        orderRateConfig: {
            logoName: '',
            className1: 't-font-NHaasGroteskDSPro-65Md',
            className2: 't-font-NHaasGroteskDSPro-55Rg',
            button: (props) => <ButtonOutline {...props} />,
        },
        timeLineStyledConfig: {
            bgColor: everscoreTheme.colors.success
        }
    }

}
export default configEnjoyable;