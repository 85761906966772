import React, {useState} from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {BsPlus, BsDash} from "react-icons/bs"

const MobileMainMenuToggle = React.memo(props => {
  const [isOpen, setIsOpen] = useState();
  const toggleElement = (element)=>{
      const el= element.currentTarget.nextElementSibling
      setIsOpen(!isOpen)
      if(!isOpen){
          el.style.height = el.scrollHeight + 'px'
      }
      else{
          el.style.height= '0px'
      }
  }
  const {title, list, toggleMenu, type } = props;
    return (
          <div className="collapse__element">
            <div data-testid="collapse__header" onClick={(e)=>toggleElement(e)} className="collapse__header">
              <h2 className="t-font-DINNextLTPro-Regular">
                {title}
              </h2>
              <span className="plus-minus_icon">{isOpen?<BsDash />:<BsPlus />}</span>
            </div>
            <div className={`collapse__body ${!isOpen? "hidden": ""}`}>
              {list && list.map((item,i)=>{
                return <Link onClick={toggleMenu} className="t-list-copy collapse__item" key={i} to={`/shop?${type}=${item.uri_param}`}>{item.name}</Link>
              })}
              <Link onClick={toggleMenu} className="t-list-copy collapse__item" to={`/shop`}>SHOP ALL</Link>
            </div>

          </div>
    );
});

MobileMainMenuToggle.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  list: PropTypes.array,
  toggleMenu: PropTypes.func
};

export default MobileMainMenuToggle;