import PropTypes from 'prop-types';

const polygonsPT = PropTypes.arrayOf(PropTypes.exact({
  created_at: PropTypes.string,
  delivery_zone_id: PropTypes.string,
  id:  PropTypes.string,
  lat: PropTypes.number,
  lng: PropTypes.number,
  shipped_area_id: PropTypes.string,
  updated_at:  PropTypes.string,
}));

const zonePT = PropTypes.exact({
  active: PropTypes.bool,
  close_hour: PropTypes.string,
  created_at: PropTypes.string,
  deleted_at: PropTypes.string,
  id: PropTypes.string,
  location_id: PropTypes.string,
  name: PropTypes.string,
  open_hour: PropTypes.string,
  time: PropTypes.string,
  updated_at: PropTypes.string,
  zone_type: PropTypes.number,
  polygons: polygonsPT,
});

const deliveryTimesPT = PropTypes.arrayOf(PropTypes.exact({
  id: PropTypes.string,
  name: PropTypes.string,
  start: PropTypes.number,
  next: PropTypes.number,
  end: PropTypes.number,
  store_id: PropTypes.string,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
}));

const klaviyo_metaPT = PropTypes.exact({
  COMPANY_ID: PropTypes.string,
});

const polygonPointsPT = PropTypes.arrayOf(PropTypes.exact({
  id: PropTypes.string,
  lat: PropTypes.number,
  lng: PropTypes.number,
  order: PropTypes.any,
  location_id: PropTypes.string,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
}));

const geoLocationsPT = PropTypes.arrayOf(PropTypes.exact({
  address: PropTypes.string,
  apartment_number: PropTypes.string,
  city: PropTypes.string,
  created_at: PropTypes.string,
  deleted_at: PropTypes.string,
  id: PropTypes.number,
  is_selected: PropTypes.bool,
  lat: PropTypes.number,
  location_related_type: PropTypes.string,
  lon: PropTypes.number,
  state: PropTypes.string,
  updated_at: PropTypes.string,
  zip: PropTypes.string,
}));

export const locationPhonePT = PropTypes.exact({
  id: PropTypes.string,
  name: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  active: PropTypes.bool,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  phone: PropTypes.string,
  logo_file_name: PropTypes.bool,
  logo_content_type: PropTypes.bool,
  logo_file_size: PropTypes.bool,
  logo_updated_at: PropTypes.bool,
  company_name: PropTypes.string,
  review_link: PropTypes.string,
  store_id: PropTypes.string,
  deleted_at: PropTypes.string,
  is_mobile: PropTypes.bool,
  driver_id: PropTypes.number,
  cova_id: PropTypes.number,
  group: PropTypes.string,
  is_default: PropTypes.bool,
  license_number: PropTypes.string,
  email: PropTypes.string,
  wm_id: PropTypes.string,
  wm_catalog_id: PropTypes.string,
  organization_id: PropTypes.string,
  work_start_time: PropTypes.string,
  work_end_time: PropTypes.string,
  is_store_pickup: PropTypes.bool,
  work_start_time_store: PropTypes.string,
  work_end_time_store: PropTypes.string,
  use_location_branding: PropTypes.bool,
  multi_out_for_delivery: PropTypes.bool,
  lat: PropTypes.string,
  lon: PropTypes.string,
  is_online: PropTypes.bool,
  zone: zonePT,
  pay_tender: PropTypes.bool,
  aeropay: PropTypes.bool,
  polygonPoints: polygonPointsPT,
  geolocations: geoLocationsPT,
  klaviyo_meta: klaviyo_metaPT,
  deliveryTimes: deliveryTimesPT,
});

