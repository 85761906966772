import styled from "styled-components";

const StyledMobileMenuButton = styled.button`
  @media (max-width: 859px) {
    padding: 10px;
    width: 18px;
    height: 18px;
    box-sizing: content-box;
    fill: ${({colorButton, theme}) => !colorButton ? theme.colors.black : "#fff"};

    svg {
      width: 18px;
      height: 18px;
      fill: inherit
    }

    &:hover,
    &:focus {
      fill: ${({theme}) => theme.colors.brand};
    }
  }
  @media (min-width: 860px) {
    display: none;
  }

`;
export default StyledMobileMenuButton;