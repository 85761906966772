import styled from "styled-components";

const BrandSpotlight = styled.section`
  align-self: center;
  display: grid;
  flex-direction: column;
  background-color: ${({themeProps}) =>themeProps.bgColor};
  
  @media (max-width: 859px) {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
    margin-left: ${({themeProps}) =>themeProps.marginLeft};
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    width: ${({themeProps}) =>themeProps.width};
    max-width: ${({themeProps}) =>themeProps.maxWidth};
  }
  @media (min-width: 860px) {
    margin-top: 5rem;
    margin-bottom: 5rem;
    margin-left: ${({themeProps}) =>themeProps.marginLeft1};
    padding: ${({themeProps}) =>themeProps.padding};
    max-width: ${({themeProps}) =>themeProps.maxWidth1};
    width: ${({themeProps}) =>themeProps.width1};
    grid-template-columns: ${({themeProps}) =>themeProps.gridTemplateColumns};
    grid-column-gap: ${({themeProps}) =>themeProps.gridColumnGap};
  }

  @media (min-width: 1808px) {
    margin-left: ${({themeProps}) =>themeProps.marginLeft2};
    max-width: ${({themeProps}) =>themeProps.maxWidth2};
    width: ${({themeProps}) =>themeProps.width2};
  }

  .brand-spotlight__content-wrap {
    display: inline-flex;
    flex-direction: column;
    padding: ${({themeProps}) => themeProps.padding1};
    @media (max-width: 859px) {
      display: ${({themeProps}) => themeProps.display};
      justify-content:${({themeProps}) => themeProps.justifyContent};
      align-items: ${({themeProps}) => themeProps.alignItems};
      padding: 2.5rem 16px 4.875rem;
    }
    @media (min-width: 860px) {
      grid-column: ${({themeProps}) => themeProps.gridColumn};
      padding-top: ${({themeProps}) => themeProps.paddingTop};
      padding: ${({themeProps}) => themeProps.padding4};
    }
    @media (max-width: 1200px) {
      padding-top: 1rem;
    }
  }

  .brand-spotlight__subheading {
    color: ${({themeProps}) => themeProps.color2};
    
    @media (max-width: 859px) {
      margin-bottom: 1.25rem;
    }
    @media (min-width: 860px) {
      margin-bottom: 1.5rem;
    }
  }

  .brand-spotlight__heading {
    color: ${({themeProps}) => themeProps.color};
    font-family: ${({themeProps}) => themeProps.fontFamily};
    text-transform: ${({themeProps}) => themeProps.textTransform};
    
    @media (max-width: 859px) {
      margin-bottom: 1.5rem;
    }
    @media (min-width: 860px) {
      margin-bottom: 2rem;
    }
  }

  .brand-spotlight__copy {
    padding-bottom: ${({themeProps}) => themeProps.paddingBottom};
    margin-bottom: ${({themeProps}) => themeProps.marginBottom};
    color: ${({themeProps}) => themeProps.color1};
    font-size: ${({themeProps}) => themeProps.fontSize};
    font-family: ${({themeProps}) => themeProps.fontFamily1};
    line-height: ${({themeProps}) => themeProps.lineHeight};

    @media (max-width: 859px) {
      font-size: ${({themeProps}) => themeProps.fontSize1};
    }
  }

  .brand-spotlight__shop-link {
    font-family: ${({themeProps}) => themeProps.fontFamily1};
    padding: ${({themeProps}) => themeProps.padding2};
    max-width: ${({themeProps}) => themeProps.maxWidth3};
    width: ${({themeProps}) => themeProps.width3};
    color:  ${({themeProps}) => themeProps.color4};
    background-color: transparent;
    border: ${({themeProps}) => themeProps.border};
    transition: var(--button-transition);
    text-decoration: none;
    text-align: center;
    border-radius: ${({themeProps}) => themeProps.borderRadius};
    font-size: ${({themeProps}) => themeProps.fontSize2};
    text-transform: ${({themeProps}) => themeProps.textTransform};
    margin-bottom: 40px;
    @media (max-width: 859px) {
      color: ${({themeProps}) => themeProps.color3};
      background-color: ${({themeProps}) => themeProps.bgColor1};
      width: ${({themeProps}) => themeProps.width4};
      padding: ${({themeProps}) => themeProps.padding3};
      font-size: ${({themeProps}) => themeProps.fontSize3};
    }
    &:hover, &:focus  {
      background-color: ${({themeProps}) => themeProps.bgColor2};
      border-color: ${({themeProps}) => themeProps.borderColor};
      color: ${({themeProps}) => themeProps.color5};
    }
  }

  .brand-spotlight__img {
    max-width: 100%;
    //background-color: ${({ theme }) => theme.colors.gray1};
    //background-image: url("${props => props.imgSrc}");
    //background-size: cover;
    //background-position: left center;
    .desktop-banner-image{
      width: 100%;
    }
    .mobile-banner-image{
      display: none;
    }
    @media(max-width: 700px){
      background-image: none;
      height: auto!important;
      min-height: auto!important;
      .mobile-banner-image{
        display: block;
        width: 100%;
      }
      .desktop-banner-image{
        display: none;
      }
    }
    @media (max-width: 859px) {
      //min-height: 312px;
      grid-row: 2;
    }
    @media (min-width: 860px) {
      //min-height: 572px;
      grid-column: ${({themeProps}) => themeProps.gridColumn2};
    }
  }
`;

export default BrandSpotlight;