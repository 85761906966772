import styled from "styled-components";

const StyledTooltip = styled.div`{
  position: absolute;
  right: -28px;
  top: 0;
  width: 25px;
  &:hover  .tooltiptext {
    display: block;
  }
  .injected-svg{
    width: 20px;
  }
  .tooltiptext {
    width: 175px;
    position: absolute;
    display: none;
    top: 13px;
    left: -87px;
    border-radius: 4px;
    background-color: ${({theme}) => theme.colors.gray2};
    color: ${({theme}) => theme.colors.gray4};
    z-index: 1;
    font-size: 12px;
    text-align: justify;
    margin: auto;
    padding-bottom: 10px;
    @media(max-width: 800px){
      left: -180px;
    }
    h2{
      text-align: center;
      margin-top: 10px;
    }
    p {
      padding: 10px;
      line-height: 1.19;
      text-align: center;
    }
    
  }
  .showTooltip {
    visibility: visible;
  }
  
  .hideTooltip {
    visibility: hidden;
  }
}`

export default StyledTooltip;