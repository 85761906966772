import styled from "styled-components";

const SimpleModal = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.colors.modalBackground};
  z-index: 10;
}


.simple-modal {
    margin-right: 8px;
    margin-left: 8px;
    width: 100%;
    max-width: 700px;
    z-index: 26;
    overflow: auto;
    max-height: calc(100% - 10px);
    position: relative;
  }

  .simple-modal__button {
    position: absolute;
    right: 15px;
    top: 15px;
    fill: black;
    color: ${(props) => props.color2};
    outline: none;

    &:hover,
    &:focus {
      fill: ${({theme}) => theme.colors.brandDark};
    }
  }

  .simple-modal__close {
    height: 20px;
    width: 20px;
    fill: inherit;
  }
  

  .background {
    width: 100vw;
    height: 100vh;
    position: absolute;
    cursor: pointer;
    z-index: 11;
  }
  img{
    max-width: 100%;
    
  }
  
`;

export default SimpleModal;