import io from 'socket.io-client';
import {socketUrl} from '../config/constants'

export const socket = (token) => io(socketUrl, {
  query: {
    token: token
  }
})
// socket.on('connect', function () {
//   console.log('connected!');
// });