import styled from "styled-components";

const FooterMain = styled.footer`
  border-top: ${({theme}) => `3px solid ${theme.colors.white}`};
  font-family: var(--font-DINNextLTPro-MediumCond);

  .footer-wrap {
    background-color: ${({theme}) => theme.colors.bundleBlack};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;

    @media (max-width: 859px) {
      padding: 40px 16px;
    }
    @media (min-width: 860px) {
      padding: 64px 32px;
    }
  }

  .footer-home__heading {
    margin-top: 2.5rem;
    color: ${({theme}) => theme.colors.brandDark};
  }
  
  .footer-home__logo {
    width: 250px;
    height: 180px;
    margin-bottom: 20px;
    margin-left: 30px;

    @media (max-width: 599px) {
      margin-left: 0;
    }
  }

  .footer-home__links {
    width: 100%;
    font-family: var(--font-DINNextLTPro-Regular);

    @media (max-width: 599px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 600px) and (max-width: 859px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 859px) {
      display: block;
      grid-gap: 20px 16px;
      justify-content: center;
    }
    @media (min-width: 860px) {
      max-width: 800px;
      display: flex;
      justify-content: space-between;
    }
  }

  .footer-home__link {
    a {
      color: ${({theme}) => theme.colors.brandDark};

      &:hover,
      &:focus {
        text-decoration: underline;
      }

      @media (min-width: 860px) {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }
    @media (max-width: 859px) {
      text-align: center;
      margin: 12px;
    }
    
    @media (min-width: 860px) {
      display: inline-flex;
    }
  }

  .footer-home__socials {
    padding-right: 32px;
    padding-left: 32px;
    display: flex;
    width: 100%;
    max-width: 900px;
    justify-content: center;
  }

  .footer-home__social {
    display: inline-flex;
    
    a {
      padding: 16px;
      display: flex;
    }

    svg {
      width: 24px;
      height: 24px;
      display: flex;
      flex-shrink: 0;
      fill: ${({theme}) => theme.colors.brandDark};
      transition: var(--button-transition);
    }

    &:hover,
    &:focus {
      svg {
        fill: ${({theme}) => theme.colors.brand}
      }
    }
  }

  .footer-home__affiliate,
  .footer-home__copywrite {
    font-family: var(--font-DINNextLTPro-Regular);
    margin-top: 2rem;
    color: ${({theme}) => theme.colors.brandDark};
    text-align: center;
    
    @media (min-width: 860px) {
      text-align: center;
    }
  }

  .footer-home__affiliate {
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  .footer-home__license {
    font-family: var(--font-DINNextLTPro-Regular);
    margin-top: 0.5rem;
    color: ${({theme}) => theme.colors.brandDark};
    text-align: center;
  }

  .test-logo {
    font-size: 48px;
    color: white;
    margin: 80px 0 32px;
  }
`;

export default FooterMain;