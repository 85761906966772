import React from "react";
import { connect } from "react-redux";
import { modalActions } from "../../actions/index";
import { Modal, ModalBody } from "reactstrap";
import Empty from "./empty.js";

const Popup = React.memo((props) => {
  const components = {
    Empty: Empty,
  };
  let FuncMod = components[props.modals.modal_name || "Empty"];
  return (
    <>
      <Modal
        isOpen={props.modals.open_modal}
        toggle={props.close_modal}
        className="modal-dialog-centered"
      >
        <button
          type="button"
          className="close_mod"
          onClick={props.close_modal}
        ></button>
        <ModalBody>
          <FuncMod />
        </ModalBody>
      </Modal>
    </>
  );
});

function mapStateToProps(state) {
  return {
    modals: state.modals,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    close_modal: () => dispatch(modalActions.close_modal()),
  };
}
const connectedPopup = connect(mapStateToProps, mapDispatchToProps)(Popup);
export { connectedPopup as Popup };
