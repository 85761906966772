import cookie from "react-cookies";
import {userActions} from "../actions";

const sendFirebaseToken = (dispatch) => {
    let cookieFirebase = cookie.load('HDeviceToken');
    if(cookieFirebase){
      dispatch(userActions.subscribeFirebase({firebase_token: cookieFirebase}));
    }
};

export default sendFirebaseToken;
