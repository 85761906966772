import {
    GET_LOCATION_PAGES,
    FAILED_LOCATION_PAGES,
    GET_PICKUP_LOCATIONS,
    GET_CURBSIDE_LOCATIONS,
    GET_SHIPPING_LOCATIONS
} from '../config/actionTypes';

export function locationPages(state = {}, action) {
    switch (action.type) {
        case GET_LOCATION_PAGES:
            return {
                locationPages: action.locationPages,
            };
        case FAILED_LOCATION_PAGES:
            return {
                error: action.error
            };
        default:
            return state
    };
};

export function pickupLocations(state = {}, action) {
    switch (action.type) {
        case GET_PICKUP_LOCATIONS:
            let newLocations;
            if( action?.pickupLocations?.locations?.length > 0){
                newLocations = action?.pickupLocations?.locations.map(item=>{return {...item.geolocations[0], name: item.name}})
            } else newLocations= []

            return {
                pickupLocations: newLocations,
            };
        default:
            return state
    };
};

export function curbsideLocations(state = {}, action) {
    switch (action.type) {
        case GET_CURBSIDE_LOCATIONS:
            let newLocations;
            if( action?.curbsideLocations?.locations?.length > 0){
                newLocations = action?.curbsideLocations?.locations.map(item=>{return {...item.geolocations[0], name: item.name}})
            } else newLocations= []

            return {
                curbsideLocations: newLocations,
            };
        default:
            return state
    };
};
export function shippingLocations(state = {}, action) {
    switch (action.type) {
        case GET_SHIPPING_LOCATIONS:
            let newLocations;
            if( action?.shippingLocations?.locations?.length > 0){
                newLocations = action?.shippingLocations?.locations.map(item=>{return {...item.geolocations[0], name: item.name}})
            } else newLocations= []

            return {
                shippingLocations: newLocations,
            };
        default:
            return state
    };
};

