import { userService } from '../services';
import {
  GET_CATEGORIES,
  GET_CATEGORIES_REQUEST,

  GET_BRANDS,
  GET_BRANDS_REQUEST,

  GET_PRODUCT_TYPES,
  GET_PRODUCT_TYPES_REQUEST,

  GET_PERSONALITY,
  GET_PERSONALITY_REQUEST,

  GET_BRANDS_BY_TYPES,
  GET_BRANDS_BY_TYPES_REQUEST,

  GET_DISCOVER,
  GET_DISCOVER_REQUEST,

  GET_CATEGORIES_ALL,
  GET_BRANDS_ALL,
  GET_PRODUCT_TYPES_ALL,
  GET_PERSONALITY_ALL,
  GET_BRANDS_BY_TYPES_ALL,
  GET_DISCOVERS_ALL,
} from '../config/actionTypes';

export const categoriesFunc = {
    categories: categoriesActions,
    brands,
    productTypes,
    featuredBrands,
    personalities,
    discover,
    categoriesAll,
    brandsAll,
    productTypesAll,
    featuredBrandsAll,
    personalitiesAll,
    discoversAll
};


function categoriesActions(search_criteria) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo('/categories', {search_criteria})
            .then(
                catList => {
                    dispatch(success(catList));
                }
            );
    };
    function success(catList) { return { type: GET_CATEGORIES, catList } }
    function request() { return { type: GET_CATEGORIES_REQUEST } }
}
function brands(search_criteria) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo('/brands/0', {search_criteria})
            .then(
                brandList => {
                    dispatch(success(brandList.list));
                }
            );
    };
    function success(brandList) { return { type: GET_BRANDS, brandList } }
    function request() { return { type: GET_BRANDS_REQUEST } }
}

function featuredBrands(search_criteria) {
    return dispatch => {
      dispatch(request());
        userService.PostInfo('/brands/by-types', {search_criteria})
            .then(
                featuredBrandsList => {
                    dispatch(success(featuredBrandsList));
                }
            );
    };
    function success(featuredBrandsList) { return { type: GET_BRANDS_BY_TYPES, featuredBrandsList } }
    function request() { return { type: GET_BRANDS_BY_TYPES_REQUEST } }
}

function personalities(search_criteria) {
    return dispatch => {
      dispatch(request());
        userService.PostInfo('/products/personalities', {search_criteria})
            .then(
                personalityList => {
                    dispatch(success(
                      personalityList
                    ));
                }
            );
    };
    function success(personalityList) { return { type: GET_PERSONALITY, personalityList } }
    function request() { return { type: GET_PERSONALITY_REQUEST } }
}
function discover(search_criteria) {
    return dispatch => {
      dispatch(request());
        userService.PostInfo('/discover', {search_criteria})
            .then(
                discoversList => {
                    dispatch(success(
                      discoversList
                    ));
                }
            );
    };
    function success(discoversList) { return { type: GET_DISCOVER, discoversList } }
    function request() { return { type: GET_DISCOVER_REQUEST } }
}

function productTypes(search_criteria) {
  return dispatch => {
    dispatch(request());
    userService.PostInfo('/prod_types', {search_criteria})
      .then(
        productTypes => {
          dispatch(success(productTypes));
        }
      );
  };
  function success(productTypes) { return { type: GET_PRODUCT_TYPES, productTypes } }
  function request() { return { type: GET_PRODUCT_TYPES_REQUEST } }
}


// All data

function categoriesAll() {
  return dispatch => {
    userService.PostInfo('/categories', {search_criteria: {}})
      .then(
        catListAll => {
          dispatch(success(catListAll));
        }
      );
  };
  function success(catListAll) { return { type: GET_CATEGORIES_ALL, catListAll } }
}
function brandsAll() {
  return dispatch => {
    userService.PostInfo('/brands/0', {search_criteria: {}})
      .then(
        brandListAll => {
          dispatch(success(brandListAll.list));
        }
      );
  };
  function success(brandListAll) { return { type: GET_BRANDS_ALL, brandListAll } }
}

function featuredBrandsAll() {
  return dispatch => {
    userService.PostInfo('/brands/by-types', {search_criteria: {}})
      .then(
        featuredBrandsListAll => {
          dispatch(success(featuredBrandsListAll));
        }
      );
  };
  function success(featuredBrandsListAll) { return { type: GET_BRANDS_BY_TYPES_ALL, featuredBrandsListAll } }
}

function personalitiesAll() {
  return dispatch => {
    userService.PostInfo('/products/personalities', {search_criteria: {}})
      .then(
        personalityListAll => {
          dispatch(success(
            personalityListAll
          ));
        }
      );
  };
  function success(personalityListAll) { return { type: GET_PERSONALITY_ALL, personalityListAll } }
}

function discoversAll() {
  return dispatch => {
    userService.PostInfo('/discover', {search_criteria: {}})
      .then(
        discoversAll => {
          dispatch(success(discoversAll));
        }
      );
  };
  function success(discoversAll) { return { type: GET_DISCOVERS_ALL, discoversAll } }
}

function productTypesAll() {
  return dispatch => {
    userService.PostInfo('/prod_types', {search_criteria: {}})
      .then(
        productTypesAll => {
          dispatch(success(productTypesAll));
        }
      );
  };
  function success(productTypesAll) { return { type: GET_PRODUCT_TYPES_ALL, productTypesAll } }
}
