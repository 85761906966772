import PropTypes from 'prop-types';

export const productDescriptionPT = PropTypes.exact({
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  description: PropTypes.string,
});

export const productDescriptionsPT = PropTypes.arrayOf(productDescriptionPT);

