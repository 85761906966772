import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledMainMenuCardEverscore = styled(Link)`
  display: flex;
  text-decoration: none;
  align-items: center;
  font-weight: normal;

  &:hover,
  &:focus {
    .main_menu-card__heading {
      text-decoration: underline;
    }
  }

  .main_menu-card__heading {
    color: ${({ theme }) => theme.colors.gray4};
    transition: var(--button-transition);
  }
`;

export default StyledMainMenuCardEverscore;
