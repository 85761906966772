import React from "react";
import PropTypes from "prop-types";
import StyledMobileMainMenuFlowerCard from "./MobileMainMenuFlowerCard.styled";
import MobileMainMenuFlowerToggle from "./MobileMainMenuFlowerToggle";

const MobileMainMenuFlowerCard = React.memo(props => {
  const {heading, list, toggleMenu, type } = props;
    return (
        <StyledMobileMainMenuFlowerCard>
            <MobileMainMenuFlowerToggle
              title={heading}
              list={list}
              toggleMenu={toggleMenu}
              type={type}
            />
        </StyledMobileMainMenuFlowerCard>
    );
});

MobileMainMenuFlowerCard.propTypes = {
  heading: PropTypes.string.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  list: PropTypes.array
};

export default MobileMainMenuFlowerCard;
