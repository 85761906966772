import {
    GET_LEDGER_GREEN_AGREEMENT,
} from '../config/actionTypes';


export function ledgerGreen(state = {}, action) {
    switch (action.type) {
        case GET_LEDGER_GREEN_AGREEMENT:
            return {
                ledgerGreenAgreement: action.data,
            };
        default:
            return state
    };
};
