import React from "react";
import PropTypes from "prop-types";
import StyledMobileMainMenuCardBundle from "./MobileMainMenuCardBundle.styled";
import MobileMainMenuToggleBundle from "./MobileMainMenuToggleBundle";

const MobileMainMenuCardBundle = React.memo(props => {
  const {heading, list, toggleMenu, type } = props;
    return (
        <StyledMobileMainMenuCardBundle>
            <MobileMainMenuToggleBundle
              title={heading}
              list={list}
              toggleMenu={toggleMenu}
              type={type}
            />
        </StyledMobileMainMenuCardBundle>
    );
});

MobileMainMenuCardBundle.propTypes = {
  heading: PropTypes.string.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  list: PropTypes.array
};

export default MobileMainMenuCardBundle;