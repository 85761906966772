import styled from "styled-components";

const StyledHeroPromoAnimated = styled.div`
  display: block;
  justify-content: center;
  text-align: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.gray4};
  background-color: ${({ theme }) => theme.colors.highlight};
  z-index:25;

  @media (max-width: 859px) {
    padding: .5rem 16px;
    z-index:3;
  }
  @media (min-width: 860px) {
    padding: .5rem 32px;
  }

  .hero-promo__copy-wrap {
    margin-bottom: 6px;
    @media (max-width: 859px) {
      margin-bottom: 1px;
    }
  }

  .hero-promo__link {
    font-size: 12px;
    line-height: 1.8;
    display:block;
    width: 100%;
    color: ${({ theme }) => theme.colors.gray4};
    flex-shrink: 0;
    transition: var(--button-transition);
    

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.colors.brand};
      border-color: ${({ theme }) => theme.colors.brand};
    }
  }

`;

export default StyledHeroPromoAnimated;
