import { USER_AUTOCOMPLETE } from '../config/actionTypes';

export function autocomplete(state = {}, action) {
    switch (action.type) {
        case USER_AUTOCOMPLETE:
            return {
                list: action.list,
            };
        default:
            return state
    }
}