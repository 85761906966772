export function alert(state = {}, action) {
  switch (action.type) {
    case 'SUCCESS-ALERT':
      return {
        type: 'alert-success',
        message: action.message
      };
    case 'ERROR-ALERT':
      return {
        type: 'alert-danger',
        message: action.message
      };
     case 'ERROR-ALERT-VERIFY':
      return {
        type: 'alert-danger',
        message: action.message
      };
    case 'CLEAR-ALERT':
      return {};
    default:
      return state
  }
}