import { userService } from '../services';
import { GET_BLOG_POSTS,
         GET_BLOG_POSTS_LOADING,
         GET_BLOG_POSTS_CATEGORIES,
         GET_BLOG_POSTS_CATEGORIES_LOADING,
         GET_BLOG_POST_DETAILS_LOADING,
         GET_BLOG_POST_DETAILS } from '../config/actionTypes';
export const blogActions = {
  getBlogPosts,
  getBlogPostsCategories,
  getBlogPostDetails
};

function getBlogPosts (page_index = 1, categoryId, page_size = 10 ) {
  const params = `?page_index=${page_index}&page_size=${page_size}`;
    return dispatch => {
      dispatch(blogPostsLoading());
        userService.GetInfo(!categoryId ? `/blogs/posts` : `/blogs/posts-by-category/${categoryId}`, params)
          .then(
            blogPosts => {
                dispatch(success(blogPosts.response));
              }
          );
    };
  function blogPostsLoading() { return { type: GET_BLOG_POSTS_LOADING, loading: true } }
  function success(blogPosts) { return { type: GET_BLOG_POSTS, blogPosts, loading: false } }
}

function getBlogPostsCategories() {
  return dispatch => {
      dispatch(blogPostsCategoriesLoading());
      userService.GetInfo('/blogs/categories', '')
          .then(
            blogPostsCategories => {
                  dispatch(success(blogPostsCategories));
              }
          );
  };
  function blogPostsCategoriesLoading() { return { type: GET_BLOG_POSTS_CATEGORIES_LOADING, loading: true } }
  function success(blogPostsCategories) { return { type: GET_BLOG_POSTS_CATEGORIES, blogPostsCategories, loading: false } }
}

function getBlogPostDetails(postId) {
  return dispatch => {
    dispatch(blogPostDetailsLoading());
      userService.GetInfo(`/blogs/post/${postId}`, '')
          .then(
            blogPostDetails => {
                  dispatch(success(blogPostDetails));
              }
          );
  };
  function blogPostDetailsLoading() { return { type: GET_BLOG_POST_DETAILS_LOADING, loading: true } }
  function success(blogPostDetails) { return { type: GET_BLOG_POST_DETAILS, blogPostDetails, loading: false } }
}
