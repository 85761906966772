import React from "react";
import PropTypes from "prop-types";
import MainMenuCardFlowerStyled from "./MainMenuCardFlower.styled";

const MainMenuCardFlower = React.memo(props => {
    const {id, className, heading, type, setMenuOpen } = props;
    const onLinkClick = () => {
        setMenuOpen(false)
    }
    return (
        <MainMenuCardFlowerStyled data-testid="link_click" onClick={onLinkClick} className={className} to={`/shop?${type}=${id}`}>
            <h4 className="main_menu-card__heading t-shelf-link-flower">{heading}</h4>
        </MainMenuCardFlowerStyled>
    );
});

MainMenuCardFlower.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    setMenuOpen: PropTypes.func.isRequired
};

export default MainMenuCardFlower;
