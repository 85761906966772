import React from "react";
import PropTypes from "prop-types";
import StyledMainMenuCard from "./MainMenuCard.styled";

const MainMenuCard = React.memo(props => {
  const {id, className, heading, type, setMenuOpen } = props;
  const onLinkClick = () => {
    setMenuOpen(false)
  }
  return (
    <StyledMainMenuCard data-testid="link_click" onClick={onLinkClick} className={className} to={`/shop?${type}=${id}`}>
      <h4 className="t-list-copy main_menu-card__heading">{heading}</h4>
    </StyledMainMenuCard>
  );
});

MainMenuCard.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  setMenuOpen: PropTypes.func.isRequired
};

export default MainMenuCard;
