import PropTypes from 'prop-types';

export const brandSpotLightPT = PropTypes.exact({
  active: PropTypes.bool,
  body: PropTypes.string,
  button_text: PropTypes.string,
  button_url: PropTypes.string,
  heading: PropTypes.string,
  pictures: PropTypes.arrayOf(PropTypes.string),
  store_id: PropTypes.string,
  subheading: PropTypes.string,
});
