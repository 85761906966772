import PropTypes from 'prop-types';

export const productPT = PropTypes.exact({
  active: PropTypes.bool,
  brand: PropTypes.string,
  brand_id: PropTypes.string,
  categories: PropTypes.string,
  categories_id: PropTypes.string,
  description: PropTypes.string,
  disable_expiration_sale: PropTypes.bool,
  expirationdates: PropTypes.array,
  government_limit: PropTypes.number,
  id: PropTypes.string,
  instructions: PropTypes.string,
  is_canabis: PropTypes.bool,
  is_staff_pick: PropTypes.bool,
  locationaddress: PropTypes.string,
  locationid: PropTypes.string,
  locationname: PropTypes.string,
  name: PropTypes.string,
  net_weight: PropTypes.number,
  net_weight_g: PropTypes.string,
  orders_count: PropTypes.string,
  parent_id: PropTypes.string,
  personalitiesname: PropTypes.string,
  product_cbd: PropTypes.number,
  product_cbd_content: PropTypes.number,
  product_cbg: PropTypes.number,
  product_cbn: PropTypes.number,
  product_delta_8: PropTypes.number,
  product_delta_9: PropTypes.number,
  product_discount_price: PropTypes.number,
  product_discount_quantity: PropTypes.number,
  product_thc: PropTypes.number,
  product_thc_content: PropTypes.number,
  product_thca: PropTypes.number,
  product_type: PropTypes.string,
  product_type_name: PropTypes.string,
  quantity: PropTypes.string,
  sales_discount_percentage: PropTypes.number,
  sales_price: PropTypes.number,
  selling_quantity: PropTypes.number,
  showLowQuantity: PropTypes.bool,
  tasting_notes: PropTypes.arrayOf(PropTypes.string),
  thc: PropTypes.string,
  totalQuantity: PropTypes.number,
  unit_price: PropTypes.number,
  uri_param: PropTypes.string,
  variation: PropTypes.string,
  variation_type: PropTypes.string,
  blob_presence: PropTypes.number,
  children: PropTypes.array,
  pictures: PropTypes.arrayOf(PropTypes.string),
});

export const productsPT = PropTypes.arrayOf(productPT);

