import React, {useEffect, useContext} from "react";
import PropTypes from "prop-types";
import StyledSimpleModal from "./ModalSimpleSnow.styled";
import SVGIcon from "../utility/SVGIcon"
import { aeroPayFastLinkPT } from "../../config/prop-types";
import Image from "../../images/holiday-hours.jpg";

const SimpleModalSnow = React.memo(props => {
  const { className, shown, onChange } = props;
  const toggleShown = () => onChange ? onChange(false) :'';

  useEffect(() => {
    shown ? document.body.classList.add("no-scroll") : document.body.classList.remove("no-scroll");
    return ()=> document.body.classList.remove("no-scroll")
  }, [shown])
  return (
    <StyledSimpleModal
    >
      <div role="dialog" className={`simple-modal snow ${className}`}>
        {onChange &&
          <button onClick={toggleShown} className="simple-modal__button">
            <SVGIcon name="close" className="simple-modal__close" />
            <span className="t-hidden">Close modal</span>
          </button>
        }
        <div className="simple-modal__content" role="document">
          <img src={Image} alt=""/>
        </div>
      </div>
      
      <div className="background" onClick={toggleShown}></div>
    </StyledSimpleModal>
  );
});

SimpleModalSnow.propTypes = {
  className: PropTypes.string,
  shown: PropTypes.bool,
  modalId: PropTypes.string,
  onChange: PropTypes.func,
  aeroPayFastLink: aeroPayFastLinkPT,
};

export default SimpleModalSnow;