import { darken, lighten, rgba } from "polished";
import { colors, sizes } from "./_baseTheme";

const primary = "#1a002d";
const secondary = "#FF8686";
const tertiary = "#171c25";
const enjoyableBrandBright = "#b0ff4c";
const bgColorMain = "#1a002d";
const everscoreBrandConst = "#585858";
const primary2 = "#b0ff4c";
const gray3 = "#1a002d";
const highLightLight = "#b0ff4c";

const mainFont1 = "PPMori,serif";
const mainFont2 = "PPMori,serif";
const everscoreGray10 = "#1a002d";

const enjoyableTheme = {
    colors: {
        ...colors,
        gray3: gray3,
        brandDark: primary,
        bgColorMain: bgColorMain,
        primary2,
        highLightLight,
        everscoreGray10,
        brandLightEverscore: colors.everscoreBrandLight,
        brandLight: colors.white,
        promoBannerBackground: colors.everscoreBrandLight,
        highlight: enjoyableBrandBright,
        error: colors.everscoreWarning,
        warning: colors.everscoreWarning,
        grey2: colors.everscoreGray2,
        grey3: colors.everscoreGray3,
        gray8: colors.everscoreGray8,
        gray9: colors.everscoreGray9,
        accentHybrid: colors.everscoreSale,
        brand: everscoreBrandConst,
        onlyLeftRed: colors.everscoreWarning,
        saleGreen: colors.everscoreSale,
        errorForm: colors.everscoreWarning,

        primary: {
            normal: primary,
            hover: lighten(0.1, primary),
            active: darken(0.1, primary),
            outline: {
                background: rgba(primary, 0.04)
            }
        },
        secondary: {
            normal: secondary,
            hover: lighten(0.1, secondary),
            active: darken(0.1, secondary),
            outline: {
                background: rgba(secondary, 0.04)
            }
        },
        tertiary: {
            normal: tertiary,
            hover: lighten(0.1, tertiary),
            active: darken(0.1, tertiary),
            outline: {
                background: rgba(tertiary, 0.04)
            }
        },
        footerBackground: tertiary,
        shopMobileFilterForm: darken(0.03, tertiary),
    },
    ...sizes,
    mainFonts: {
        mainFont1,
        mainFont2
    }
};

export default enjoyableTheme;
