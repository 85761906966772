import {userService} from "../services";
import {GET_REWARDS_LIST, GET_REWARDS_POINTS, GET_REWARDS_LOYALTY, GET_REWARDS_ALL_LIST} from "../config/actionTypes";

export const rewardsActions = {
    getRewardsList,
    getRewardsAllList,
    getRewardsPoints,
    getRewardsLoyalty
};

function getRewardsList() {
    return dispatch => {
        userService.GetInfo('/rewards/point-list', '')
            .then(
                data => {
                    dispatch(success(data));
                }
            ).catch(
            err => {
                console.log(err)
            }
        );
    };

    function success(data) { return { type: GET_REWARDS_LIST, data, loading: false } }
}
function getRewardsAllList() {
    return dispatch => {
        userService.GetInfo('/rewards/point-list-store', '')
            .then(
                data => {
                    dispatch(success(data));
                }
            ).catch(
            err => {
                console.log(err)
            }
        );
    };

    function success(data) { return { type: GET_REWARDS_ALL_LIST, data, loading: false } }
}
function getRewardsPoints() {
    return dispatch => {
        userService.GetInfo('/rewards/points', '')
            .then(
                data => {
                    dispatch(success(data));
                }
            ).catch(
            err => {
                console.log(err)
            }
        );
    };

    function success(data) { return { type: GET_REWARDS_POINTS, data, loading: false } }
}
function getRewardsLoyalty() {
    return dispatch => {
        userService.GetInfo('/tiers/lists', '')
            .then(
                data => {
                    dispatch(success(data));
                }
            ).catch(
            err => {
                console.log(err)
            }
        );
    };
    function success(data) { return { type: GET_REWARDS_LOYALTY, data, loading: false } }
}