import PropTypes from 'prop-types';

export const summaryPT = PropTypes.exact({
  aeropay_fee: PropTypes.string,
  cashBalancePaid: PropTypes.string,
  cityTaxAmount: PropTypes.string,
  debit_fee: PropTypes.string,
  deliveryCost: PropTypes.string,
  discountAmount: PropTypes.string,
  eta: PropTypes.string,
  exciseTax: PropTypes.string,
  giveawayPrice: PropTypes.string,
  isTaxIncluded: PropTypes.bool,
  locationId: PropTypes.string,
  pay_tender_fee: PropTypes.string,
  paybotic_fee: PropTypes.string,
  salesTax: PropTypes.string,
  salesTaxRate: PropTypes.string,
  showExciseTax: PropTypes.bool,
  subtotal: PropTypes.string,
  tip: PropTypes.string,
  totalCost: PropTypes.string,
  total_taxes: PropTypes.string,
  yotpoWalletAmount: PropTypes.string,
  nftMembership: PropTypes.bool,
});
