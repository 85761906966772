import styled from "styled-components";

const StyledLoadingEllipsis = styled.div`
    display: inline-flex;
    font-size: inherit;
    align-items: center;
    
    .dot {
        display: inline-block;
        height: .5rem;
        fill: ${props => props.color || "inherit"};
        width: ${props => props.size || "1em"};
        height: ${props => props.size || "1em"};
        opacity: 0;
        animation: ellipsis-dot 1s infinite;

        &:not(:first-child) {
            margin-left: .25rem;
        }

        &:nth-child(1) {
            animation-delay: 0.0s;
        }
        &:nth-child(2) {
            animation-delay: 0.1s;
        }
        &:nth-child(3) {
            animation-delay: 0.2s;
        }
    }

    @keyframes ellipsis-dot {
        0% { opacity: 0; }
        50% { opacity: 1; }
        100% { opacity: 0; }
    }
`;
export default StyledLoadingEllipsis;
