import PropTypes from 'prop-types';

export const addOnsPT = PropTypes.arrayOf(PropTypes.exact({
  brand: PropTypes.string,
  brand_id: PropTypes.string,
  categories: PropTypes.string,
  categories_id: PropTypes.string,
  children: PropTypes.array,
  description: PropTypes.string,
  id: PropTypes.string,
  is_canabis: PropTypes.bool,
  is_staff_pick: PropTypes.bool,
  locationaddress: PropTypes.string,
  locationid: PropTypes.string,
  locationname: PropTypes.string,
  name: PropTypes.string,
  net_weight_g: PropTypes.string,
  parent_id: PropTypes.string,
  pictures: PropTypes.arrayOf(PropTypes.string),
  product_cbd: PropTypes.number,
  product_cbd_content: PropTypes.number,
  product_cbg: PropTypes.number,
  product_cbn: PropTypes.number,
  product_delta_8: PropTypes.number,
  product_delta_9: PropTypes.number,
  product_thc: PropTypes.number,
  product_thc_content: PropTypes.number,
  product_thca: PropTypes.number,
  product_type_name: PropTypes.string,
  quantity: PropTypes.string,
  sales_discount_percentage: PropTypes.number,
  sales_price: PropTypes.number,
  selling_quantity: PropTypes.number,
  unit_price: PropTypes.number,
  uri_param: PropTypes.string,
  variation: PropTypes.string,
  variation_type: PropTypes.string,
}));

