import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
// import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

// const loggerMiddleware = createLogger();
let middleware;
if (process.env.NODE_ENV !== 'production') {
    middleware = composeWithDevTools(
      applyMiddleware(
        thunkMiddleware,
        // loggerMiddleware
      )
    )
} else {
    middleware = applyMiddleware(
      thunkMiddleware
    )
}
export const store = createStore(
    rootReducer,
    middleware
);
