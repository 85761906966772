import PropTypes from 'prop-types';

export const carouselPT = PropTypes.arrayOf(PropTypes.exact({
  id: PropTypes.string,
  imagePath: PropTypes.string,
  order: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
}));

