import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import Button from "./Button";
import ButtonOutline from "./ButtonOutline";

const StyledConfirmationSimple = styled.div`
  .confirmation-simple-modal__heading {
    margin-bottom: 2rem;
    color: ${({theme}) => theme.colors.brandDark};
    &.text-center {
      text-align: center;
    }
  }

  .confirmation-simple-modal__buttons {
    display: flex;
    justify-content: center;
    @media (max-width: 480px) {
      flex-wrap: wrap;
      button {
        width: 100%;
        margin: 5px;
      }
    }
  }

  .confirmation-simple-modal__button {
    width: 45%;
    margin: 0 5px;
  }
`;

const ConfirmationSimple = (props) => {
  const { headingText, cancelButtonText, approveButtonText, cancelFunction, approveFunction } = props;

  return (
    <StyledConfirmationSimple>
      <h2 className="confirmation-simple-modal__heading t-section-heading text-center">{headingText}</h2>
      <div className="confirmation-simple-modal__buttons">
        <Button dark className="confirmation-simple-modal__button" onClick={cancelFunction}>{cancelButtonText}</Button>
        <ButtonOutline
          className="confirmation-simple-modal__button"
          onClick={approveFunction}
        >
          {approveButtonText}
        </ButtonOutline>
      </div>
    </StyledConfirmationSimple>
  )
};
ConfirmationSimple.propTypes = {
  headingText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  approveButtonText: PropTypes.string,
  cancelFunction: PropTypes.func,
  approveFunction: PropTypes.func,
};

export default React.memo(props => <ConfirmationSimple {...props} />);
