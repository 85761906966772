import PropTypes from 'prop-types';

export const productDetailsPT = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  parent_id: PropTypes.string,
  product_type: PropTypes.string,
  product_type_name: PropTypes.string,
  brand: PropTypes.exact({
    name: PropTypes.exact({
      contact_person: PropTypes.string,
      created_at: PropTypes.string,
      deleted_at: PropTypes.string,
      description: PropTypes.string,
      email: PropTypes.string,
      id: PropTypes.string,
      img_path: PropTypes.string,
      name: PropTypes.string,
      phone_number: PropTypes.string,
      picture: PropTypes.string,
      show_in_marketplace: PropTypes.bool,
      store_id: PropTypes.string,
      type_id: PropTypes.number,
      updated_at: PropTypes.string,
      uri_param: PropTypes.string,
    })
  }),
  categories: PropTypes.arrayOf(PropTypes.exact({
    description: PropTypes.string,
    id: PropTypes.string,
    milligram: PropTypes.bool,
    name: PropTypes.string,
    parent_id: PropTypes.string,
    uri_param: PropTypes.string,
  })),
  children: PropTypes.array,
  description: PropTypes.string,
  government_limit: PropTypes.number,
  instructions: PropTypes.array,
  is_bundle: PropTypes.bool,
  is_canabis: PropTypes.bool,
  is_staff_pick: PropTypes.bool,
  meta_alt_tag: PropTypes.string,
  meta_description: PropTypes.string,
  meta_title: PropTypes.string,
  net_weight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  net_weight_g: PropTypes.string,
  personalities: PropTypes.arrayOf(PropTypes.exact({
    id: PropTypes.string,
    name: PropTypes.string,
    icon: PropTypes.string,
    image_url: PropTypes.string,
  })),
  pictures: PropTypes.arrayOf(PropTypes.string),
  productExists: PropTypes.bool,
  product_cbd: PropTypes.number,
  product_cbd_content: PropTypes.number,
  product_cbg: PropTypes.number,
  product_cbn: PropTypes.number,
  product_delta_8: PropTypes.number,
  product_delta_9: PropTypes.number,
  product_thc: PropTypes.number,
  product_thc_content: PropTypes.number,
  product_thca: PropTypes.number,
  quantity: PropTypes.string,
  sales_discount_percentage: PropTypes.number,
  sales_price: PropTypes.number,
  showLowQuantity: PropTypes.bool,
  tasting_notes: PropTypes.arrayOf(PropTypes.string),
  unit_price: PropTypes.number,
  variation: PropTypes.string,
  variation_type: PropTypes.string,
  tags: PropTypes.array,
  terpenes: PropTypes.arrayOf(PropTypes.exact({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  })),
});

export const productsPT = PropTypes.arrayOf(productDetailsPT);

