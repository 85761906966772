import PropTypes from 'prop-types';

export const notificationsPT = PropTypes.arrayOf(PropTypes.exact({
  app_user_id: PropTypes.string,
  body: PropTypes.string,
  created_at: PropTypes.string,
  icon_url: PropTypes.string,
  id: PropTypes.string,
  image_url: PropTypes.string,
  location_id: PropTypes.string,
  note_type: PropTypes.string,
  store_id: PropTypes.string,
  title: PropTypes.string,
  updated_at: PropTypes.string,
  user_id: PropTypes.string,
}));

