/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Button from "./Button";
import ButtonOutline from "./ButtonOutline";

const StyledConfirmationTimer = styled.div`
  .kiosk-confirmation-modal__heading {
    margin-bottom: 1.5rem;
    color: ${({theme}) => theme.colors.brandDark};
    &.text-center {
      text-align: center;
    }
    &.timer {
      font-size: 5rem;
      width: 224px;
      margin: 0 auto 1.5rem;
    }
  }

  .kiosk-confirmation-modal__buttons {
    display: flex;
    justify-content: center;
  }

  .kiosk-confirmation-modal__button {
    width: 45%;
    margin: 0 5px;
  }
`;

const ConfirmationTimer = (props) => {
  const { headingText, cancelButtonText, approveButtonText, cancelFunction, approveFunction } = props;
  const [seconds, steSeconds] = useState(30);
  useEffect(() => {
    if(seconds > 0) {
      const timer = setTimeout(() => {
        steSeconds(seconds - 1)
      }, 1000);
      return () => clearTimeout(timer);
    }
    else {
      approveFunction()
    }
  }, [seconds]);
  
  return (
    <StyledConfirmationTimer>
      <h2 className="kiosk-confirmation-modal__heading t-section-heading text-center">{headingText}</h2>
      <h2 className="kiosk-confirmation-modal__heading t-section-heading timer">00:{seconds < 10 ? "0"+seconds: seconds}</h2>
      <div className="kiosk-confirmation-modal__buttons">
        <Button dark className="kiosk-confirmation-modal__button" onClick={cancelFunction}>{cancelButtonText}</Button>
        <ButtonOutline
          className="kiosk-confirmation-modal__button"
          onClick={approveFunction}
        >
          {approveButtonText}
        </ButtonOutline>
      </div>
    </StyledConfirmationTimer>
  )
};
ConfirmationTimer.propTypes = {
  headingText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  approveButtonText: PropTypes.string,
  cancelFunction: PropTypes.func,
  approveFunction: PropTypes.func,
};

export default React.memo(props => <ConfirmationTimer {...props} />);
