import PropTypes from 'prop-types';

export const categoriesPT = PropTypes.arrayOf(PropTypes.exact({
  active: PropTypes.bool,
  backgroundColor: PropTypes.string,
  children: PropTypes.array,
  description: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  parent: PropTypes.string,
  parentId: PropTypes.string,
  pictureContentType: PropTypes.string,
  pictureFileName: PropTypes.string,
  pictureFileSize: PropTypes.string,
  sortId: PropTypes.string,
  storeId: PropTypes.string,
  uri_param: PropTypes.string,
}));

