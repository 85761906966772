import React from "react";
import PropTypes from "prop-types";
import StyledMobileMainMenuCard from "./MobileMainMenuCard.styled";
import MobileMainMenuToggle from "./MobileMainMenuToggle";

const MobileMainMenuCard = React.memo(props => {
  const {heading, list, toggleMenu, type } = props;
    return (
        <StyledMobileMainMenuCard>
            <MobileMainMenuToggle
              title={heading}
              list={list}
              toggleMenu={toggleMenu}
              type={type}
            />
        </StyledMobileMainMenuCard>
    );
});

MobileMainMenuCard.propTypes = {
  heading: PropTypes.string.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  list: PropTypes.array
};

export default MobileMainMenuCard;