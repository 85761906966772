import styled from "styled-components";

const AppStyled = styled.div`
  background-color: ${({theme, isBundleTheme}) => isBundleTheme ? theme.colors.bundleBlack : 'inherit'}; 
  *{
    font-family: ${({selectedTheme}) => selectedTheme === "EnjoyableTheme" ? "PPMori" : ''};
  }
`;

export default AppStyled;
