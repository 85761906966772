import PropTypes from 'prop-types';

const brandsPT = PropTypes.arrayOf(PropTypes.exact({
  id: PropTypes.string,
  name: PropTypes.string,
  store_id: PropTypes.string,
  uri_param: PropTypes.string,
  show_in_marketplace: PropTypes.bool,
  picture: PropTypes.string,
}));

export const featuredBrandPT = PropTypes.arrayOf(PropTypes.exact({
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  name_type: PropTypes.string,
  brands: brandsPT,
}));

