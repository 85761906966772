import { userService } from '../services';
import { USER_AUTOCOMPLETE } from '../config/actionTypes';

export const autocompleteFunc = {
    autocomplete: autocompleteActions
};

function autocompleteActions(query) {
    return dispatch => {
        userService.GetInfo('/search/autocomplete/', query)
            .then(
                list => {
                    dispatch(success(list.list));
                }
            );
    };
    function success(list) { return { type: USER_AUTOCOMPLETE, list } }
}
