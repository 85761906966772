import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const StyledButtonOutline = styled.button`
  padding: 6px 31px;
  border: 1px solid ${({ secondary, theme: { colors } }) => secondary ? colors.gray1 : colors.brandDark};
  background-color: transparent;
  color: ${({ theme: { colors } }) => colors.brandDark};
  font-size: var(--type-scale-18);
  font-weight: var(--weight-bold);
  font-family: var(--font-NHaasGroteskDSPro-55Rg);
  text-align: center;
  flex-shrink: 0;
  transition: var(--button-transition);

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.gray4};
    border-color: ${({ theme }) => theme.colors.gray4};
    color: ${({ theme }) => theme.colors.white};
  }
  &:disabled {
    background-color: transparent;
    border-color: ${({ theme }) => theme.colors.gray4};
    color: ${({ theme }) => theme.colors.gray4};
    cursor: default;
  }

  @media (max-width: 799px) {
      padding: 8px 44px;
      font-size: var(--type-scale-14);
    }
`;

StyledButtonOutline.propTypes = {
  secondary: PropTypes.bool
};

export default React.memo(props => <StyledButtonOutline {...props} />);