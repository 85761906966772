import PropTypes from 'prop-types';

export const bannerGroupPT = PropTypes.arrayOf(PropTypes.exact({
  active: PropTypes.bool,
  backgroundColor: PropTypes.string,
  banner_link: PropTypes.string,
  location_id: PropTypes.string,
  pictures: PropTypes.arrayOf(PropTypes.string),
  redirect_url: PropTypes.string,
  title: PropTypes.string,
}));

