import styled from "styled-components";

const HeaderDeliveryInput = styled.div`
  --svg-size: 20px;
  display: grid;
  width: 100%;

  @media (max-width: 859px) {
    grid-template-columns: 1fr;
  }
  @media (min-width: 860px) {
    grid-template-columns: auto 1fr;
  }

  .icon_theme {
    path {
      fill: ${({theme}) => theme.colors.brandDark};
    }
  }

  .header-delivery__heading {
    padding-top: 0;
    font-size: 22px;
    font-weight: bold;
    text-decoration: underline;

    @media (max-width: 859px) {
      position: absolute !important;
      height: 1px;
      width: 1px;
      overflow: hidden;
      clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
      clip: rect(1px, 1px, 1px, 1px);
      white-space: nowrap; /* added line */
    }

    @media (min-width: 860px) {
      display: inline-flex;
      align-items: center;
      color: ${({theme}) => theme.colors.brandDark};
    }
  }

  .header-delivery__label {
    cursor: pointer;
    margin-right: auto;
    position: relative;
    display: flex;
    align-items: center;

    @media (max-width: 859px) {
      width: 100%;
    }

    &:before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background: transparent;
    }


    &:hover,
    &:focus {
      .header-delivery__icon {
        animation: bobbing 0.3s infinite ease-in-out forwards alternate;
      }
    }
  }

  .header-delivery__icon {
    position: relative;
    width: 18px;
    height: 18px;
    box-sizing: content-box;
    flex-shrink: 0;
    display: inline-flex;
    fill: ${({theme}) => theme.colors.brandDark};
    transition: var(--button-transition);
    transform-origin: center;

    &:before {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      width: 1px;
      height: 36px;
      background-color: ${({theme}) => theme.colors.gray2};
    }

    &.mod {
      width: 65px;
      height: 50px;
    }

    @media (max-width: 859px) {
      width: 40px;
      height: 26px;
    }

    @media (min-width: 860px) {
      width: 42px;
      height: 28px;
    }
  }

  @keyframes bobbing {
    0% {
      transform: rotate(0) translateY(0);
    }

    100% {
      transform: rotate(1deg) translateY(1px);
    }
  }

  .header-delivery__clear {
    width: 16px;
    height: 16px;

    svg {
      fill: ${({theme}) => theme.colors.gray3};
      width: inherit;
      height: inherit;
    }

    &:hover,
    &:focus {
      svg {
        fill: ${({theme}) => theme.colors.brandDark};
      }
    }
  }

  .header-delivery__location-input {
    background-color: ${({theme}) => theme.colors.background};
    color: ${({theme}) => theme.colors.brandDark};
    outline: none;
    border: none;

    &:focus {
      border-color: ${({theme}) => theme.colors.brandDark};
    }

    @media (max-width: 859px) {
      padding: 0 8px;
      width: 100%;
      height: 24px;
    }

    @media (min-width: 860px) {
      padding: 0 16px;
      width: 320px;
      height: 30px;
    }
  }

  .highlight-text {
    margin-right: 22px;

    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    position: relative;
    cursor: pointer;
    transition: var(--button-transition);
    //width: 150px;
    &:hover,
    &:focus {
      background-color: ${({theme}) => theme.colors.highlight};

      .svg-left,
      .svg-right {
        opacity: 1
      }
    }

    .svg-right,
    .svg-left {
      display: block;
      opacity: 0;
      position: absolute;
      top: 0;
      width: var(--svg-size);
      fill: ${({theme}) => theme.colors.highlight};
      transition: var(--button-transition);
    }

    .svg-left {
      left: calc(var(--svg-size) * -1);
    }

    .svg-right {
      right: calc(var(--svg-size) * -1);
    }
  }

  .address-container {
    position: relative;
    margin-left: 15px;
    max-width: 420px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .edit-icon{
      margin-left: 10px;
    }
    h3{
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 2px;
    }
  }
`;

export default HeaderDeliveryInput;
