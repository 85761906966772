/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import StyledModal from "./Modal.styled";
import ModalSimple from "../ModalSimple/ModalSimple";
import StyledHeaderDeliveryInput from "./HeaderDeliveryInput.styled";
import SVGIcon from "../utility/SVGIcon";
import { locationPhonePT }  from "../../config/prop-types";
import {ReactSVG} from "react-svg";
import VerifyModalAddress from "../VerifyModalAddress/VerifyModalAddress";

const HeaderDeliveryInput = React.memo(({dispatch, address, locationPhone}) => {

  const [deliveryLocation, setDeliveryLocation] = useState({
    address: "",
    lat: "",
    lng: "",
    zip: ""
  });

  // Handle deliver address modal
  const [showModal, setShowModal] = useState(false);
  const toggleShowModal = () => setShowModal(!showModal);
  const handleShowModal = (newValue) => {
    toggleShowModal(newValue);
  }

  const myRef = useRef();

  useEffect(() => {
    // dispatch(locationActions.selectedLocation());
    // dispatch(locationActions.locationInfo());
    // dispatch(globals.cartCount());
    // dispatch(categoriesFunc.featuredBrandsAll());
  }, []);

  useEffect(() => {
    if(address){
      const data = {
        ...deliveryLocation,
        address: address.address,
        lat: address.lat,
        lng: address.lng,
        zip: address.zip,
      };
      setDeliveryLocation(data);
      // handleOnSelect(address.address)
    }
  }, [ address ]);


  const [showAddressPopup, setShowAddressPopup] = useState(false)
  return (
    <>
    <StyledHeaderDeliveryInput ref={myRef} onClick={()=>setShowAddressPopup(true)}>
      <div className="highlight-text">
        <SVGIcon className="svg-left" name="filter-svg-left" />
        <p className="header-delivery__heading">
          {address?.delivery_type === 'delivery' && 'Delivery'}
          {address?.delivery_type === 'is_pickup' && 'In-Store'}
          {address?.delivery_type === 'is_curbside' && 'Curbside'}
          {address?.delivery_type === 'shipping' && 'Shipping'}
        </p>
        <SVGIcon className="svg-right" name="filter-svg-right" />
      </div>
        <label className="header-delivery__label" >

          {address?.delivery_type === 'is_curbside' &&
              <ReactSVG src="/images/icons/New-Curbside-Icon.svg" className="header-delivery__icon mod icon_theme"/>
          }
          {address?.delivery_type === 'delivery' &&
              <ReactSVG src="/images/icons/New-Delivery-Icon.svg" className="header-delivery__icon mod icon_theme"/>
          }
          {address?.delivery_type === 'is_pickup' &&
              <ReactSVG src="/images/icons/pickup-icon.svg" className="header-delivery__icon icon_theme"/>
          }
          {address?.delivery_type === 'shipping' &&
              <ReactSVG src="/images/icons/New-Shipping-Icon.svg" className="header-delivery__icon icon_theme"/>
          }
          <div className="address-container">
              <p>
                <h3>{locationPhone?.name}</h3>
                {deliveryLocation.address}
              </p>
              <ReactSVG src="/images/icons/edit-icon.svg" className="edit-icon"/>
            </div>
        </label>
    </StyledHeaderDeliveryInput>
    <StyledModal>
        <ModalSimple shown={showModal} onChange={handleShowModal} className="address-modal">
          <h2 className="address-modal__heading t-section-heading">Sorry, friend....</h2>
          <p className="t-body-medium">We’re not delivering to that location yet.</p>
        </ModalSimple>
    </StyledModal>
      {showAddressPopup && <VerifyModalAddress isShown={showAddressPopup} onChange={setShowAddressPopup} changeAddressModal={true} />}

    </>
  );
});

const mapStateToProps = ({ userAddress, locationInfo, cartCount, featuredBrandAll, categoriesAll, personalityAll }) => ({
  address: userAddress.address,
  locationPhone: locationInfo.locationPhone,
  cartCount: cartCount.count,
  featuredBrand: featuredBrandAll.featuredBrandsListAll,
  categories: categoriesAll.catListAll,
  personality: personalityAll.personalityListAll,
});

HeaderDeliveryInput.propTypes = {
  brandName: PropTypes.string,
  locationPhone: locationPhonePT,
  cartCount: PropTypes.number,
  featuredBrand: PropTypes.array,
  categories: PropTypes.array,
};

export default connect(mapStateToProps)(HeaderDeliveryInput);
