import PropTypes from 'prop-types';

export const userDataPT = PropTypes.exact({
  avatar: PropTypes.string,
  dateOfBirth: PropTypes.string,
  discreteDelivery: PropTypes.bool,
  email: PropTypes.string,
  expirationDate: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  medicalNumberId: PropTypes.string,
  phoneNumber: PropTypes.string,
});

