
export const alertActions = {
    success,
    error,
    verify_error,
    clear
};

function success(message) {
    return { type: 'SUCCESS-ALERT', message };
}

function error(message) {
    return { type: 'ERROR-ALERT', message };
}
function verify_error(message) {
    return { type: 'ERROR-ALERT-VERIFY', message };
}
function clear() {
    return { type: 'CLEAR-ALERT' };
}