import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import { useHistory } from 'react-router-dom';
import StyledMobileMenuBundle from "./MobileMenuBundle.styled";
import StyledMobileMenuButtonBundle from "./MobileMenuButtonBundle.styled";
import SVGIcon from "../utility/SVGIcon";
import MobileMainMenuCardBundle from "../MobileMainMenuCardBundle/MobileMainMenuCardBundle";
import { authActions } from '../../actions/index';

const MobileMenu = React.memo(props => {
  const { featuredBrand, categories, dispatch} = props;
  const [clicked, setClicked] = useState(false);

  // Toggle no-scroll class
  const toggleMenu = () => {
    setClicked (!clicked);
    document.body.classList.remove("no-scroll");
  }
  useEffect(() => {
    clicked && document.body.classList.add("no-scroll");
  })

  // Toggle no-scroll class if route changes to cover edge cases
  const history = useHistory();
  useEffect(() => {
     return history.listen((location) => {
      document.body.classList.remove("no-scroll")
     })
  },[history])

  // Get Login Status for app from local storage
  const LoginStatus = JSON.parse(localStorage.getItem('LoginStatus'));

  return (
    <>
    <StyledMobileMenuButtonBundle onClick={toggleMenu}>
      <SVGIcon name="menu" />
      <span className="t-hidden">open menu</span>
    </StyledMobileMenuButtonBundle>

    <StyledMobileMenuBundle data-menu-state={clicked}>
      <nav className="mobile-menu__nav">
        <div className="mobile-menu__nav__header">
          <button className="mobile-menu__close" onClick={toggleMenu}>
            <SVGIcon name="close" />
            <span className="t-hidden">close menu</span>
          </button>
        </div>

        <div className="mobile-menu__nav__body t-font-DINNextLTPro-MediumCond">

          <div className="shop-all_container">
            <Link className="mobile-menu__shop-all t-body-medium" to="/shop" onClick={toggleMenu}>
              SHOP ALL
            </Link>
            <Link to="/shop" onClick={toggleMenu}>
              <SVGIcon name="arrow-bundle" className="arrow-bundle"/>
            </Link>
          </div>

          {categories && categories.length ?
              <MobileMainMenuCardBundle
                heading="CATEGORY"
                list={categories.slice(0, 9)}
                type='category'
                toggleMenu={toggleMenu}
              />
            :''}
          {featuredBrand && featuredBrand.length ?
            featuredBrand.map(item =>
            item.brands.length > 0 &&
              <MobileMainMenuCardBundle
                  key={item.id}
                  heading="BRAND"
                  list={item.brands}
                  type="brand"
                  toggleMenu={toggleMenu}
              />
              )
          :''}

          <div className="mobile-menu__nav__urls t-font-DINNextLTPro-Regular">
            { LoginStatus ?
            <>
              <Link onClick={toggleMenu} to={"/account"} className="t-list-copy">MY ACCOUNT</Link>
              <Link onClick={toggleMenu} to={"/account/orders"} className="t-list-copy">MY ORDERS</Link>
              <button className="t-list-copy" onClick={()=> dispatch(authActions.logOut()) }>LOG OUT</button>
            </>
            :
            <>
              <Link onClick={toggleMenu} to="/login"  className="t-list-copy">LOG IN</Link>
              <Link onClick={toggleMenu} to="/registration"  className="t-list-copy">REGISTER</Link>
            </>
            }
          </div>
        </div>

        <div className="logo-container">
          <SVGIcon name="bundle-icon-footer" className="footer-logo"/>
        </div>

      </nav>
      
      <div className="mobile-menu__background" onClick={toggleMenu}/>
    </StyledMobileMenuBundle>
    </>
  )
});

MobileMenu.propTypes = {
  handleOpenLocation: PropTypes.func,
  featuredBrand: PropTypes.array,
  specialityAll: PropTypes.array,
};

export default MobileMenu;