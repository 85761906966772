import styled from "styled-components";

const StyledHeaderDeliveryInputEverscore = styled.div`
  display: grid;
  width: 100%;
  
  @media (max-width: 859px) {
    grid-template-columns: 1fr;
  }
  @media (min-width: 860px) {
    grid-template-columns: auto 1fr;
  }

  .header-delivery__heading {
    padding: 2px;
    font-size: 16px;
    font-family: var(--font-NHaasGroteskDSPro-45Lt);

    @media (max-width: 859px) {
      position: absolute !important;
      height: 1px; 
      width: 1px;
      overflow: hidden;
      clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
      clip: rect(1px, 1px, 1px, 1px);
      white-space: nowrap; /* added line */
    }

    @media (min-width: 860px) {
      padding-right: 22px;
      position: relative;
      display: inline-flex;
      align-items: center;
      color: ${({theme}) => theme.colors.brandDark};

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        width: 1px;
        height: 30px;
        background-color: ${({theme}) => theme.colors.gray2};
      }
    }
  }

  .header-delivery__label {
    margin-right: auto;
    position: relative;
    display: flex;
    align-items: center;
    &:before{
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background: transparent;
    }
    @media (max-width: 859px) {
      width: 100%;
    }

    &:hover,
    &:focus {
      .header-delivery__icon {
        animation: bobbing 0.3s infinite ease-in-out forwards alternate;
      }
    }
  }

  .header-delivery__icon {
    width: 18px;
    height: 18px;
    box-sizing: content-box;
    flex-shrink: 0;
    display: inline-flex;
    fill: ${({theme}) => theme.colors.brandDark};
    transition: var(--button-transition);
    transform-origin: center;

    @media (max-width: 859px) {
      width: 40px;
      height: 26px;
    }

    @media (min-width: 860px) {
      margin-left: 14px;
      width: 42px;
      height: 28px;
    }
  }

  @keyframes bobbing {
    0%{
        transform: rotate(0) translateY(0);
    }

    100%{
        transform: rotate(1deg) translateY(1px);
    }
  }

  .header-delivery__clear {
    width: 16px;
    height: 16px;
    position: inherit;
    right: inherit;
    top: inherit;

    svg {
      fill: ${({theme}) => `${theme.colors.gray3}`};
      width: inherit;
      height: inherit;
    }

    @media (max-width: 859px) {
      top: inherit;
      right: inherit;

      svg {
        width: inherit;
        height: inherit;
      }
    }


    &:hover,
    &:focus {
      svg {
        fill: ${({theme}) => theme.colors.brandDark};
      }
    }
  }

  .header-delivery__location-input {
    background-color: ${({theme}) => theme.colors.background};
    color: ${({theme}) => theme.colors.brandDark};
    outline: none;
    border: none;
    font-size: inherit;
    font-family: var(--font-NHaasGroteskDSPro-45Lt);

     &:focus {      
      border-color: ${({theme}) => theme.colors.brandDark};
    }
    
    @media (max-width: 859px) {
      padding: 0 8px;
      font-size: inherit;
      width: 100%;
      height: 24px;
    }

    @media (min-width: 860px) {
      padding: 0 16px;
      width: 320px;
      height: 30px;
      border-radius: inherit;

      &:focus {
        border-bottom: inherit;
      }
    }
  }
`;

export default StyledHeaderDeliveryInputEverscore;
