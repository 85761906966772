import styled from "styled-components";

const FooterMain = styled.section`
  padding: 48px 32px 56px;
  background-color: ${({theme}) => theme.colors.brandDark};
  color: ${({theme}) => theme.colors.brandLight};
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  
  .email-sub__heading {
    margin-bottom: 1rem;
  }

  .email-sub__form {
    margin-top: 1.5rem;
    display: flex;
    width: 100%;

    @media (max-width: 859px) {
      flex-direction: column;
      align-items: center;
      max-width: 400px;
    }
    
    @media (min-width: 860px) {
      max-width: 600px;
    }
  }

  .email-sub__input {
    padding: 0.875rem 16px;
    flex-shrink: 0;
    background-color: ${({ theme }) => theme.colors.brandDark};
    border: 1px solid ${({ theme }) => theme.colors.brandLight};
    color: ${({ theme }) => theme.colors.brandLight};
    font-size: var(--type-scale-16);
    letter-spacing: 1px;
    transition: var(--button-transition);

    @media (max-width: 859px) {
      width: 100%;
    }
    @media (min-width: 860px) {
      width: calc(50% - .5rem);
    }

    &::placeholder {
      opacity: .68;
      color: ${({ theme }) => theme.colors.brandLight};
      font-weight: var(--weight-normal);
      font-family: var(--font-lato);
    }

    &:hover,
    &:focus,
    &:active {
      outline: none;
      background-color: ${({ theme }) => theme.colors.brand};

      .email-sub__svg {
        opacity: 1;
      }
    }
    &:disabled {
      background-color: ${({ theme }) => theme.colors.gray2};
      border: 1px solid ${({ theme }) => theme.colors.gray2};
      color: ${({ theme }) => theme.colors.white};
      cursor: no-drop;
    }
  }

  .email-sub__button {
    padding: 0.875rem 16px;
    flex-shrink: 0;
    text-align: center;
    background-color: ${({ theme }) => theme.colors.brandLight};
    border: 1px solid transparent;
    color: ${({ theme }) => theme.colors.brandDark};
    font-size: var(--type-scale-16);
    font-weight: var(--weight-bold);
    letter-spacing: 1px;
    transition: var(--button-transition);

    &:hover,
    &:focus {
      outline: none;
      color: ${({ theme }) => theme.colors.brandLight};
      background-color: ${({ theme }) => theme.colors.brandDark};
      border-color: ${({ theme }) => theme.colors.brandLight};
    }
    &:disabled {
      background-color: ${({ theme }) => theme.colors.gray2};
      border: 1px solid ${({ theme }) => theme.colors.gray2};
      color: ${({ theme }) => theme.colors.white};
      cursor: no-drop;
    }

    @media (max-width: 859px) {
      margin-top: 1rem;
      width: 100%;
    }
    @media (min-width: 860px) {
      margin-left: 1rem;
      width: calc(50% - .5rem);
    }
  }
`;

export default FooterMain;
