import PropTypes from 'prop-types';
import { productPT } from './productPropTypes';

export const productShopPT = PropTypes.shape({
  list: PropTypes.arrayOf(productPT),
  next: PropTypes.bool,
  page: PropTypes.number,
  pages: PropTypes.number,
  previous: PropTypes.bool,
  total: PropTypes.number,
  searchCriteria: PropTypes.shape({
    total: PropTypes.number,
    product_type_name: PropTypes.arrayOf(PropTypes.string),
    page: PropTypes.number,
    discover: PropTypes.arrayOf(PropTypes.string),
    product_ids: PropTypes.arrayOf(PropTypes.string),
  }),
});

export const productsShopPT = PropTypes.arrayOf(productShopPT);

