import {
    BRAND_LOADING, CHANGE_BRAND_SEARCH,
    GET_BRAND_PRODUCTS,
    LOAD_MORE_BRANDS
} from '../config/actionTypes';


export function brandProducts(state = {}, action) {
    const listChange = (list)=>{
        const newList = list.map(item=>{
            if(item.quantity==="0" && item.children){
                let l;
                item.children.every(item1=>{
                    if(item1.quantity){
                        l = {...item, selectedProduct: item1}
                    } else {
                        return true
                    }
                })
                return l;
            } else  return item
        })
        return newList
    }
    switch (action.type) {
        case GET_BRAND_PRODUCTS:
            return {
                data: {...action.result, list : listChange(action.result.list)},
                loading: action.loading
            };
        case BRAND_LOADING:
            return {
                data: action.result,
                loading: action.loading
            };
        case LOAD_MORE_BRANDS:
            return {
                data: {
                    ...state.data,
                    next: action.result.next,
                    list: state.data.list.concat(listChange(action.result.list))
                },
            };
        case CHANGE_BRAND_SEARCH:
            const list = state.data.list.map(item=>
                {
                    if(action.changeProduct.product && (Number(action.changeProduct.product.parent_id) === Number(item.id) || Number(action.changeProduct.product.id) === Number(item.id))){
                        return {...item, selectedProduct: action.changeProduct.product}
                    }
                    else return item
                }
            );
            return {
                data: {
                    ...state.data,
                    list
                },
            };
        default:
            return state
    }
}

