import PropTypes from 'prop-types';

export const blogPostPT = PropTypes.exact({
    active: PropTypes.bool,
    blog_category_id: PropTypes.string,
    body: PropTypes.string,
    created_at: PropTypes.string,
    id: PropTypes.string,
    is_listed: PropTypes.bool,
    meta_description: PropTypes.string,
    picture: PropTypes.string,
    preview_link: PropTypes.string,
    seo_title: PropTypes.string,
    store_id: PropTypes.string,
    title: PropTypes.string,
    updated_at: PropTypes.string,
    uri_param: PropTypes.string,
});

export const blogPostCategoriesPT = PropTypes.exact({
    created_at: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    store_id: PropTypes.string,
    updated_at: PropTypes.string,
});


