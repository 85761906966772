import {GET_REWARDS_ALL_LIST, GET_REWARDS_LIST, GET_REWARDS_LOYALTY, GET_REWARDS_POINTS,} from "../config/actionTypes";

const initialState = {
    rewardsList: {list:[]},
}
const initialState2 = {
    rewardsAllList: {list:[]},
}

export function rewardsList(state=initialState, action) {
    switch (action.type) {
        case GET_REWARDS_LIST:
            return {
                ...state,
                rewardsList: action.data,
            };
        default:
            return state
    }
}
export function rewardsInfo(state={rewardsInfo: null}, action) {
    switch (action.type) {
        case GET_REWARDS_POINTS:
            return {
                ...state,
                rewardsInfo: action.data,
            };
        default:
            return state
    }
}
export function rewardsLoyalty(state={rewardsLoyalty: null}, action) {
    switch (action.type) {
        case GET_REWARDS_LOYALTY:
            return {
                ...state,
                rewardsLoyalty: action.data,
            };
        default:
            return state
    }
}
export function rewardsAllList(state=initialState2, action) {
    switch (action.type) {
        case GET_REWARDS_ALL_LIST:
            return {
                ...state,
                rewardsAllList: action.data,
            };
        default:
            return state
    }
}