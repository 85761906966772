import styled from "styled-components";

const StyledHeaderMain = styled.header`
    --icon-size: 24px;
    font-family: ${(props) => props.themeProps.fontFamily};
    width: 100%;
    background-color: ${(props) => props.themeProps.bgColor3};
    z-index: 24;
    
    .header-main {
        width: 100%;
        position: relative;
        border-bottom: ${(props) => props.themeProps.borderBottom1};
        @media (min-width: 860px) {
            padding: ${(props) => props.themeProps.padding5};
        }
    }
    
    .header-main__wrap {
        margin-right: auto;
        margin-left: auto;
        width: 100%;
        display: flex;
        align-items: center;
        background-color: ${(props) => props.themeProps.bgColor1};
        font-size: ${(props) => props.themeProps.fontSize3};
        
        @media (max-width: 859px) {
            padding: ${(props) => props.themeProps.padding1};
        }
        @media (min-width: 860px) {
            padding: ${(props) => props.themeProps.padding2};
            justify-content: space-between;
        }
    }

    .header-main__logo {
        display: inline-flex;
        margin: ${(props) => props.themeProps.margin2};
        @media (max-width: 859px) {
            width: ${(props) => props.themeProps.width1};
            height: ${(props) => props.themeProps.height1};
            margin: ${(props) => props.themeProps.margin1};;
        }
        @media (min-width: 860px) {
            width: ${(props) => props.themeProps.width2};
            height: ${(props) => props.themeProps.height2};
        }
    }

    .header-main__logo-other {
        display: inline-flex;
        height: auto;

        @media (max-width: 859px) {
            margin-right: 16px;
            max-height: ${(props) => props.themeProps.height2};
        }
        @media (min-width: 860px) {
            max-height: ${(props) => props.themeProps.height1};
        }
    }

    .header-main__home {
        display: inline-flex;
        text-decoration: none;
    }
    
    .header-main__link {
        padding: ${(props) => props.themeProps.padding};
        color: ${(props) => props.themeProps.color5};
        outline: none;

        @media (max-width: 459px) {
            position: absolute !important;
            height: 1px; 
            width: 1px;
            overflow: hidden;
            clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
            clip: rect(1px, 1px, 1px, 1px);
            white-space: nowrap; /* added line */
        }
        @media (min-width: 860px) {
            margin-left: auto;
        }

    }

    .menu_btn {
        margin-left: ${(props) => props.themeProps.marginLeft1};
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        user-select: none;
        text-decoration: none;

        @media (max-width: 859px) {
            display: none;
        }

        &:hover,
        &:focus {
            color: ${({theme}) => theme.colors.brand};
            cursor: pointer;

            .menu_btn__icon {
                fill: ${({theme}) => theme.colors.brand};
            }
        }
    }
      .title {
        display: flex;
        color: ${({theme}) => theme.colors.bundleWhite};
        font-size: 36px;
        cursor: pointer;
        margin-left: 50px;
    
        @media (max-width: 859px) {
          display: none;
        }
      }
    .menu_btn__icon {
        margin: 2px 4px;
        width: 12px;
        height: 12px;
        fill: ${(props) => props.themeProps.color5};
        flex-shrink: 0;
        transition: transform 0.5s;

        &.isOpen {
            transform: rotate(-180deg);
        }
    }

    .header-main__icons {
        flex-shrink: 0;
        display: flex;
        font-size: ${(props) => props.themeProps.fontSize1};
        @media (max-width: 859px) {
            margin-left: auto;
            margin-right: ${(props) => props.themeProps.margin1};
            align-self: ${(props) => props.themeProps.alignSelf};
        }
    }

    .header-main__search-ahead {
        @media (max-width: 859px) {
            margin-right: 8px;
            display: inline-flex;
        }
        @media (min-width: 860px) {
            max-width: 423px;
        }
    }
    
    .cart-icon {
        position: relative;
        text-decoration: none;
        color: ${({theme}) => theme.colors.brand};
        .cart-count {
            position: absolute;
            right: ${(props) => props.themeProps.positionRight};
            top: ${(props) => props.themeProps.positionTop};
            padding: ${(props) => props.themeProps.padding3};
            min-width: ${(props) => props.themeProps.minWidth};
            height: ${(props) => props.themeProps.height3};
            color: ${(props) => props.themeProps.color};
            background-color: ${(props) => props.themeProps.bgColor2};
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            border-radius: ${(props) => props.themeProps.borderRadius};
            font-size: ${(props) => props.themeProps.fontSize2};
        }
    }

    .header-main__icon-button {
        padding: ${(props) => props.themeProps.padding4};
        display: inline-flex;
        fill: ${(props) => props.themeProps.color5};
        transition: var(--button-transition);

        &:hover,
        &:focus {
            fill: ${({theme}) => theme.colors.brand};
        }

        &:not(:first-child) {

            @media (min-width: 400px) and (max-width: 859px) {
                margin-left: 8px;
            }
            @media (min-width: 860px) {
                margin-left: ${(props) => props.themeProps.marginLeft};
            }
        }

        svg {
            width: var(--icon-size);
            height: var(--icon-size);
            fill: inherit;
        }
        &.close-session {
          @media (max-width: 400px) {
            margin-left: 10px;
          }
        }
    }

    .header-main__user {
        @media (max-width: 859px) {
            display: none;
        }
        @media (min-width: 860px) {
            flex-shrink: 0;
            margin-left: 26px;
        }
    }

    .header-main__user__button {
        fill: ${(props) => props.themeProps.color5};

        &:not(:first-of-type) {
            margin-left: 8px;
        }
    }
`;
export default StyledHeaderMain;
