import React, {useContext} from "react";
import StyledHeaderMainSecondary from "./HeaderMainSecondary.styled";
import {ThemePramsContext} from "../../App";

const HeaderMainSecondary = React.memo(() => {
  const {headerConfig:{headerMainSecondaryConfig}} = useContext(ThemePramsContext);
  return (
    <StyledHeaderMainSecondary
        themeProps={headerMainSecondaryConfig}
    >
      <div className="header-main__wrap" >
          <headerMainSecondaryConfig.headerDeliveryInput/>
      </div>
    </StyledHeaderMainSecondary>
  );
});

export default HeaderMainSecondary;
