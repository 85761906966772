import React, { PureComponent } from "react";
import {selectedTheme} from "../../config/constants";
import HeroPromo from "./HeroPromo";
import HeroPromoFlower from "./HeroPromoFlower";
import PropTypes from "prop-types";

class HeroPromoContainer extends PureComponent {
    render() {
        const {copy, isEverscoreTheme} = this.props;
        switch(selectedTheme) {
            case 'FlowerTheme':
                return <HeroPromoFlower copy={copy} />;
            default:
                return <HeroPromo copy={copy} isEverscoreTheme={isEverscoreTheme} />;
        }
    }
}

HeroPromoContainer.propTypes = {
    copy: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    isEverscoreTheme: PropTypes.bool
};

export default HeroPromoContainer;
