import React from "react";
import PropTypes from "prop-types";
import StyledMobileMainMenuCardEverscore from "./MobileMainMenuCardEverscore.styled";
import MobileMainMenuToggleEverscore from "./MobileMainMenuToggleEverscore";

const MobileMainMenuCardEverscore = React.memo(props => {
  const {heading, list, toggleMenu, type } = props;
    return (
        <StyledMobileMainMenuCardEverscore>
            <MobileMainMenuToggleEverscore
              title={heading}
              list={list}
              toggleMenu={toggleMenu}
              type={type}
            />
        </StyledMobileMainMenuCardEverscore>
    );
});

MobileMainMenuCardEverscore.propTypes = {
  heading: PropTypes.string.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  list: PropTypes.array
};

export default MobileMainMenuCardEverscore;
