import {
    GET_CATEGORIES_REQUEST,
    GET_CATEGORIES,
    GET_DISCOVER,
    GET_DISCOVER_REQUEST,
    GET_BRANDS,
    GET_PRODUCT_TYPES,
    GET_PERSONALITY,
    GET_BRANDS_BY_TYPES,
    GET_DISCOVERS_LIST,
    GET_DISCOVERS_LIST_REQUEST,
    GET_CATEGORIES_ALL,
    GET_BRANDS_ALL,
    GET_PERSONALITY_ALL,
    GET_BRANDS_BY_TYPES_ALL,
    GET_PRODUCT_TYPES_ALL,
    GET_BRANDS_REQUEST,
    GET_PERSONALITY_REQUEST,
    GET_BRANDS_BY_TYPES_REQUEST,
    GET_PRODUCT_TYPES_REQUEST,
    GET_DISCOVERS_ALL,
} from '../config/actionTypes';

export function categories(state = {}, action) {
    switch (action.type) {
        case GET_CATEGORIES_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_CATEGORIES:
            return {
                ...state,
                catList: action.catList,
                loading: false
            };
        default:
            return state
    }
}
export function brands(state = {}, action) {
    switch (action.type) {
        case GET_BRANDS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_BRANDS:
            return {
                ...state,
                brandList: action.brandList,
                loading: false
            };
        default:
            return state
    }
}

export function personality(state = {}, action) {
    switch (action.type) {
        case GET_PERSONALITY_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_PERSONALITY:
            return {
                ...state,
                personalityList: action.personalityList,
                loading: false
            };
        default:
            return state
    }
}
export function discover(state = {}, action) {
    switch (action.type) {
        case GET_DISCOVER_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_DISCOVER:
            return {
                ...state,
                discoversList: action.discoversList,
                loading: false
            };
        default:
            return state
    }
}

export function discoversList(state = {}, action) {
    switch (action.type) {
        case GET_DISCOVERS_LIST_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_DISCOVERS_LIST:
            return {
                ...state,
                discoversList: action.discoversList,
                loading: false
            };
        default:
            return state
    }
}

export function featuredBrand(state = {}, action) {
    switch (action.type) {
        case GET_BRANDS_BY_TYPES_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_BRANDS_BY_TYPES:
            return {
                ...state,
                featuredBrandsList: action.featuredBrandsList,
                loading: false
            };
        default:
            return state
    }
}

export function productTypesList(state = {}, action) {
    switch (action.type) {
        case GET_PRODUCT_TYPES_REQUEST:
            return {
                ...state,
                loading: true
            };
        case GET_PRODUCT_TYPES:
            return {
                ...state,
                list: action.productTypes,
                loading: false
            };
        default:
            return state
    }
}

// All data

export function categoriesAll(state = {}, action) {
    switch (action.type) {
        case GET_CATEGORIES_ALL:
            return {
                catListAll: action.catListAll,
            };
        default:
            return state
    }
}
export function brandsAll(state = {}, action) {
    switch (action.type) {
        case GET_BRANDS_ALL:
            return {
                brandListAll: action.brandListAll,
            };
        default:
            return state
    }
}

export function discoversAll(state = {}, action) {
    switch (action.type) {
        case GET_DISCOVERS_ALL:
            return {
                discoversAll: action.discoversAll,
            };
        default:
            return state
    }
}

export function personalityAll(state = {}, action) {
    switch (action.type) {
        case GET_PERSONALITY_ALL:
            return {
                personalityListAll: action.personalityListAll,
            };
        default:
            return state
    }
}

export function featuredBrandAll(state = {}, action) {
    switch (action.type) {
        case GET_BRANDS_BY_TYPES_ALL:
            return {
                featuredBrandsListAll: action.featuredBrandsListAll,
            };
        default:
            return state
    }
}

export function productTypesListAll(state = {}, action) {
    switch (action.type) {
        case GET_PRODUCT_TYPES_ALL:
            return {
                listAll: action.productTypesAll,
            };
        default:
            return state
    }
}
