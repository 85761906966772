import {
    GET_BEST_SELLERS,
    GET_CAROUSEL,
    GET_STAFF_PICKS,
    GET_HERO_BANNER,
    GET_BRAND_SPOTLIGHT,
    GET_BRANDS_SHELF,
    GET_DYNAMIC_SHELF,
    GET_BRANDS_SEARCH,
    GET_PRODUCTS_SALES,
    GET_PROMO_BAR
} from '../config/actionTypes';

export function homePageCarousel(state = {}, action) {
    switch (action.type) {
        case GET_CAROUSEL:
            return {
                carousel: action.carousel,
            };
        default:
            return state
    }
}

export function homePagePromoBar(state = {}, action) {
    switch (action.type) {
        case GET_PROMO_BAR:
            return {
                promoBar: action.promoBar,
            };
        default:
            return state
    }
}

export function homePageBestSellers(state = {}, action) {
    switch (action.type) {
        case GET_BEST_SELLERS:
            return {
                bestSellers: action.bestSellers,
            };
        default:
            return state
    }
}

export function homePageProductsSales(state = {}, action) {
  switch (action.type) {
      case GET_PRODUCTS_SALES:
          return {
              productsSales: action.productsSales,
          };
      default:
          return state
  }
}

export function homePageStaffPicks(state = {}, action) {
    switch (action.type) {
        case GET_STAFF_PICKS:
            return {
                staffPicks: action.staffPicks,
            };
        default:
            return state
    }
}

export function homePageBrandsShelf(state = {}, action) {
    switch (action.type) {
        case GET_BRANDS_SHELF:
            return {
                brandsShelf: action.shelfBrands
            };
        default:
            return state
    }
}

export function homePageDynamicShelf(state = {}, action) {
    switch (action.type) {
        case GET_DYNAMIC_SHELF:
            return {
                dynamicShelf: action.dynamicShelf
            };
        default:
            return state
    }
}

export function brandsSearch(state = {}, action) {
    switch (action.type) {
        case GET_BRANDS_SEARCH:
            return {
                brandsSearchList: action.list
            };
        default:
            return state
    }
}

export function homePageHeroBanner(state = {}, action) {
    switch (action.type) {
        case GET_HERO_BANNER:
            return {
                heroBanner: action.heroBanner,
            };
        default:
            return state
    }
}

export function homePageBrandSpotlight(state = {}, action) {
    switch (action.type) {
        case GET_BRAND_SPOTLIGHT:
            return {
                brandSpotlight: action.brandSpotlight.brandSpotlights.shift(),
            };
        default:
            return state
    }
}
