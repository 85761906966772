import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {userActions} from '../../../actions/index';
import StyledEmailSubcriptionCTAEverscore from './EmailSubcriptionCTAEverscore.styled'
import {connect} from "react-redux";

const EmailSubcriptionCTAEverscore = props => {
   const {dispatch, message} = props;
   const input = useRef();
   const handleSubscribe = e  => {
        e.preventDefault();
        const { email } = e.target;
        const formData = {
            email: email.value.trim()
        };
        return dispatch(userActions.subscribeEmailFooter(formData));
   };
    useEffect(() => {
        if(message && message === 'Success'){
            input.current.value='';
        }
    }, [message]);
  return (
    <StyledEmailSubcriptionCTAEverscore>
      <h3 className="email-sub__heading">Keep In Touch</h3>
      <p className="email-sub__copy">Subscribe to our newsletter</p>
      <form className="email-sub__form" onSubmit={handleSubscribe} >
        <label className="t-hidden" htmlFor="subEmail">Enter your email address</label>
        <input className="email-sub__input" id="subEmail" name="email" type="email" required placeholder="EMAIL ADDRESS" ref={input} />
        <button type="submit" className="email-sub__button">SIGN UP</button>
      </form>
    </StyledEmailSubcriptionCTAEverscore>
  );
};

const mapStateToProps = ({alert}) => ({
    message: alert.message
});

EmailSubcriptionCTAEverscore.propTypes = {
  message: PropTypes.string,
};

export default connect(mapStateToProps)(EmailSubcriptionCTAEverscore);
