import React, { lazy } from "react";
import { Switch, Route, Redirect } from 'react-router-dom';
import LazyLoad from "./LazyLoad";
import cookie from "react-cookies";
import { storeDomain } from "./constants";

/**
|--------------------------------------------------
| Route Config
|--------------------------------------------------
*/

const Home = lazy(() => import("../containers/Home/HomeContainer"));
const LazyHome = (props) => <LazyLoad component={Home} {...props} />;

const Brands = lazy(() => import("../containers/Brands/Brands"));
const LazyBrands = (props) => <LazyLoad component={Brands} {...props} />;

const Login = lazy(() => import("../containers/LoginEverscore/LoginContainerEverscore"));
const LazyLogin = (props) => <LazyLoad component={Login} {...props} />;

const Registration = lazy(() =>
  import("../containers/RegistrationEverscore/RegistrationContainerEverscore")
);
const LazyRegistration = (props) => (
  <LazyLoad component={Registration} {...props} />
);

const PhoneVerification = lazy(() =>
  import("../containers/PhoneVerificationEverscore/PhoneVerificationContainerEverscore")
);
const LazyPhoneVerification = (props) => (
  <LazyLoad component={PhoneVerification} {...props} />
);

const ResetPassword = lazy(() =>
  import("../containers/ResetPasswordEverscore/ResetPasswordContainerEverscore")
);
const LazyResetPassword = (props) => (
  <LazyLoad component={ResetPassword} {...props} />
);

const ForgotPassword = lazy(() =>
  import("../containers/ForgotPasswordEverscore/ForgotPasswordContainerEverscore")
);
const LazyForgotPassword = (props) => (
  <LazyLoad component={ForgotPassword} {...props} />
);

const ForgotVerification = lazy(() =>
  import("../containers/ForgotVerificationEverscore/ForgotVerificationContainerEverscore")
);
const LazyForgotVerification = (props) => (
  <LazyLoad component={ForgotVerification} {...props} />
);

const Account = lazy(() => import("../containers/AccountEverscore/AccountContainerEverscore"));
const LazyAccount = (props) => <LazyLoad component={Account} {...props} />;

const AccountOrders = lazy(() =>
  import("../containers/AccountEverscore/AccountOrdersContainerEverscore")
);
const LazyAccountOrders = (props) => (
  <LazyLoad component={AccountOrders} {...props} />
);

const AccountOrderDetails = lazy(() =>
  import("../containers/AccountEverscore/AccountOrderDetailsContainerEverscore")
);
const LazyAccountOrderDetails = (props) => (
  <LazyLoad component={AccountOrderDetails} {...props} />
);

const AccountOrderCanceling = lazy(() =>
  import("../containers/AccountEverscore/AccountOrderCancelingContainer")
);
const LazyAccountOrderCanceling = (props) => (
  <LazyLoad component={AccountOrderCanceling} {...props} />
);

const Faq = lazy(() => import("../containers/Faq/FaqContainer"));
const LazyFaq = (props) => <LazyLoad component={Faq} {...props} />;

const ContactUs = lazy(() =>
    import("../containers/ContactUs/ContactUsContainer")
);
const LazyContactUs = (props) => <LazyLoad component={ContactUs} {...props} />;

const AboutUs = lazy(() => import("../containers/AboutUs/AboutUsContainer"));
const LazyAboutUs = (props) => <LazyLoad component={AboutUs} {...props} />;

const ProductPage = lazy(() =>
  import("../containers/ProductPage/ProductPageContainer")
);
const LazyProductPage = (props) => (
  <LazyLoad component={ProductPage} {...props} />
);
const BrandPage = lazy(() =>
  import("../containers/BrandPage/BrandPage")
);
const LazyBrandPage = (props) => (
  <LazyLoad component={BrandPage} {...props} />
);

const Cart = lazy(() => import("../containers/Cart/CartContainer"));
const LazyCart = (props) => <LazyLoad component={Cart} {...props} />;

const Shop = lazy(() => import("../containers/ShopEverscore/ShopEverscore"));
const LazyShop = (props) => <LazyLoad component={Shop} {...props} />;

const Checkout = lazy(() => import("../containers/Checkout/CheckoutContainer"));
const LazyCheckout = (props) => <LazyLoad component={Checkout} {...props} />;

const Success = lazy(() => import("../containers/Success/SuccessContainer"));
const LazySuccess = (props) => <LazyLoad component={Success} {...props} />;

const EmailVerification = lazy(() =>
  import("../containers/EmailVerificationEverscore/EmailVerificationContainerEverscore")
);
const LazyEmailVerification = (props) => (
  <LazyLoad component={EmailVerification} {...props} />
);

const Promotions = lazy(() =>
  import("../containers/Promotions/PromotionsContainer")
);
const LazyPromotions = (props) => (
  <LazyLoad component={Promotions} {...props} />
);

const TermsConditions = lazy(() =>
    import("../containers/TermsConditions/TermsConditionsContainer")
);
const LazyTermsConditions = (props) => (
  <LazyLoad component={TermsConditions} {...props} />
);

const Partnerships = lazy(() => import("../containers/Partnerships/Partnerships"));
const LazyPartnerships = (props) => <LazyLoad component={Partnerships} {...props} />;

const Privacy = lazy(() => import("../containers/Privacy/PrivacyContainer"));
const LazyPrivacy = (props) => <LazyLoad component={Privacy} {...props} />;

const NotFound = lazy(() => import("../containers/NotFound/NotFoundContainer"));
const LazyNotFound = (props) => <LazyLoad component={NotFound} {...props} />;

const PaytenderSuccess = lazy(() => import("../containers/Paytender/Success/SuccessContainer"));
const LazyPaytenderSuccess = (props) => <LazyLoad component={PaytenderSuccess} {...props} />;

const Careers = lazy(() => import("../containers/Careers/CareersContainer"));
const LazyCareers = (props) => <LazyLoad component={Careers} {...props} />;

const DebitSuccess = lazy(() => import("../containers/Debit/Success/SuccessContainer"));
const LazyDebitSuccess = (props) => <LazyLoad component={DebitSuccess} {...props} />;

const Notifications = lazy(() => import("../containers/Notifications/NotificationsContainer"));
const LazyNotifications = (props) => <LazyLoad component={Notifications} {...props} />;

const Rewards = lazy(() => import("../containers/Rewards/RewardsContainer"));
const LazyRewards = (props) => <LazyLoad component={Rewards} {...props} />;

const Blog = lazy(() => import("../containers/Blog/BlogContainer"));
const LazyBlog = (props) => <LazyLoad component={Blog} {...props} />;

const BlogPostDetails = lazy(() => import("../containers/Blog/BlogPostDetails/BlogPostDetailsContainer"));
const LazyBlogPostDetails = (props) => <LazyLoad component={BlogPostDetails} {...props} />;

const Landing = lazy(() => import("../containers/Landing/LandingContainer"));
const LazyLanding = (props) => <LazyLoad component={Landing} {...props} />;

const OrderCompleted = lazy(() => import("../containers/OrderCompleted/OrderCompleted"));
const LazyOrderCompleted = (props) => <LazyLoad component={OrderCompleted} {...props} />;

const LedgerGreenSuccess = lazy(() => import("../containers/LedgerGreen/Success/SuccessContainer"));
const LazyLedgerGreenSuccess = (props) => <LazyLoad component={LedgerGreenSuccess} {...props} />;

const cookieFirebase = cookie.load('HDeviceToken');

export default function getRoutes() {
  return (
    <>
      <Switch>
        <Route
          path="/" 
          exact={true} 
          component={LazyHome}
        />
        <Route
            path="/received-order/:orderId"
            exact={true}
            component={LazyOrderCompleted}
        />
        <Route
          path="/brands"
          exact={true}
          component={LazyBrands}
        />
        <Route
            path="/brands/:brand/:uri_param"
            exact={true}
            component={LazyBrandPage}
        />
        <Route
          path="/login"
          exact={true}
          component={LazyLogin}
        />
        <Route
          path="/registration"
          exact={true}
          component={LazyRegistration}
        />
        <Route
          path="/phone-verification"
          exact={true}
          component={LazyPhoneVerification}
        />
        <Route
          path="/reset-password/:token"
          exact={true}
          component={LazyResetPassword}
        />
        <Route
          path="/forgot-verification"
          exact={true}
          component={LazyForgotVerification}
        />
        <Route
          path="/forgot-password"
          exact={true}
          component={LazyForgotPassword}
        />
        <Route
          path="/confirmation/:token"
          exact={true}
          component={LazyEmailVerification}
        />
        <LoginRedirectRoute
            path="/ledger-green-processing/success"
            exact={true}
            component={LazyLedgerGreenSuccess}
        />
        <LoginRedirectRoute
          path="/account"
          exact={true}
          component={LazyAccount}
        />
        <LoginRedirectRoute
          path="/account/orders"
          exact={true}
          component={LazyAccountOrders}
        />
        <LoginRedirectRoute
          path="/account/orders/:id"
          exact={true}
          component={LazyAccountOrderDetails}
        />
        <LoginRedirectRoute
          path="/account/orders/canceling/:id"
          exact={true}
          component={LazyAccountOrderCanceling}
        />
        <LoginRedirectRoute
          path="/debit-processing"
          exact={true}
          component={LazyDebitSuccess}
        />
        <Route
          path="/faq"
          exact={true}
          component={LazyFaq}
        />
        <Route
          path="/contact"
          exact={true}
          component={LazyContactUs}
        />
        <Route
          path="/about"
          exact={true}
          component={LazyAboutUs}
        />
        {/*<Route*/}
        {/*  path="/product/:id"*/}
        {/*  exact={true}*/}
        {/*  component={LazyProductPage}*/}
        {/*/>*/}
        <Route
          path="/cart"
          exact={true}
          component={LazyCart}
        />
        <Route
          path="/shop"
          exact={true}
          component={LazyShop}
        />
        <Route
          path="/checkout"
          exact={true}
          component={LazyCheckout}
        />
        <Route
          path="/success"
          exact={true}
          component={LazySuccess}
        />
        <Route
          path="/promotions"
          exact={true}
          component={LazyPromotions}
        />
        <Route
          path="/partnerships"
          exact={true}
          component={LazyPartnerships}
        />
        <Route
          path="/terms"
          exact={true}
          component={LazyTermsConditions}
        />
        <Route
          path="/privacy"
          exact={true}
          component={LazyPrivacy}
        />
        {cookieFirebase &&
          <Route
              path="/notifications"
              exact={true}
              component={LazyNotifications}
          />
        }

        <Route
          path="/paytender/success"
          exact={true}
          component={LazyPaytenderSuccess}
        />
        <Route
          path="/careers"
          exact={true}
          component={LazyCareers}
        />
        <Route
            path="/page/:url"
            exact={true}
            component={LazyLanding}
        />
        <Route
          path="/:brand/:id"
          exact={true}
          component={LazyProductPage}
        />
        <Route
            path="/rewards"
            exact={true}
            component={LazyRewards}
        />

        <Route  
          path="/blog"
          exact={true}
          component={LazyBlog}
        />
        <Route
          path="/blog/post/:uri_param"
          exact={true}
          component={LazyBlogPostDetails}
        />
        <Redirect from="/sales" to="/shop?discover=is_sale&page=1" />
        <Route
          path="/shop?discover=is_sale&page=1"
          exact={true}
          component={LazyShop}
        />
        <Route component={LazyNotFound} />
      </Switch>
    </>
  );
}

/**
|--------------------------------------------------
| Private Route Function
|--------------------------------------------------
*/
export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("token") ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

/* export const RedirectRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("initial") && localStorage.getItem(`guId_${storeDomain}`) ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{pathname: "/", state: { from: props.location, needVerification: true } }}
        />
      )
    }
  />
); */

export const LoginRedirectRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("initial") && localStorage.getItem(`guId_${storeDomain}`) && (localStorage.getItem("LoginStatus") === "true") ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ 
            pathname: "/login", 
            state: {
              from: props.location
            },
          }}
        />
      )
    }
  />
);
