import { storeDomain } from "../config/constants";

export function authHeader(upload, header={}) {
    const token = JSON.parse(localStorage.getItem('token'));
    const guId = JSON.parse(localStorage.getItem(`guId_${storeDomain}`));
    const storeStatus = JSON.parse(localStorage.getItem('storeStatus')) || 'retail';
    if (token && guId && upload) {
        return { 'Authorization': 'Bearer ' + token,
            'X-Logickit-Store': storeDomain,
            'X-Logickit-GUID': guId,
            'X-Logickit-Location': storeStatus
        };
    }
    if (token && guId) {
        return { 'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'X-Logickit-Store': storeDomain,
            'X-Logickit-GUID': guId,
            'X-Logickit-Location': storeStatus,
            ...header
        };
    }
    else if(token && !guId){
        return { 'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'X-Logickit-Store': storeDomain,
            'X-Logickit-Location': storeStatus,
            ...header
        };
    }
    else if(!token && guId){
        return { 'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'X-Logickit-Store': storeDomain,
            'X-Logickit-GUID': guId,
            'X-Logickit-Location': storeStatus,
            ...header
        };
    }
    else {
        return {'Content-Type': 'application/json',
            'X-Logickit-Store': storeDomain,
            'X-Logickit-Location': storeStatus,
            ...header
        };
    }
}