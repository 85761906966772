import PropTypes from 'prop-types';

export const addressPT = PropTypes.exact({
  GUID: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  lat: PropTypes.number,
  lng: PropTypes.number,
  state: PropTypes.string,
  zip: PropTypes.string,
});

