import styled from "styled-components";

const StyledSpinner = styled.div`
  display: inline-block;
  &.content-load {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .loader {
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    border: ${props => props.size/10}px solid ${props => props.color};
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 
}

`;
export default StyledSpinner;
