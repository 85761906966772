import styled from "styled-components";

const StyledInputAuthorization = styled.label`
  position: relative;
  padding-bottom: 2rem;
  margin-top: 1rem;
  display: block;
  &.tel{
    margin-top: 0;
  }

  &.error {


    input {
      border: solid 1px ${({theme}) => theme.colors.newErrorForm} !important;

      &:focus {
        &::placeholder {
          color: transparent;
        }

        & + .placeHold {
        }
      }
    }

    .error-message {
      color:  ${({theme}) => theme.colors.webGrey4};
      font-size: var(--type-scale-16);
      padding: 3px 0 0 0;
      display: block;
    }
  }

  &.warning {
    .placeHold {
    }

    input {
      border: solid 1px ${({theme}) => theme.colors.newErrorForm} !important;
    }

    .warning-message {
      color:  ${({theme}) => theme.colors.webGrey4};
      font-size: var(--type-scale-16);
      padding: 3px 0 0 0;
      display: block;
    }
  }

  &.dataPicker_default {
    max-width: 167px !important;

    .react-datepicker-wrapper {
      width: 100%;

      input {
        font-size: 16px;
        font-family: ${({theme}) => theme.colors.font1 ? theme.colors.font1 : ''};;
        &:disabled {
          border: solid 1px ${(props) => props?.inputAuth?.color1};
          background-color: ${(props) => props?.inputAuth?.color1};
        }
      }
    }
  }

  .placeHold {
    color: ${(props) => props?.inputAuth?.color2};
    font-size: 16px;
    position: absolute;
    top: -18px;
    left: 2px;
    transition: all 0.2s ease-in-out;
  }

  input {
    padding: 0 16px;
    width: 100%;
    height: 56px;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    border: solid 1px ${(props) => { return props?.inputAuth?.color3}};
    background-color: ${(props) => props?.inputAuth?.color4};
    outline: none;

    &::placeholder {
      color: ${(props) => props?.inputAuth?.color11};
      transition: all 0.2s ease-in-out;
    }

    &:hover,
    &:focus {
      border-color: ${({theme}) => theme.colors.brand}
    }

    &:focus {
      &::placeholder {
        color: transparent;
      }

      & + .placeHold {
        color: ${(props) => props?.inputAuth?.color5}
      }
    }

    &.disabled {
      border: solid 1px ${(props) => props?.inputAuth?.color6};
      background-color: ${(props) => props?.inputAuth?.color6};
    }
  }

  input[type="date"] {
    text-transform: uppercase;
    font-weight: bold;

    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }

    &::-webkit-datetime-edit-year-field {
      margin-left: 5px;
    }

    &::-webkit-datetime-edit-month-field {
      margin-right: 5px;
    }

    &::-webkit-datetime-edit-day-field {
      margin: 0 5px;
    }

    &::-webkit-datetime-edit-text {
      font-weight: 900;
    }
  }

  textarea {
    padding: 1.375rem 16px;
    width: 100%;
    height: 100px;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    border: solid 1px ${(props) => props?.inputAuth?.color3};
    background-color: ${(props) => props?.inputAuth?.color4};
    outline: none;
    resize: vertical;

    &::placeholder {
      color:  ${(props) => props?.inputAuth?.color7};
      transition: all 0.2s ease-in-out;
    }

    &:hover,
    &:focus {
      border-color: ${({theme}) => theme.colors.brand}
    }

    &:focus {
      &::placeholder {
        color: transparent;
      }

      & + .placeHold {
        color: ${(props) => props?.inputAuth?.color8}
      }
    }
    &.disabled {
      border: solid 1px ${(props) => props?.inputAuth?.color6};
      background-color: ${(props) => props?.inputAuth?.color6};
    }
  }
`;

export default StyledInputAuthorization;
