/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef , useContext} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import cookie from "react-cookies";

// Local Imports
import { locationActions, globals, categoriesFunc } from '../../actions';
import { storeType, storeName, isKiosk } from "../../config/constants";
import useOutsideClick from "../../helpers/useOutsideClick";
import { locationPhonePT, featuredBrandPT, categoriesPT, discoversAllPT } from "../../config/prop-types"

// Component Imports
import StyledHeaderMain from "./HeaderMain.styled";
import InputSearchAhead from "../InputSearchAhead/InputSearchAhead";
import SVGIcon from "../utility/SVGIcon";
import ProfileDropdown from "../ProfileDropdown/ProfileDropdown";
import HeaderMainSecondary from "../HeaderMainSecondary/HeaderMainSecondary";
import {ThemePramsContext} from "../../App";
import { history } from "../../helpers";

const HeaderMain = React.memo(props => {
    const {
        brandName,
        brandLogo,
        dispatch,
        cartCount,
        featuredBrand,
        categories,
        personality,
        sessionClose,
        discoversAll,
        storeInfo,
    } = props;

    // eslint-disable-next-line no-unused-vars
    const [menuOpen, setMenuOpen] = useState(false);
    const toggleNavMenu = () => {
        setMenuOpen(!menuOpen)
    }

    useEffect(() => {
        // dispatch(locationActions.selectedLocation());
        dispatch(locationActions.locationInfo());
        dispatch(globals.cartCount());
        dispatch(categoriesFunc.featuredBrandsAll());
    }, []);


    //Hidden menu when clicked off store panel
    const ref = useRef();
    useOutsideClick(ref, () => {
        if (menuOpen) setMenuOpen(false);
    });

    // Look up current route
    const currentRoute = useLocation();
    const formatPathName = (str) => {
        //Replace all "/" from pathname with space
        let lettersOnly = ('' + str).replace(/[/]/g, ' ');
        // Uppercase first letter of string
        let formatPath = lettersOnly.charAt(1).toUpperCase() + lettersOnly.slice(2);

        return formatPath;
    };
    const LoginStatus = JSON.parse(localStorage.getItem('LoginStatus'));
    const {headerConfig} = useContext(ThemePramsContext);
    return (
        <StyledHeaderMain ref={ref} themeProps={headerConfig?.styling}>
            <div className="header-main">
                <div className="header-main__wrap">
                    <MediaQuery maxWidth={859}>
                        <headerConfig.mobileMenu
                            categories={categories}
                            featuredBrand={featuredBrand}
                            personality={personality}
                            discoversAll={discoversAll}
                            dispatch={dispatch}
                            storeInfo={storeInfo}
                        />
                    </MediaQuery>

                    <h1 className="t-hidden">
                      {brandName} Online Cannabis Delivery
                      {currentRoute.pathname === "/" ?
                          ""
                          :
                          ` - ${formatPathName(currentRoute.pathname)}`
                      }
                    </h1>

                    <Link to="/" className="header-main__home">
                        {storeType === 'rebud' ?
                            <>
                                {
                                    headerConfig.icon1 ?
                                        <SVGIcon name={headerConfig.icon1} className="header-main__logo" />
                                        :
                                        <img src={headerConfig.img1}  className="header-main__logo-other" />
                                }

                            </>
                            :
                            brandLogo && <img src={brandLogo}  className="header-main__logo-other" />
                        }
                        <span className="t-hidden">{storeName} home</span>
                    </Link>
                    { headerConfig.toggleMenu ?
                        <button className={`${headerConfig.className1} header-main__link menu_btn`} onClick={toggleNavMenu}>
                            SHOP
                            {<headerConfig.icon2 className={`menu_btn__icon ${menuOpen? "isOpen":""}`}/>}
                        </button>
                        :
                        <div className="t-header-link title" onClick={()=>{history.push("/shop")}}>SHOP BUNDLES</div>
                    }


                    <div className="header-main__icons">
                        <InputSearchAhead
                            inputName="search_input"
                            placeholder="What are you looking for…"
                            className="header-main__search-ahead"
                        />

                        <div className="header-main__user">
                            <ProfileDropdown storeInfo={storeInfo} className="header-main__user__button" dispatch={dispatch} />
                        </div>
                        {cookie.load('HDeviceToken') && LoginStatus &&
                            <Link to="/notifications" className="header-main__icon-button notification-icon">
                                <SVGIcon name="notifications" />
                            </Link>
                        }

                        <Link to="/cart" className="header-main__icon-button cart-icon">
                            {headerConfig.visibleCartIcon ? <SVGIcon name={headerConfig.icon3} /> : "CART"}
                            <span className="t-hidden">Cart</span>
                            <div className={`cart-count ${headerConfig?.className2}`}>{cartCount ? cartCount : localStorage.getItem("itemsCountInShoppingCart")}</div>
                        </Link>
                        {isKiosk && LoginStatus &&
                            <button data-testid="close-session" onClick={sessionClose} className="header-main__icon-button close-session">
                                <SVGIcon name="close_session" />
                            </button>
                        }
                    </div>
                    <headerConfig.mainMenu
                        isOpen={menuOpen}
                        setMenuOpen={setMenuOpen}
                        categories={categories}
                        featuredBrand={featuredBrand}
                        personality={personality}
                        discoversAll={discoversAll}
                    />
                </div>
            </div>

            {!isKiosk && <HeaderMainSecondary />}
        </StyledHeaderMain>
    );
});
const mapStateToProps = ({ userAddress, storeInfo, locationInfo, cartCount, featuredBrandAll, categoriesAll, personalityAll, discoversAll, }) => ({
    address: userAddress.address,
    locationPhone: locationInfo.locationPhone,
    storeInfo: storeInfo.storeInfo,
    cartCount: cartCount.count,
    featuredBrand: featuredBrandAll.featuredBrandsListAll,
    categories: categoriesAll.catListAll,
    personality: personalityAll.personalityListAll,
    discoversAll: discoversAll.discoversAll,
});

HeaderMain.propTypes = {
    brandLogo: PropTypes.string,
    brandName: PropTypes.string.isRequired,
    locationPhone: locationPhonePT,
    cartCount: PropTypes.number,
    featuredBrand: featuredBrandPT,
    categories: categoriesPT,
    sessionClose: PropTypes.func.isRequired,
    discoversAll: discoversAllPT,
};

export default connect(mapStateToProps)(HeaderMain);
