import PropTypes from 'prop-types';

export const brandListPT = PropTypes.exact({
  contact_person: PropTypes.string,
  created_at: PropTypes.string,
  deleted_at: PropTypes.string,
  description: PropTypes.string,
  email: PropTypes.string,
  id: PropTypes.string,
  img_path: PropTypes.string,
  name: PropTypes.string,
  phone_number: PropTypes.string,
  picture: PropTypes.string,
  show_in_marketplace: PropTypes.bool,
  store_id: PropTypes.string,
  type_id: PropTypes.number,
  updated_at: PropTypes.string,
  uri_param: PropTypes.string,
});

export const brandsListPT = PropTypes.arrayOf(brandListPT);