import React from "react";
import {Link} from "react-router-dom";
import Textra from 'react-textra'
import StyledHeroPromoAnimated from "./HeroPromoAnimated.styled";
import { promoBarPT } from "../../config/prop-types";

const HeroPromoAnimated = React.memo(({promoBar}) => {
    const promoBarTitlesList = promoBar.map((item => <p className="hero-promo__copy-wrap t-body-small">{item.title}</p>));
    return (
        <StyledHeroPromoAnimated>
            <Textra
                data={promoBarTitlesList}
                effect="simple"
                duration={500}
                stopDuration={7000}
            />
            <Link to="/promotions" className="hero-promo__link">See All Deals</Link>
        </StyledHeroPromoAnimated>
    );
});

HeroPromoAnimated.propTypes = {
    promoBar: promoBarPT.isRequired
};

export default HeroPromoAnimated;
