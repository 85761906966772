import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const StyledButtonOutlineBundle = styled.button`
  padding: 14px 31px;
  background-color: ${({ secondary, theme: { colors } }) => secondary ? colors.gray1 : colors.bundleBlack};
  color: ${({ secondary, theme: { colors } }) => secondary ? colors.brand : colors.bundleWhite};
  font-size: var(--type-scale-16);
  font-weight: var(--weight-bold);
  text-align: center;
  flex-shrink: 0;
  transition: var(--button-transition);
  /* UI Properties */
  border: ${(modalButton ) => modalButton ? "3px solid #F5F5F5" : ''};
  border-radius: ${( modalButton ) => modalButton ? "29px" : ''};
  opacity: ${( modalButton ) => modalButton ? "1" : ''};

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.bundleWhite};
    color: ${({ theme }) => theme.colors.bundleBlack};
  }
  &:disabled {
    background-color: ${({ theme, modalButton }) => modalButton ? theme.colors.bundleBlack : theme.colors.bundleWhite};
    border-color: ${({ theme, modalButton }) => theme.colors.bundleWhite};
    color: ${({ theme, modalButton }) => modalButton ? theme.colors.bundleWhite : theme.colors.bundleGrey3};
    cursor: default;
  }
`;

StyledButtonOutlineBundle.propTypes = {
    secondary: PropTypes.bool
};

export default React.memo(props => <StyledButtonOutlineBundle {...props} />);