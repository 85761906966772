import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const StyledButtonOutline = styled.button`
  padding: 14px 31px;
  border: 1px solid ${({ secondary, theme: { colors } }) => secondary ? colors.gray1 : colors.brandDark};
  background-color: ${({ secondary, theme: { colors } }) => secondary ? colors.gray1 : colors.white};
  color: ${({ secondary, theme: { colors } }) => secondary ? colors.brand : colors.brandDark};
  font-size: var(--type-scale-16);
  font-weight: var(--weight-bold);
  text-align: center;
  flex-shrink: 0;
  transition: var(--button-transition);

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.brand};
    border-color: ${({ theme }) => theme.colors.brand};
    color: ${({ theme }) => theme.colors.white};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.gray3};
    color: ${({ theme }) => theme.colors.gray3};
    cursor: default;
  }
`;

StyledButtonOutline.propTypes = {
  secondary: PropTypes.bool
};

export default React.memo(props => <StyledButtonOutline {...props} />);