import {
    GET_LANDING_DATA, GET_LANDING_DATA_FAILED,
    GET_LANDING_DATA_LOADING, SET_LANDING
} from "../config/actionTypes";

const initialState = {
    landingData: null,
    loading: false,
    isLanding: false
}

export function landingInfo(state = initialState, action) {
    switch (action.type) {
        case SET_LANDING:
            return {
                ...state,
                isLanding: true
            };
        case GET_LANDING_DATA_LOADING:
            return {
                ...state,
                loading: true,
                isLanding: true
            };
        case GET_LANDING_DATA:
            return {
                ...state,
                landingData: action.payload,
                loading: false,
                isLanding: true
            };
        case GET_LANDING_DATA_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false,
                isLanding: false
            };
        default:
            return state
    }
}