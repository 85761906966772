import PropTypes from 'prop-types';

export const locationPT = PropTypes.exact({
  GUID: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  lat: PropTypes.number,
  lng: PropTypes.number,
  state: PropTypes.string,
  zip: PropTypes.string,
});

export const userInfoPT = PropTypes.exact({
  avatar: PropTypes.string,
  dateOfBirth: PropTypes.string,
  discreteDelivery: PropTypes.bool,
  email: PropTypes.string,
  expirationDate: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  medicalNumberId: PropTypes.string,
  phoneNumber: PropTypes.string,
  docVerified: PropTypes.bool,
  emailVerified: PropTypes.bool,
  id: PropTypes.string,
  idVerified: PropTypes.bool,
  location: locationPT,
  optInMessage: PropTypes.bool,
  phoneNumberVerified: PropTypes.bool,
  nftMembership: PropTypes.bool,
});

