import styled from "styled-components";

const StyledMobileMenu = styled.div`
  --header-height: 65px;
  --gutter-width: 16px;

  width: 100vw;
  height: 100%;
  top: 0;
  display: grid;
  grid-template-columns: minmax(100px, 360px) 1fr;
  grid-template-rows: var(--header-height) 1fr;
  position: fixed;
  z-index: 4;
  transition: left .4s ease-in-out;
  left: -100vw;

  &[data-menu-state=true] {
    left: 0;
    
    .mobile-menu__background {
        width: 100vw;
        height: 100vh;
        position: fixed;
        cursor: pointer;
        background: ${({theme}) => theme.colors.blackTransparent};
        left: 0;
        top: 0;
        z-index: 1;
        transition: background .4s ease-in-out;
      }
  }

  &[data-menu-state=false] {
     left: -100vw;
  }

  .mobile-menu__nav {
    height: 100%;
    display: grid;
    grid-column-start: 1;
    grid-row: 1 / 3;
    grid-template-rows: auto 1fr auto;
    background-color: ${({theme}) => theme.colors.white};
    z-index: 2;
    width: 100%;
    max-width: 360px;
    overflow-y: auto;
  }

  .mobile-menu__nav__header {
    padding: .5rem 12px;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: ${({theme}) => theme.colors.white};
  }

  .mobile-menu__close {
    padding: 10px 6px;
    display: flex;
    order: 1;
    fill: ${({theme}) => theme.colors.brandDark};
    border-radius: 4px;

    &:hover,
    &:focus {
      fill: ${({theme}) => theme.colors.brand};
      outline: none;
    }
    svg {
      width: 20px;
      height: 20px;
      fill: inherit;
    }
  }

  .mobile-menu__nav__footer {
    padding: 2.5rem;
  }
  
  .mobile-menu__nav__footer__heading {
    color: ${({theme}) => theme.colors.brandDark};
    text-align: center;
    text-transform: uppercase;
  }
  
  .mobile-menu__nav__footer__logo {
    margin-bottom: 3rem;
    width: 92px;
    height: 138px;
  }

  .mobile-menu__nav__footer__links {
    width: 100%;
  }

  .mobile-menu__nav__footer__link {
    a {
      color: ${({theme}) => theme.colors.brandDark};

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

  }

  .mobile-menu__nav__footer__socials {
    padding-right: 32px;
    padding-left: 32px;
    display: flex;
    width: 100%;
    max-width: 900px;
    justify-content: center;
  }

  .mobile-menu__nav__footer__social {
    display: inline-flex;
    
    a {
      padding: 16px;
      display: flex;
    }

    svg {
      width: 24px;
      height: 24px;
      display: flex;
      flex-shrink: 0;
      fill: ${({theme}) => theme.colors.brandDark};
      transition: var(--button-transition);
    }

    &:hover,
    &:focus {
      svg {
        fill: ${({theme}) => theme.colors.brand}
      }
    }
  }

  .mobile-menu__nav__urls {
    padding: 146px 20px 30px;

    a,
    button {
      display: block;
      width: 100%;
      color: ${({theme}) => theme.colors.gray3};
      padding: 10px 0;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .mobile-menu__shop-all {
    padding: .5rem 32px .5rem 20px;
    width: fit-content;
    //display: inline-flex;
    color: ${({ theme }) => theme.colors.brandDark};
    text-decoration: none;
    transition: var(--button-transition);
    align-items: center;
    position: relative;
    flex-shrink: 0; // Needed for Safari //
    white-space: nowrap; // Needed for Safari //
    display: block;
    &:hover,
    &:focus {
      svg {
        right: 0;
      }
    }

    svg {
      position: absolute;
      right: 4px;
      top: 50%;
      transform: translateY(-45%) rotate(90deg);
      width: 16px;
      height: 16px;
      stroke: ${({ theme }) => theme.colors.brandDark};
      transition: var(--button-transition);
    }

  }
`;

export default StyledMobileMenu;
