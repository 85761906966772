import PropTypes from 'prop-types';

export const brandsShelfPT = PropTypes.arrayOf(PropTypes.exact({
  blurb: PropTypes.string,
  brands_ids: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string,
  link: PropTypes.string,
  pictures: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
}));
