import React from "react";
import styled from "styled-components";

const StyledInputZip = styled.input`
  padding: ${(props) => props.padding};
  width: 100%;
  height:  ${(props) => props.height1};
  border-radius: ${(props) => props.borderRadius};
  border: ${(props) => props.border};
  border-bottom: ${(props) => props.borderBottom};
  background-color: ${(props) => props.zipColor1};
  transition: var(--button-transition);
  outline: none;
  font-family: ${(props) => props.font4};
  &::placeholder {
    color: ${(props) => props.zipColor2}
  }

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.brand}
  }
  &.disabled {
    border: solid 1px ${({ theme }) => theme.colors.gray1};
    background-color: ${({ theme }) => theme.colors.gray1};
  }
  @media (max-width: 799px) {
    height: ${(props) => props.height2};
  }
`;

export default React.memo(props => <StyledInputZip {...props} />);