import React from "react";
import { IoIosClose, IoIosCheckmarkCircle } from "react-icons/io";
import { MdError } from "react-icons/md";
import { TiWarning } from "react-icons/ti";
import PropTypes from "prop-types";

import "./toast.scss";

export const SuccessToast = React.memo(({ closeToast, text }) => (
  <div className="toast-container -success">
    <div className="toast-container-toast">
      <IoIosCheckmarkCircle
        className="toast-container-toast--icon -success"
        size="24"
      />
      <div className="toast-container-toast--text">
        <p className="toast-container-toast--paragraph">{text}</p>
      </div>
    </div>
    <IoIosClose className="close__icon" size="25" onClick={closeToast} />
  </div>
));

SuccessToast.propTypes = {
  closeToast: PropTypes.func,
  text: PropTypes.string.isRequired,
};

export const ErrorToast = React.memo(({ closeToast, text }) => (
  <div className="toast-container -error">
    <div className="toast-container-toast">
      <MdError className="toast-container-toast--icon -error" size="24" />
      <div className="toast-container-toast--text">
        <p className="toast-container-toast--paragraph">{text}</p>
      </div>
    </div>
    <IoIosClose className="close__icon" size="25" onClick={closeToast} />
  </div>
));

ErrorToast.propTypes = {
  closeToast: PropTypes.func,
  text: PropTypes.string.isRequired,
};

export const WarningToast = React.memo(({ closeToast, text }) => (
  <div className="toast-container -warning">
    <div className="toast-container-toast">
      <TiWarning className="toast-container-toast--icon -warning" size="24" />
      <div className="toast-container-toast--text">
        <p className="toast-container-toast--paragraph">{text}</p>
      </div>
    </div>
    <IoIosClose
      className="close__icon -warning"
      size="25"
      onClick={closeToast}
    />
  </div>
));

WarningToast.propTypes = {
  closeToast: PropTypes.func,
  text: PropTypes.string.isRequired,
};
