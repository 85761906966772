import PropTypes from 'prop-types';

export const filterRangePT = PropTypes.exact({
  id: PropTypes.number,
  name: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.number),
});

export const filterProductTypePT = PropTypes.arrayOf(PropTypes.exact({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  uri_param: PropTypes.string,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  deleted_at: PropTypes.string,
  store_id: PropTypes.string,
  email: PropTypes.string,
  phone_number: PropTypes.string,
  contact_person: PropTypes.string,
  show_in_marketplace: PropTypes.bool,
  type_id: PropTypes.number,
  description: PropTypes.string,
  img_path: PropTypes.string,
  picture: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.exact({
    active: PropTypes.bool,
    backgroundColor: PropTypes.string,
    children: PropTypes.array,
    description: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    parent: PropTypes.string,
    parentId: PropTypes.string,
    pictureContentType: PropTypes.string,
    pictureFileName: PropTypes.string,
    pictureFileSize: PropTypes.string,
    sortId: PropTypes.string,
    storeId: PropTypes.string,
    uri_param: PropTypes.string,
  })),
}));
