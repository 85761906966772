import { darken, lighten, rgba } from "polished";
import { colors, sizes } from "./_baseTheme";

const primary = "#3abf7c";
const secondary = "#FF8686";
const tertiary = "#171c25";
const mainFont1 = "NHaasGroteskDSPro-65Md, sans-serif";
const mainFont2 = "NHaasGroteskDSPro-55Rg, sans-serif";
// const brandColor = "#FFD700"
const brandBright = "#FFD700";
const everscoreGray10 = "grey";
const everscoreBrandBright = "#FFD700";
const everscoreBrandConst = "grey";

const everscoreTheme = {
    colors: {
        ...colors,
        brandDark: colors.everscoreBrandDark,
        everscoreGray10,
        everscoreBrandBright,
        everscoreBrandConst,
        brandLightEverscore: colors.everscoreBrandLight,
        brandLight: colors.white,
        promoBannerBackground: colors.everscoreBrandLight,
        highlight: brandBright,
        error: colors.everscoreWarning,
        warning: colors.everscoreWarning,
        grey2: colors.everscoreGray2,
        grey3: colors.everscoreGray3,
        gray8: colors.everscoreGray8,
        gray9: colors.everscoreGray9,
        accentHybrid: colors.everscoreSale,
        brand: everscoreBrandConst,
        // brand: colors.flowerBrandConst,

        onlyLeftRed: colors.everscoreWarning,
        saleGreen: colors.everscoreSale,
        errorForm: colors.everscoreWarning,

        primary: {
            normal: primary,
            hover: lighten(0.1, primary),
            active: darken(0.1, primary),
            outline: {
                background: rgba(primary, 0.04)
            }
        },
        secondary: {
            normal: secondary,
            hover: lighten(0.1, secondary),
            active: darken(0.1, secondary),
            outline: {
                background: rgba(secondary, 0.04)
            }
        },
        tertiary: {
            normal: tertiary,
            hover: lighten(0.1, tertiary),
            active: darken(0.1, tertiary),
            outline: {
                background: rgba(tertiary, 0.04)
            }
        },
        footerBackground: tertiary,
        shopMobileFilterForm: darken(0.03, tertiary),
    },
    ...sizes,
    mainFonts: {
        mainFont1,
        mainFont2
    }
};

export default everscoreTheme;
