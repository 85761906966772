import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const StyledButtonPrimary = styled.button`
  padding: ${props => props.small ? "0.625rem" : "0.875rem"};
  ${props => props.small ? "" : "width: 100%"};
  background-color: ${({theme: {colors} }) => colors.brandDark};
  color: ${({theme: {colors} }) => colors.white};
  font-size: ${props => props.small ? "var(--type-scale-14)" : "var(--type-scale-16)"};
  font-weight: ${props => props.noBold ? "var(--weight-regular)" : "var(--weight-bold)"};
  line-height: 19.2px;
  letter-spacing: 1px;
  text-align: center;
  ${'' /* flex-shrink: 0; */}
  transition: var(--button-transition);

  &:hover {
    outline: none;
    color: ${({ outline, theme }) => outline ? theme.colors.brand : theme.colors.white};
    background-color: ${({ outline, theme }) => outline ? theme.colors.white : theme.colors.brand};
  }

  &:focus {
    background-color: ${({ outline, theme, noFocus }) => noFocus ? '' : outline ? theme.colors.white : theme.colors.brand};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.gray1};
    color: ${({ theme }) => theme.colors.gray5};
    cursor: no-drop;
  }
`;

StyledButtonPrimary.propTypes = {
  small: PropTypes.bool,
  outline: PropTypes.bool,
  noFocus: PropTypes.bool,
  noBold: PropTypes.bool,
};

export default React.memo(props => <StyledButtonPrimary {...props} />);
