/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import React from "react";
import StyledHeaderMain from "./MainMenu.styled";
import MainMenuCard from "../MainMenuCard/MainMenuCard";
import { Link } from "react-router-dom";
import { personalityPT, featuredBrandPT, categoriesPT, discoversAllPT } from "../../config/prop-types"
import { isKiosk } from "../../config/constants";

const MainMenu = React.memo(props => {
    const {
      isOpen,
      setMenuOpen,
      featuredBrand,
      categories,
      personality,
      discoversAll
    } = props;

    const closeMenu = () => {
      setMenuOpen(false)
    }

    const thc = [
      {id: "1", name: "Low THC" , uri_param: "lowTHC" },
      {id: "2", name: "Medium THC", uri_param: "mediumTHC" },
      {id: "3", name: "Highest THC", uri_param: "highestTHC" },
    ]

    return (
        <StyledHeaderMain data-testid="main_menu" data-menu-state={isOpen} isKiosk={isKiosk}>
          <div className="main_menu">
            {categories && categories.length > 0 &&
              <div>
                <h3 className="main_menu__heading t-body-medium t-font-artifex">Category</h3>
                <div className="main_menu__list">
                  {categories.slice(0, 9).map((item,i)=> {
                    return <MainMenuCard
                      key={i}
                      id={item.uri_param}
                      heading={item.name}
                      type="category"
                      setMenuOpen={setMenuOpen}
                    />
                  })}
                </div>
              </div>
            }

            {personality && personality.length > 0 &&
              <div>
                <h3 className="main_menu__heading t-body-medium t-font-artifex">Personality</h3>
                <div className="main_menu__list">
                  {personality.map((item,i)=> {
                    return <MainMenuCard
                      key={i}
                      id={item.uri_param}
                      heading={item.name}
                      type="by_personality"
                      setMenuOpen={setMenuOpen}
                    />
                  })}
                </div>
              </div>
            }

            {thc && 
              <div>
                <div>
                  <h3 className="main_menu__heading t-body-medium t-font-artifex">Potency</h3>
                  <div className="main_menu__list">
                    {thc.map((item, i)=> {
                      return <MainMenuCard
                        key={i}
                        id={item.uri_param}
                        heading={item.name}
                        type="thc"
                        setMenuOpen={setMenuOpen}
                      />
                    })}
                  </div>
                </div>
                  {discoversAll && discoversAll.length > 0 &&
                      <div className="main-menu__discover">
                          <h3 className="main_menu__heading t-body-medium t-font-artifex">Discover</h3>
                          <div className="main_menu__list">
                              { discoversAll.map((item, i)=> {
                                  return <MainMenuCard
                                      key={i}
                                      id={item.uri_param}
                                      heading={item.name}
                                      type="discover"
                                      setMenuOpen={setMenuOpen}
                                  />
                              })}
                          </div>
                      </div>
                  }

              </div>
            }

            {featuredBrand && featuredBrand.length > 0 && featuredBrand.map((item,i)=>{
              return (item.brands.length > 0 && <div className="main-menu__brands" key={i}>
                <h3 className="main_menu__heading t-body-medium t-font-artifex">Featured Brand</h3>
                <div className="main_menu__list-grid">
                  {item.brands && item.brands.map((brandItem,t)=> {
                    return <MainMenuCard
                      key={t}
                      id={brandItem.uri_param}
                      heading={brandItem.name}
                      type="brand"
                      setMenuOpen={setMenuOpen}
                    />
                  })}
                </div>
              </div>)
            })}
          </div>

          <div className="main_menu__lower-wrap">
            <div className="main_menu__lower">
              <Link to="/shop" className="main-menu-link__lower t-body-medium t-font-artifex" onClick={closeMenu}>
                SHOP ALL
              </Link>

              <Link to="/shop?discover=is_sale" className="main-menu-link__lower t-body-medium t-font-artifex" onClick={closeMenu}>
                SALE
              </Link>

              <a href="https://nft.rebud.com" className="main-menu-link__lower t-body-medium t-font-artifex" onClick={closeMenu}>
                NFT
              </a>

              <Link to="/blog" className="main-menu-link__lower t-body-medium t-font-artifex" onClick={closeMenu}>
                LEARN
              </Link>

              <Link to="/promotions" className="main-menu-link__lower t-body-medium t-font-artifex" onClick={closeMenu}>
                PROMO
              </Link>
            </div>
          </div>
        </StyledHeaderMain>
    );
});

MainMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setMenuOpen: PropTypes.func.isRequired,
  featuredBrand: featuredBrandPT,
  discoversAll: discoversAllPT,
  categories: categoriesPT,
  personality: personalityPT,
};

export default MainMenu;