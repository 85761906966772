import PropTypes from 'prop-types';

export const personalityPT = PropTypes.arrayOf(PropTypes.exact({
  active: PropTypes.bool,
  blob_keys: PropTypes.arrayOf(PropTypes.string),
  color: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.string,
  image_url: PropTypes.string,
  name: PropTypes.string,
  uri_param: PropTypes.string,
}));

