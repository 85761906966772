import React, {useEffect, useContext} from "react";
import PropTypes from "prop-types";
import StyledSimpleModal from "./ModalSimple.styled";
import SVGIcon from "../utility/SVGIcon"
import { aeroPayFastLinkPT } from "../../config/prop-types";
import {ThemePramsContext} from "../../App";

const SimpleModal = React.memo(props => {
  const { className, shown, modalId, onChange, aeroPayFastLink, marginRight } = props;
  
  const toggleShown = () => onChange ? onChange(false) :'';

  useEffect(() => {
    shown ? document.body.classList.add("no-scroll") : document.body.classList.remove("no-scroll");
  }, [shown])
  const {checkoutConfig:{simpleModal}} = useContext(ThemePramsContext);
  return (
    <StyledSimpleModal
        shown={shown}
        aeroPayFastLink={aeroPayFastLink}
        color1={simpleModal.color1}
        color2={simpleModal.color2}
        marginRight={marginRight}
    >
      <div role="dialog" className={`simple-modal ${className}`}  data-testid={modalId}>
        {onChange &&
          <button onClick={toggleShown} className="simple-modal__button">
            <SVGIcon name="close" className="simple-modal__close" />
            <span className="t-hidden">Close modal</span>
          </button>
        }
        <div className="simple-modal__content" role="document">
          {props.children}
        </div>
      </div>
      
      <div className="background" onClick={toggleShown}></div>
    </StyledSimpleModal>
  );
});

SimpleModal.propTypes = {
  className: PropTypes.string,
  shown: PropTypes.bool,
  modalId: PropTypes.string,
  onChange: PropTypes.func,
  aeroPayFastLink: aeroPayFastLinkPT,
};

export default SimpleModal;