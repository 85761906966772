import { userService } from '../services';
import {
    GET_CAROUSEL,
    GET_BEST_SELLERS,
    GET_PRODUCTS_SALES,
    GET_STAFF_PICKS,
    GET_HERO_BANNER,
    GET_BRAND_SPOTLIGHT,
    GET_BRANDS_SHELF,
    GET_BRANDS_SEARCH,
    GET_PROMO_BAR, GET_DYNAMIC_SHELF
} from "../config/actionTypes";
import qs from "qs";

export const homePageActions = {
  getCarousel,
  getBestSellers,
  getProductsSales,
  getStaffPicks,
  getHeroBanner,
  getBrandSpotlight,
  getBrandsShelf,
  getBrandsSearch,
  getDynamicShelf,
  getPromoBar
};

function getCarousel() {
  return dispatch => {
    userService.GetInfo('/carousel', '')
      .then(
        data => {
          return dispatch(success(data))
        }
      )
  };
  function success(carousel) { return { type: GET_CAROUSEL, carousel } }
}

function getPromoBar() {
  return dispatch => {
    userService.GetInfo('/promo_bar/lists', '')
      .then(
        data => {
          return dispatch(success(data))
        }
      )
  };
  function success(promoBar) { return { type: GET_PROMO_BAR, promoBar } }
}

function getBestSellers() {
  return dispatch => {
    userService.GetInfo('/products/brand/best_sellers', '')
      .then(
        data => {
          return dispatch(success(data))
        }
      )
  };
  function success(bestSellers) { return { type: GET_BEST_SELLERS, bestSellers } }
}

function getProductsSales() {
  return dispatch => {
    userService.GetInfo('/products/sales', '')
      .then(
        data => {
          return dispatch(success(data))
        }
      )
  };
  function success(productsSales) { return { type: GET_PRODUCTS_SALES, productsSales } }
}

function getStaffPicks() {
  return dispatch => {
    userService.GetInfo('/products/staff_picks/list', '')
      .then(
        data => {
          return dispatch(success(data))
        }
      )
  };
  function success(staffPicks) { return { type: GET_STAFF_PICKS, staffPicks } }
}

function getBrandsShelf() {
    return dispatch => {
        userService.GetInfo('/brands/shelf', '')
            .then(
                data => {
                    return dispatch(success(data))
                }
            )
    };
    function success(shelfBrands) { return { type: GET_BRANDS_SHELF, shelfBrands } }
}

function getDynamicShelf() {
    return dispatch => {
        userService.GetInfo('/dynamic_shelf', '')
            .then(
                data => {
                    return dispatch(success(data))
                }
            )
    };
    function success(dynamicShelf) { return { type: GET_DYNAMIC_SHELF, dynamicShelf } }
}

function getBrandsSearch(queryParams, urlParams) {
  let endpoint = '/brands';
  if (queryParams) {
    endpoint += '?' + qs.stringify(queryParams)
  }
  if (urlParams) {
    Object.keys(urlParams).forEach(k => {
      endpoint = endpoint.replace(':' + k, urlParams[k]);
    });
  }
  return dispatch => {
    userService.GetInfo(endpoint, '')
      .then(
        data => {
          return dispatch(success(data))
        }
      )
  };
  function success(list) { return { type: GET_BRANDS_SEARCH, list } }
}

function getHeroBanner() {
  return dispatch => {
    userService.GetInfo('/locations/hero-banner', '')
      .then(
        data => {
          return dispatch(success(data))
        }
      )
  };

  function success(heroBanner) { return { type: GET_HERO_BANNER, heroBanner } }
}

function getBrandSpotlight() {
  return dispatch => {
    userService.GetInfo('/locations/brand-spotlights', '')
      .then(
        data => {
          return dispatch(success(data))
        }
      )
  };

  function success(brandSpotlight) { return { type: GET_BRAND_SPOTLIGHT, brandSpotlight } }
}
