import {parsePhoneNumber} from "react-phone-number-input";

export const USNumberCode = "1";

export const US = "US";

export function IsUsNumber(phone) {
    const phoneNumber = parsePhoneNumber(`+${phone}`)
    return (phoneNumber && phoneNumber.country === US );
}

export function IsUsPhoneNumber(phone) {
    const re =/^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[0-9]{3}[\s\-]?[0-9]{4}$/;
    return re.test(phone)
}