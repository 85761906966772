import styled from "styled-components";

const FooterMain = styled.footer`
  .footer-wrap {
    background-color: ${({theme}) => theme.colors.brandDark};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;

    @media (max-width: 859px) {
      padding: 40px 16px;
    }
    @media (min-width: 860px) {
      padding: 64px 32px;
    }
  }

  .footer-home__heading {
    margin-top: 2.5rem;
    color: ${({theme}) => theme.colors.primary2};
  }
  
  .footer-home__logo {
    margin-bottom: 3rem;
    width: 250px;
  }

  .footer-home__links {
    width: 100%;
    
    @media (max-width: 599px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 600px) and (max-width: 859px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 859px) {
      display: grid;
      grid-gap: 20px 16px;
      justify-content: center;
    }
    @media (min-width: 860px) {
      max-width: 800px;
      display: flex;
      justify-content: space-between;
    }
  }

  .footer-home__link {
    a {
      display: inline-block;
    background-image: linear-gradient(#000, ${({theme}) => theme.colors.primary2});
    background-position: 0 100%; /*OR bottom left*/
    background-size: 0% 2px;
    background-repeat: no-repeat;
    transition:
      background-size 0.3s,
      background-position 0s 0.3s;
      color: ${({theme}) => theme.colors.primary2};

      &:hover,
      &:focus {
        background-position: 100% 100%;
        background-size: 100% 3px;
      }

      @media (min-width: 860px) {
        padding-top: 1rem;
        padding-bottom: .3rem;
      }
    }
    @media (max-width: 859px) {
      text-align: center;
    }
    
    @media (min-width: 860px) {
      display: inline-flex;

      &:not(:last-of-type) {
        margin-right: 40px;
      }
    }
  }

  .footer-home__socials {
    padding-right: 32px;
    padding-left: 32px;
    display: flex;
    width: 100%;
    max-width: 900px;
    justify-content: center;
  }

  .footer-home__social {
    display: inline-flex;
    
    a {
      padding: 16px;
      display: flex;
    }

    svg {
      width: 24px;
      height: 24px;
      display: flex;
      flex-shrink: 0;
      fill: ${({theme}) => theme.colors.primary2};
      transition: var(--button-transition);
    }

    &:hover,
    &:focus {
      svg {
        fill: ${({theme}) => theme.colors.brand}
      }
    }
  }

  .footer-home__affiliate,
  .footer-home__copywrite {
    margin-top: 0.5rem;
    color: ${({theme}) => theme.colors.primary2};
    text-align: center;
    
    @media (min-width: 860px) {
      text-align: center;
    }
  }

  .footer-home__affiliate {
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  .footer-home__license {
    margin-top: 0.5rem;
    color: ${({theme}) => theme.colors.primary2};
    text-align: center;
  }

  .test-logo {
    font-size: 48px;
    color: white;
    margin: 80px 0 32px;
  }
`;

export default FooterMain;
