import { userService } from '../services';
import { GET_USER_ADDRESS, GET_LOCATION_INFO, GET_LOCATION_INFO_LOADING,
    GET_CURBSIDE_LOCATIONS, GET_LOCATION_PAGES, FAILED_LOCATION_PAGES, GET_PICKUP_LOCATIONS, GET_SHIPPING_LOCATIONS } from '../config/actionTypes';
import { storeDomain } from "../config/constants";
import {history} from "../helpers";
import {globals} from "./global.actions";

export const locationActions = {
    locationSelect,
    selectedLocation,
    selectedPostLocation,
    locationSelectInHeader,
    locationInfo,
    locationPages,
    getPickupLocations,
    getCurbsideLocations,
    getShippingLocations,
};

function locationSelect(data) {
     return  userService.PostInfo('/locations/delivery_in_range',data)
}

function selectedLocation() {
    return dispatch => {
        userService.GetInfo('/locations/selected','')
            .then(
                data => {
                    if( data && data.GUID){
                        localStorage.setItem(`guId_${storeDomain}`, JSON.stringify(data.GUID));
                    }
                    dispatch(success(data));
                }
            );
    };
    function success(address) { return { type: GET_USER_ADDRESS, address } }
}
function selectedPostLocation(data, redirect) {
    return dispatch => {
        return userService.PostInfo('/locations/selected', data)
            .then(
                data => {
                    localStorage.setItem('initial', true);
                    if(data.GUID){
                        localStorage.setItem(`guId_${storeDomain}`, JSON.stringify(data.GUID));
                    }
                    if(redirect){
                        history.push('/cart')
                        dispatch(globals.storeInfo());
                        window.location.reload()
                    } else{
                        window.location.reload()
                    }


                }
            );
    };
}
function locationSelectInHeader(data) {
    return  userService.PostInfo('/locations/selected',data)
}

function locationInfo() {
    return dispatch => {
        dispatch(loading(true));
        userService.GetInfo('/locations/info','')
            .then(
                data => {
                    dispatch(success(data));
                }
            ).catch(
                ()=> {
                  dispatch(loading(false));
                }
        );
    };
    function success(locationPhone) { return { type: GET_LOCATION_INFO, locationPhone, loading: false } }
    function loading(loading) { return { type: GET_LOCATION_INFO_LOADING, locationPhone: {}, loading } }
}

function locationPages(location) {
    return dispatch => {
        dispatch(requestLoader());
        userService.GetInfo('/locations/pages/', location)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(successLoader());
                },
                (err) =>{
                  dispatch(failureLoader());
                  dispatch(failed(err));
                }
        );
    };
    function requestLoader() { return { type: 'REQUEST' } }
    function successLoader() { return { type: 'SUCCESS' } }
    function failureLoader() { return { type: 'FAILURE' } }
    function failed(error) { return { type: FAILED_LOCATION_PAGES, error }}
    function success(locationPages) { return { type: GET_LOCATION_PAGES, locationPages, loading: false } }
};
function getPickupLocations() {
    return dispatch => {
        userService.GetInfo('/locations/by-type/is_store_pickup','')
            .then(
                data => {
                    dispatch(success(data));
                }
            ).catch(
            (err)=> {
                console.log(err)
            }
        );
    };
    function success(pickupLocations) { return { type: GET_PICKUP_LOCATIONS, pickupLocations} }
}
function getCurbsideLocations() {
    return dispatch => {
        userService.GetInfo('/locations/by-type/is_curbside','')
            .then(
                data => {
                    dispatch(success(data));
                }
            ).catch(
            (err)=> {
                console.log(err)
            }
        );
    };
    function success(curbsideLocations) { return { type: GET_CURBSIDE_LOCATIONS, curbsideLocations} }
}
function getShippingLocations() {
    return dispatch => {
        userService.GetInfo('/locations/by-type/is_shipping','')
            .then(
                data => {
                    dispatch(success(data));
                }
            ).catch(
            (err)=> {
                console.log(err)
            }
        );
    };
    function success(shippingLocations) { return { type: GET_SHIPPING_LOCATIONS, shippingLocations} }
}
