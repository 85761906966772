import styled from "styled-components";

const StyledCartItem = styled.li`
  padding: 0;
  vertical-align: middle;
  margin-top: 40px;
  &:first-child{
    margin-top: 5px;
  }
  .item-row {
    display: grid;

    //@media (max-width: 999px) {
    //  grid-template-columns: 1fr auto;
    //  grid-gap: 16px 8px;
    //}
    @media (min-width: 1000px) {
      grid-template-columns: var(--product-list-columns);
      grid-gap: var(--product-list-gap);
    }

    &.nonactive {
      opacity: 0.5;
    }
  }

  .item__node {
    display: flex;
    color: ${({theme}) => theme.colors.gray5};

    @media (min-width: 1000px) {
      justify-content: flex-start;
      //align-items: center;
    }
  }

  .item__node--price {
    //@media (max-width: 999px) {
    //  align-items: center;
    //  grid-row-start: 2;
    //  grid-column-start: 2;
    //
    //  .item__heading,
    //  .t-section-heading-small {
    //    display: none;
    //  }
    //}
  }

  //.item__node--quantity {
  //  @media (max-width: 999px) {
  //    flex-direction: column;
  //    grid-row-start: 2;
  //    grid-column-start: 1;
  //
  //    .item__heading {
  //      margin-bottom: .5rem;
  //    }
  //  }
  //}

  //.item__node--delete {
  //  @media (max-width: 999px) {
  //    grid-row-start: 1;
  //    grid-column-start: 2;
  //    justify-content: flex-end;
  //  }
  //}

  .item__heading {
    // @media (max-width: 999px) {
    //   color: ${({theme}) => theme.colors.gray3};
    // }
    @media (min-width: 1000px) {
      position: absolute !important;
      height: 1px;
      width: 1px;
      overflow: hidden;
      clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
      clip: rect(1px, 1px, 1px, 1px);
      white-space: nowrap; /* added line */
    }
  }

  .item__price-each {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-width: 100px;
    font-size: 18px;
    color: ${({theme}) => theme.colors.gray5};

    //@media (max-width: 999px) {
    //  padding-top: 1.25rem;
    //}

    .t-body-small {
      font-weight: var(--weight-regular);
      font-size: 16px;
    }
  }

  .item-img {
    margin-right: 8px;
    height: 48px;
    width: 48px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  .item-text {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    text-decoration: none;
    width: 100%;

    h4 {
      font-size: var(--type-scale-16);
      color: ${({themeProps}) => themeProps.color1};
      margin-bottom: 5px;
      margin-left: 5px
    }

    h3 {
      font-size: var(--type-scale-18);
      color: ${({themeProps}) => themeProps.color1};
      margin-bottom: 5px;
      margin-left: 5px;
    }
  }

  .remove-icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    padding: 4px;
    cursor: pointer;
    background-color: ${({theme}) => theme.colors.gray1};
    transition: all ease .2s;

    &:hover,
    &:focus {
      background-color: ${({theme}) => theme.colors.error};
      fill: ${({theme}) => theme.colors.white};
    }

    svg {
      fill: inherit;
    }
  }

  .explanation {
    margin-top: 10px;
    color: ${({theme}) => theme.colors.gray3};
    font-style: italic;
    padding-right: 5px;

    a {
      font-style: normal;
      margin-left: 4px;
      color: ${({theme}) => theme.colors.brand};
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  .actions_product-items {
    margin-top: 16px;
    margin-left: 62px;
    display: flex;
    align-items: center;
  }

  .Dropdown-control {
    border: none;
    display: flex;
    padding: 0 0 0 16px;
    width: 59px;
    justify-content: space-between;
    margin-right: 32px;

    &:hover {
      box-shadow: none;
    }
  }


`;
export default StyledCartItem;
