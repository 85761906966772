import everscoreTheme from "../../styles/themes/EverscoreTheme";
import rebudTheme from "../../styles/themes/DefaultTheme";
import SVGIcon from "../../components/utility/SVGIcon";
import React from "react";
import ButtonOutline from "../../components/ui/ButtonOutline";
import HeaderDeliveryInputEverscore from "../../components/HeaderDeliveryInputEverscore/HeaderDeliveryInputEverscore";
import MainMenuEverScore from "../../components/MainMenu/MainMenuEverScore/MainMenuEverScore";
import MobileMenuEverscore from "../../components/MobileMenu/MobileMenuEverscore/MobileMenuEverscore";
import ButtonOutlineEverscore from "../../components/ui/ButtonOutlineEverscore";
import Button from "../../components/ui/Button";
import enjoyableTheme from "../../styles/themes/EnjoyableTheme";
import HeaderDeliveryInput from "../../components/HeaderDeliveryInput/HeaderDeliveryInput";
import ButtonSecondary from "../../components/ui/ButtonSecondary";

const configEverScore = {
    contactUsConfig: {
        background:'',
        colorFonts:'',
        classMainFont: '',
        classNameTitleFont: '',
        classNameDescription: '',
        color: 'brandDark',
        pstText: 'PST',
        mainPageHeadClass1: 't-section-title',
        mainPageHeadClass2: 't-link',
        mainPageHeadClass3: '',
        mainPageHeadClass4: '',
        contactFormFont: '',
        contactFormClass: 't-section-heading',
        buttonFont: '',
        buttonColor: "",
    },
    checkoutConfig: {
        headingClass: 't-section-heading',
        classNameFont: '',
        fontName1:  '',
        iconName1:  'checked',
        iconName2:  'check',
        steps: {
            font1: '',
            font2: 't-font-NHaasGroteskDSPro-65Md',
            font3: '',
            font4: 't-font-NHaasGroteskDSPro-55Rg',
            font5: 't-font-NHaasGroteskDSPro-55Rg',
            font6: 't-font-NHaasGroteskDSPro-55Rg',
            font7: '',
            color1: everscoreTheme.colors.gray2,
            color2: '',
            color3: everscoreTheme.colors.brandDark,
            color4: everscoreTheme.colors.gray3,
            classNameFont1: '',
        },
        simpleModal: {
            color1: everscoreTheme.colors.white,
            color2: ''
        },
        mainFont1: 't-title-small',
        mainFont2: 't-font-NHaasGroteskDSPro-55Rg',
        mainFont3: '',
        mainFont4: 't-font-NHaasGroteskDSPro-55Rg',
        iconName3: 'delivery-circle-active-everscore',
        mainFont5: 't-font-NHaasGroteskDSPro-55Rg',
        iconName4: 'delivery-normal-box-everscore',
        mainFont6: '',
        dark: true,
        mainFont7: '',
        mainFont8: '',
        mainFont9: 't-font-lato-regular',
        mainText1: 'Marketplace',
        inputAuth1: {
            font: '',
            color1: '#f5f5f5',
            color2: everscoreTheme.colors.gray3,
            color3: everscoreTheme.colors.gray2,
            color4: everscoreTheme.colors.white,
            color5: everscoreTheme.colors.brandDark,
            color6: everscoreTheme.colors.gray1,
            color7: everscoreTheme.colors.gray3,
            color8: everscoreTheme.colors.brandDark,
            font1: '',
            error: everscoreTheme.colors.error,
            color9: rebudTheme.colors.gray3,
            color10: "#fff",
            color11: everscoreTheme.colors.gray3,
        },
        dropUpload: {
            color1: '',
            color2: everscoreTheme.colors.gray1,
            color3: '',
            color4: everscoreTheme.colors.brand,
            iconName1: 'img-upload-everscore',
            error: everscoreTheme.colors.error,
            font: "",
            errorText: rebudTheme.colors.gray4,
        },
        themeLocationSearchInput: {
            font1: 't-form-input',
            font2: '',
            font3: '',
            zipColor1: everscoreTheme.colors.white,
            zipColor2: everscoreTheme.colors.gray3,
            padding: '0',
            height1: '36px',
            height2: '26px',
            borderRadius: '0',
            border: `none`,
            borderBottom: `solid 1px ${rebudTheme.colors.flowerBrandDark}`,
            font4: '',
            color1: '#ffffff',
            color2: ''
        },
        iconName5: 'store-pickup-everscore',
        checkoutStyles: {
            color1: '',
            font1: 'NHaasGroteskDSPro-55Rg, sans-serif',
            color2: '',
            color3: everscoreTheme.colors.gray4,
            color4: everscoreTheme.colors.brandDark,
            color5: everscoreTheme.colors.white,
            color6: everscoreTheme.colors.brand,
            color7: everscoreTheme.colors.white,
            color8: '',
            color9: '',
            color10: '',
            color11: everscoreTheme.colors.gray3,
            color12: everscoreTheme.colors.gray3,
            color13: everscoreTheme.colors.everscoreBrandBright,
            color14: everscoreTheme.colors.gray1,
            color15: everscoreTheme.colors.everscoreBrandBright,
            color16: everscoreTheme.colors.white,
            color17: everscoreTheme.colors.gray4,
            color18: everscoreTheme.colors.everscoreBrandBright,
            color19: '',
            ontSize1: '',
            color20: everscoreTheme.colors.gray3,
            color21: everscoreTheme.colors.brandDark,
            color22: '',
            color23: everscoreTheme.colors.brandDark,
            color24: everscoreTheme.colors.brand,
            color25: '',
            color26: everscoreTheme.colors.gray3,
            color27: everscoreTheme.colors.gray4,
            color28: '',
            color29: everscoreTheme.colors.gray1,
            color30: everscoreTheme.colors.gray2,
            color31: everscoreTheme.colors.black,
            color32: everscoreTheme.colors.white,
            margin: '0 auto',
            overflow: 'initial',
            color33: '#757575',
            color34: everscoreTheme.colors.everscoreBrandBright,
            error: everscoreTheme.colors.warning,
            maxWidth: 'initial',
            font2: '',
        },
        spinner: {
            font1: '',
            font2: '',
            color1: ''
        },
        summaryConfig: {
            font1: '',
            font2: '',
            font3: '',
            font4: '',
        },
        priceItemColor: '#222',
        visibleRewards: false
    },
    headerConfig:{
        icon1: "everscore-logo__horizontal",
        icon2: (props) => <SVGIcon name="flower-down-icon" {...props}/>,
        className1: 't-shelf-heading-multishop',
        icon3: "everscore-bag-icon",
        className2: '',
        toggleMenu: true,
        visibleCartIcon: true,
        styling: {
            color5: everscoreTheme.colors.brandDark,
            fontFamily: "",
            borderBottom1: `1px solid ${everscoreTheme.gray2}`,
            bgColor1: "",
            fontSize3: "",
            padding1: "10px 8px 10px 0",
            padding2: "0 32px",
            margin2: "",
            width1: "154px",
            height1: "50px",
            width2: "192px",
            height2: "30px",
            margin: "initial",
            fontSize1: "",
            margin1: "10px",
            positionRight: "-10px",
            positionTop: "0",
            padding3: "4px",
            minWidth: "20px",
            height3: "20px",
            color: everscoreTheme.colors.brandLight,
            bgColor2: everscoreTheme.colors.brand,
            borderRadius: "10px",
            fontSize2: "12px",
            padding4: "8px",
            marginLeft: "20px",
            padding: "1.5rem 12px",
            marginLeft1: "0",
            bgColor3: everscoreTheme.colors.background,
            padding5: "",
            alignSelf: "",
        },
        inputSearchAhead: {
            color6: everscoreTheme.colors.brandDark,
            icon: "everscore-search-icon",
            button1: (props) => <ButtonOutline {...props} > SEARCH ALL PRODUCTS </ButtonOutline>,
            padding: "",
            svgPadding: "8px",
            svgWidth: "22px",
            svgHeight: "22px",
            bgColor: "",
            borderBottom: `solid 1px ${everscoreTheme.colors.gray4}`,
            inputPositionTop: "50%",
            inputPositionRight: "0",
            color1: everscoreTheme.colors.gray3,
            color2: everscoreTheme.colors.gray4,
            color3: "",
            bgColor2: everscoreTheme.colors.gray1,
            color4: "",
            color5: "",
            bgColor3: "",
        },
        profileDropDownConfig:{
            icon: 'everscore-user-icon',
            rewards: true,
            className1: "t-copyright-everscore ",
            text: "",
            width: "40px",
            fontFamily: "",
            color: rebudTheme.colors.brand,
            bgColor: ""
        },
        headerMainSecondaryConfig:{
            borderBottom: "",
            padding: "",
            bgColor4: "",
            headerDeliveryInput: ()=> <HeaderDeliveryInput/>
        },
        mainMenu: (props) => <MainMenuEverScore {...props} />,
        mobileMenu: (props) => <MobileMenuEverscore {...props} />,
    },
    modalAddressConfig: {
        icon1: "everscore-logo__vertical",
        icon3: "delivery-circle-active",
        helloText: false,
        verifyText: <>
            <div className="verification__form__label-text-everscore first t-font-NHaasGroteskDSPro-65Md">Get ready to explore the most</div>
            <div className="verification__form__label-text-everscore t-font-NHaasGroteskDSPro-65Md">innovative cannabis brands.</div>
            <div className="verification__form__label-text-second t-font-NHaasGroteskDSPro-55Rg">Enter your address and discover cannabis products available in your area</div>
        </>,
        icon2: "close",
        headingText1: true,
        headingText2: <p className="t-sub-heading verification__form__age-copy">Are you 21+? or 18+ with a medical card?</p>,
        color1: "var(--color-brand)",
        button: (props) => <ButtonOutlineEverscore {...props} className="verification__yes__button t-font-NHaasGroteskDSPro-55Rg" />,
        placeholder: '',
        className1: 't-sub-heading',
        className2: 't-font-NHaasGroteskDSPro-45Lt',
        className3: "buttons-container",
        styling: {
            color10: everscoreTheme.colors.brand,
            minHeight1: "497px",
            padding1: "10.11px 0 25.35px",
            minHeight2: "unset",
            backgroundRepeat: "round",
            padding2: "23.38px 0 34.23px",
            marginBottom1: "",
            width1: "140px",
            height1: "",
            marginBottom2: "1.5rem",
            marginTop3: "2.5rem",
            width2: "164px",
            height2: "",
            paddingBottom1: '',
            color: everscoreTheme.colors.gray4,
            padding3: "8px 31px",
            bgColor1: "transparent",
            borderColor1: everscoreTheme.colors.gray9,
            color1: everscoreTheme.colors.gray4,
            fontSize1: "var(--type-scale-18)",
            width: "142px",
            bgColor2: everscoreTheme.colors.gray4,
            borderColor2: "",
            bgColor3: "transparent",
            borderColor3: everscoreTheme.colors.gray4,
            color2: everscoreTheme.colors.gray4,
            marginTop: "",
            marginLeft: "",
            width3: "110",
            fontSize2: "var(--type-scale-14)",
            padding: "8px 31px",
            maxWidth: "590px",
            marginBottom3: "40px",
            marginTop1: "28px",
            maxWidth1: "290px",
            marginBottom4: "75px",
            color3: everscoreTheme.colors.gray4,
            color4: everscoreTheme.colors.gray4,
            fontWeight: "var(--weight-bold)",
            marginBottom5: "23px",
            marginBottom6: "33px",
            borderBottom2: `1px solid ${everscoreTheme.colors.gray4}`,
            lineHeight: "19px",
            fontFamily: "var(--font-NHaasGroteskDSPro-45Lt)",
            fontSize3: "12px",
            linHeight2: "14px",
            color5: everscoreTheme.colors.brandDark,
            right: "14px",
            top: "0.625rem",
            fill: everscoreTheme.colors.gray4,
            width4: "12px",
            height4: "12px",
            top2: "0.325rem",
            margin: "0 8px 3% 8px",
            maxWidth2: "724px",
            bgColor: everscoreTheme.colors.white,
            border: "",
            borderRadius: "",
            maxWidth3: "",
            width5: "100%",
            minHeight3: "",
            width6: "144px",
            minHeight4: "",
            border2: `1px solid ${everscoreTheme.colors.brandDark}`,
            marginLeft2: "",
            borderRadius2: "",
            color6: everscoreTheme.colors.white,
            bgColor4: "transparent",
            color7: "",
            border3: "",
            borderColor: everscoreTheme.colors.brand,
            paddingRight: "40px",
            padding5: "",
            width7: "",
            height: "",
            fontSize: '',
            padding4: "",
            width8: "",
            height5: "",
            borderRadius3: "",
            fontSize5: "",
            margin3: "",
            paddingLeft: "",
            marginBottom: ".75rem",
            marginBottom7: "",
            fontSize4: "",
            fontSize6: "",
            fontSize7: "",
            padding6: "",
            fontSize8: "",
            padding7: "",
            width9: "16px",
            height6: "16px",
            alignSelf: "center",
            top3: "",
            right3: "",
            fill2: "",
            width10: "",
            height7: "",
            width11: "",
            height8: "",
            marginBottom8: "",
            fontSize9: "",
            bgImage: "",
            fontFamily5: "",
            marginLeft3: "",
            fontFamily1: "",
            fontWeight1: "",
            radius: "0",
            color12: everscoreTheme.colors.brandDark,
            color13: ""
        }
    },
    cartItemsStyles:{
        color1: everscoreTheme.colors.webGrey4,
        color2: everscoreTheme.colors.gray4,
    },
    cartConfig: {
        mainConfig: {
            button1: (props) => <Button {...props} />,
            className1: "t-shelf-heading-multishop",
            className2: "t-eyebrow-everscore",
            className3: "t-eyebrow-everscore",
            className4: "t-lineage-everscore",
            className5: "t-copyright-everscore",
            className6: "t-lineage-everscore",
            className7: "",
            dark: true,
            className8: "t-eyebrow-everscore",
            className9: "t-copyright-everscore",
            className10: "",
            promoComponent: true,
        },
        mainStyled:{
            font1: "",
            bgColor: "",
            color1: everscoreTheme.colors.gray3,
            color2: "",
            bgColor2: "",
            bgColor3: "",
            color3: "",
            color4: everscoreTheme.colors.gray1,
            color5: "",
            borderBottom: `1px solid ${everscoreTheme.colors.gray2}`,
            font2: "",
            color6: "",
            color7: everscoreTheme.colors.gray3,
            color8: everscoreTheme.colors.gray4,
            bgColor4: everscoreTheme.colors.gray1,
            color9: everscoreTheme.colors.brand,
            bgColor5: "",
            color10: "",
            color11: "#888888",
        },
        emptyCart: {
            className1: "t-shelf-heading-multishop",
            className2: "t-lineage-everscore",
            color1: everscoreTheme.colors.gray3,
        },
        addOnsProps: {
            className1: "t-body-everscore",
            className2: "t-copyright-everscore",
            color1: "",
            color2: everscoreTheme.colors.gray3,
        },
        addOnsShelfProps: {
            className1: "t-eyebrow-everscore",
            bgColor: everscoreTheme.colors.white
        },
        cartItemProps: {
            className1: "t-lineage-everscore",
            className2: "t-eyebrow-everscore",
            className3: "t-body-everscore",
            className4: "t-paragraph-everscore",
            className5: "t-eyebrow-everscore",
            className6: "t-eyebrow-everscore",
            className7: "",
        },
        productCounterProps: {
            color1: rebudTheme.colors.gray2,
            color2: "#fff",
        },
        cartItemPromotionProps: {
            className1: "t-body-everscore",
            button: (props) => <Button {...props} />,
            className2: "t-body-everscore",
            fontFamily: "var(--font-NHaasGroteskDSPro-55Rg)",
            bgColor: "",
            bgColor2: everscoreTheme.colors.brandDark,
            color1: everscoreTheme.colors.white,
            color2: everscoreTheme.colors.brandDark,
            fill: everscoreTheme.colors.brandDark,
            bgColor3: "var(--background-color)",
        }
    },
    productDetailsConfig:{
        viewPersonality: false,
        button: (props) => <Button {...props} dark />,
        className1: "t-paragraph-everscore",
        className2: "t-shelf-heading-multishop",
        className3: "t-eyebrow-everscore",
        className4: "t-footer-everscore",
        className5: "tt-footer-everscore",
        className6: "t-eyebrow-everscore",
        className7: "t-shelf-heading-multishop",
        className8: "t-paragraph-everscore",
        className9: "t-lineage-everscore",
        className10: "t-paragraph-everscore",
        className11: "t-shelf-heading-multishop",
        className12: "t-font-Moderat-Medium",
        cardText: "ADD TO BAG",
        productDetailsConfigStyled: {
            font: "var(--font-NHaasGroteskDSPro-45Lt) !important",
            marginTop: "2rem",
            marginBottom: ".5rem",
            margin: "",
            color1: "",
            color2: "",
            color3: "",
            color4: "",
            color5: "",
        },
        isFirst: "Personality",
        productDescriptionDetailsConfigStyled: {
            font: "var(--font-Moderat-Bold)",
            fontWeight: "var(--weight-bold)",
            lineHeight1: "1.17",
            fontSize1: "var(--type-scale-22)",
            lineHeight2: "1.33",
            fontSize2: "var(--type-scale-24)",
            lineHeight3: "",
            letterSpacing: ".5px",
            transition: "var(--button-transition)",
            font2: "var(--font-Moderat-Medium)",
            fontSize3: "var(--type-scale-16)",
            fontSize4: "var(--type-scale-18)",
        },
        productCardConfig:{
            className1: "t-footer-everscore",
            className2: "t-footer-everscore",
            className3: "t-footer-everscore",
            className4: "t-eyebrow-everscore",
            className5: "t-paragraph-everscore",
            className6: "t-shop-link-everscore",
            className7: "",
            className8: "",
            className9: "",
            className10: "t-eyebrow-everscore",
            productCardConfigStyled: {
                color1: "",
                paddingLeft: "",
                paddingRight: "",
                marginRight: "6px",
                color2: everscoreTheme.colors.gray7,
                borderRadius: "50px",
                font: "var(--font-Moderat-Medium)",
                bgColor1: everscoreTheme.colors.everscoreGray10,
                borderColor1: everscoreTheme.colors.brandDark,
                color: everscoreTheme.colors.white,
                bgColor2: everscoreTheme.colors.everscoreGray10,
                color3: everscoreTheme.colors.brandDark,
                lineHeight: 1.8,
                color4: "",
                color5: "",
                color6: "",
                color7: "",
                color8: everscoreTheme.colors.everscoreGray9,
            },
            cardText: "ADD TO BAG",
            className11: "",
        },
    },
    homeConfig: {
        personalityActive: false,
        dynamicShelfActive: false,
        heroBannerActive:  true,
        brandSpotlightActive:  true,
        productSalesActive:  true,
        staffPicksActive:  true,
        discoverAllActive:  true,
        styles: {
            bgColor: everscoreTheme.colors.white,
            fontFamily: '',
            gridTemplate: "repeat(auto-fill, minmax(222px, 1fr))",
        },
        heroBanner:{
            bannerText: true,
            styles:{
                display: "grid",
                gridTemplateColumns: "1fr min(1808px, 100%) 1fr",
                marginTop: "1.5rem",
                minHeight: "394px",
                marginTop1: "3rem",
                marginBottom: "3rem",
                marginLeft: "16px",
                width: "calc(100% - 12px)",
                marginLeft1: "36px",
                width1: "calc(100% - 32px)",
                hover: "scale(1.02)",
                maxWidth: "1744px",
            }
        },
        sectionShelfGridConfig: {
            className1: "t-shelf-heading-multishop",
            className2: "t-shelf-link-mono-everscore",
            styles:{
                fontFamily: "",
                marginTop: "4.813em",
                color: everscoreTheme.colors.brandDark,
                display: "inline-flex",
                paddingBottom: "",
                backgroundImage: ``,
                backgroundPosition: "", /*OR bottom left*/
                backgroundSize: "",
                backgroundRepeat: "",
                transition: "",
                position: "",
                color1: everscoreTheme.colors.gray3,
                margin1: "8px",
                backgroundPosition1: "",
                backgroundSize1: "",
                textDecoration: "underline",
                marginTop1: "4px",
                borderColor: everscoreTheme.colors.brandDark,
                fontSize: "",
                fontFamily1: "",
                fontSize1: "",
                fontSize2: "",
            }
        },
        brandSpotlightConfig:{
            subHeading: true,
            presentation: true,
            className1: "t-footer-everscore",
            className2: "t-shelf-heading-multishop",
            className3: "t-paragraph-everscore",
            className4: "t-paragraph-everscore",
            styles: {
                bgColor: everscoreTheme.colors.brandLightEverscore,
                marginLeft: "",
                width: "calc(100% - 32px)",
                maxWidth: "100%",
                marginLeft1: "0",
                padding: "0 0 0 54px",
                maxWidth1: "1744px",
                width1: "calc(100% - 64px)",
                gridTemplateColumns: "2fr 3fr",
                gridColumnGap: "50px",
                marginLeft2: "0",
                maxWidth2: "1744px",
                width2: "100%",
                padding1: "",
                gridColumn: 1,
                color: everscoreTheme.colors.brandDark,
                fontFamily: "",
                textTransform: "",
                paddingBottom: "1.5rem",
                marginBottom: "auto",
                color1: everscoreTheme.colors.brandDark,
                fontSize: "",
                fontFamily1: "",
                fontSize1: "",
                padding2: "12px 10px",
                maxWidth3: "225px",
                width3: "100%",
                border: `1px solid #161115`,
                borderRadius: "",
                fontSize2: "",
                color3: "",
                bgColor1: "",
                width4: "",
                padding3: "",
                fontSize3: "",
                bgColor2: everscoreTheme.colors.brand,
                gridColumn2: 2,


                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding4: "4.75rem 0 5.625rem 4.475rem",
                paddingTop: "",
                color2: everscoreTheme.colors.brandDark,
                lineHeight: "24px",
                color4: everscoreTheme.colors.brandDark,
                borderColor: everscoreTheme.colors.brand,
                color5: everscoreTheme.colors.white

            }
        },
        brandCardConfig:{
            className1: "t-footer-everscore",
            visibleBrandDesc: true,
            styles: {
                paddingLeft : "8px",
                color: everscoreTheme.colors.gray3,
                color1: everscoreTheme.colors.brandDark,
                border: ``,
                borderRadius: "",
                width: "100%",
                margin: "",
                paddingLeft1: "",
                marginLeft1: "",
                fontSize: "18px",
                fontFamily: "",
            }
        }
    },
    privacyConfig:{
        className1: 't-section-heading',
        fontFamily: '',
        className2: 't-section-subheading',
    },
    termsConfig:{
        className1: 't-section-heading',
        fontFamily: '',
        className2: 't-section-subheading',
        className3: 't-font-NHaasGroteskDSPro-65Md',
        className4: 't-font-NHaasGroteskDSPro-65Md',
    },
    orderDetailsConfig: {
        mainConfig: {
            className1: '',
            className2: '',
            className3: '',
        },
        orderRateConfig: {
            logoName: 'everscore-logo__vertical',
            className1: 't-font-NHaasGroteskDSPro-65Md',
            className2: 't-font-NHaasGroteskDSPro-55Rg',
            button: (props) => <ButtonOutline {...props} />,
        },
        timeLineStyledConfig: {
            bgColor: everscoreTheme.colors.success
        }
    }
}
export default configEverScore