import styled from "styled-components";

const StyledHeaderSimpleBundle = styled.header`
  padding-bottom: 10px;
  padding-top: 10px;
  width: 100%;
  min-height: 60px;
  display: flex;
  align-items: center;
  padding-left: 64px;
  //justify-content: center;
  //border-bottom: 1px solid ${({theme}) => theme.colors.gray2};
  background-color: ${({theme}) => theme.colors.bundleBlack};

  .header-simple__link {
    display: inline-flex;
    text-decoration: none;
  }
  
  .header-simple__logo {
    display: inline-flex;
    height: auto;

    @media (max-width: 859px) {
        width: 86px;
        height: 18px;
    }
    @media (min-width: 860px) {
        width: 116px;
        height: 23px;
    }
  }

  .header-simple__logo-other {
    display: inline-flex;
    height: auto;

    @media (max-width: 859px) {
      max-height: 28px;
    }
    @media (min-width: 860px) {
      max-height: 38px;
    }
  }
  .bundle-logo {
    width: 120px;
    height: 60px;
  }

`;
export default StyledHeaderSimpleBundle;