/**
 * Created by user on 27.07.2018.
 */
import React, { Component } from "react";
import { connect } from "react-redux";
class Empty extends Component {
  render() {
    return <div></div>;
  }
}
function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(Empty);
