import styled from "styled-components";

const HeroPromo = styled.div`
  width: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.colors.highlight};

  @media (max-width: 859px) {
    flex-direction: column;
  }

  .hero-promo__wrap {
    margin-right: auto;
    margin-left: auto;
    max-width: 1808px;
    width: 100%;
    display: flex;
    flex-direction: row;

    @media (max-width: 859px) {
      padding: .5rem 16px;
    }
    @media (min-width: 860px) {
      padding: .5rem 32px;
      justify-content: center;
    }
  }

/*   .hero-promo__heading {
    @media (max-width: 859px) {
      margin-top: 1rem;
      margin-bottom: .5rem;
    }
    @media (min-width: 860px) {
      margin-top: 1.375rem;
      margin-bottom: .25rem;
    }
  } */

  .hero-promo__copy {
    color: ${({ theme }) => theme.colors.gray4};
  }

  .hero-promo__copy-wrap {
    margin-right: 8px;
  }

  .hero-promo__link {
    padding-bottom: 1px;
    color: ${({ theme }) => theme.colors.gray4};
    font-size: var(--type-scale-16);
    flex-shrink: 0;
    transition: var(--button-transition);
    text-decoration: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray4};

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.colors.brand};
      border-color: ${({ theme }) => theme.colors.brand};
    }

/*     @media (max-width: 859px) {
      padding-right: 24px;
      padding-left: 24px;
    }
    @media (min-width: 860px) {
      padding-right: 32px;
      padding-left: 32px;
    } */
  }
`;

export default HeroPromo;