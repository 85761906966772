import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledMainMenuCardFlower = styled(Link)`
  display: flex;
  text-decoration: none;
  align-items: center;
  font-weight: normal;
  @media (max-width: 459px) {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
  @media (min-width: 460px) {
    padding-top: .3rem;
    padding-bottom: .3rem;
  }
  &:hover,
  &:focus {
    .main_menu-card__heading {
      text-decoration: underline;
    }
  }

  .main_menu-card__heading {
    color: ${({theme}) => theme.colors.brandDark};
    transition: var(--button-transition);
    line-height: 1.5;
  }
`;

export default StyledMainMenuCardFlower;
