import React from "react";
import PropTypes from "prop-types";
import StyledFooterMain from "./FooterMain.styled";
import { Link, useLocation } from "react-router-dom";
import SVGIcon from "../utility/SVGIcon";
import EmailSubscriptionCTA from "../EmailSubscriptionCTA/EmailSubscriptionCTA";
import { storeType, storeName } from "../../config/constants";
import LogoLogickit from "../../images/LK_Logo2.png"

const FooterMain = React.memo(props => {
  const { license_number, simple } = props
  const year = new Date();
  const currentYear = year.getFullYear();
  const { pathname } = useLocation();
  const isSEOLocationPAge = pathname.includes("/cannabis-delivery-");

  return (
    <>
    <StyledFooterMain simple={simple} data-testid="footerMain">
      {!simple && !isSEOLocationPAge &&
        <EmailSubscriptionCTA />
      }
      <div className="footer-wrap">
        {!simple && storeType === 'rebud' &&
          <SVGIcon name="rebud-arch-exotic" className="footer-home__logo" />
        }
        <ul className="footer-home__links">
          <li className="footer-home__link">
            <Link to="/about" className="t-footer">About</Link>
          </li>
          <li className="footer-home__link">
            <Link to="/contact" className="t-footer">Contact Us</Link>
          </li>
          <li className="footer-home__link">
            <Link to="/privacy" className="t-footer">Privacy Policy</Link>
          </li>
          <li className="footer-home__link">
            <Link to="/terms" className="t-footer">Terms</Link>
          </li>
          <li className="footer-home__link">
            <Link to="/careers" className="t-footer">Careers</Link>
          </li>
        </ul>

        <h3 className="footer-home__heading t-footer-heading">Let’s Be Buds</h3>
        <ul className="footer-home__socials">
          <li className="footer-home__social">
            <a href="https://www.instagram.com/official.rebud/" className="t-footer">
              <span className="t-hidden">Instagram</span>
              <SVGIcon name="instagram" />
            </a>
          </li>
          <li className="footer-home__social">
            <a href="https://twitter.com/RebudOfficial" className="t-footer">
              <span className="t-hidden">Twitter</span>
              <SVGIcon name="twitter" />
            </a>
          </li>
        </ul>

        <a href="https://login.cannaffiliate.com/signup" className="t-body footer-home__affiliate">Affiliate Registration</a>

        <p className="t-body footer-home__copywrite">Copyright &copy; {currentYear} {storeName}, all rights reserved.</p>
        
        {license_number && 
          <p className="t-body footer-home__license">Store license number: {license_number}.</p>
        }
        <a href="https://www.logickit.io/" target="_blank" className="t-body footer-home__license d-flex-container">
          Powered by &nbsp; <img src={LogoLogickit} height={20} alt="logo"/>
        </a>
      </div>
    </StyledFooterMain>
    </>
  );
});

FooterMain.propTypes = {
  license_number: PropTypes.string,
  simple: PropTypes.bool
};

export default FooterMain;
