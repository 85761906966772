import React from "react";
import StyledTooltip from "./Tooltip.styled"
import {ReactSVG} from "react-svg";

const Tooltip = (props) => {
    const { classname } = props;
    return <StyledTooltip>
        <div className="icon-tooltip">
            <ReactSVG src="/images/icons/info-icon.svg" className="icon_theme" width={12} />
        </div>
        <span className={`${classname ? classname : ''} tooltiptext showTooltip`} >
        {props.children}
        </span>
    </StyledTooltip>
}

export default Tooltip;