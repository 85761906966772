import { userService } from '../services';
import {GET_PROMO, GET_PROMO_BANNER, GET_WELCOME_PROMO} from '../config/actionTypes';

export const promoActions = {
  getPromo,
  getWelcomePromo,
    getPromoImages
};

function getPromo(date) {
    return dispatch => {
        userService.GetInfo('/promo_codes', date)
            .then(
                promo => {
                    dispatch(success(promo));
                }
            );
    };
    function success(promo) { return { type: GET_PROMO, promo } }
}
function getPromoImages(date) {
    return dispatch => {
        userService.GetInfo('/promo_bar/images', date)
            .then(
                banner => {
                    dispatch(success(banner));
                }
            );
    };
    function success(banner) { return { type: GET_PROMO_BANNER, banner } }
}

function getWelcomePromo() {
  return dispatch => {
    userService.GetInfo('/promo_codes/welcome', '')
      .then(
        promo => {
          if(promo?.code) {
            dispatch(success(promo));
          }
          if(promo.status) {
            localStorage.setItem("mobilePromo", "true")
          }
        }
      );
  };
  function success(promo) { return { type: GET_WELCOME_PROMO, promo } }
}