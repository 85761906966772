import React from "react";
import PropTypes from "prop-types";
import StyledLoadingEllipsis from "./LoadingEllipsis.styled";

const LoadingEllipsis = React.memo(props => {
    const { className, color, size } = props;

    return (
        <StyledLoadingEllipsis className={className} color={color} size={size}>
            <svg className="dot" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
                <circle cx="4" cy="4" r="4"/>
            </svg>
            <svg className="dot" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
                <circle cx="4" cy="4" r="4"/>
            </svg>
            <svg className="dot" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
                <circle cx="4" cy="4" r="4"/>
            </svg>
            <p className="t-hidden">loading</p>
        </StyledLoadingEllipsis>
    );
});
LoadingEllipsis.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string
};

export default LoadingEllipsis;
