import flowerTheme from "../../styles/themes/FlowerTheme";
import rebudTheme from "../../styles/themes/DefaultTheme";
import React from "react";
import SVGIcon from "../../components/utility/SVGIcon";
import ButtonSecondary from "../../components/ui/ButtonSecondary";
import bundleTheme from "../../styles/themes/BundleTheme";
import HeaderDeliveryInputFlower from "../../components/HeaderDeliveryInputFlower/HeaderDeliveryInputFlower";
import MainMenuFlower from "../../components/MainMenu/MainMenuFlower/MainMenuFlower";
import MobileMenuFlower from "../../components/MobileMenu/MobileMenuFlower/MobileMenuFlower";
import ButtonOutlineFlower from "../../components/ui/ButtonOutlineFlower";
import Button from "../../components/ui/Button";
import {history} from "../../helpers";
import everscoreTheme from "../../styles/themes/EverscoreTheme";
import BrandSpotlight from "../../components/BrandSpotlight/BrandSpotlight";
import HeaderDeliveryInput from "../../components/HeaderDeliveryInput/HeaderDeliveryInput";
import ButtonOutline from "../../components/ui/ButtonOutline";

const configFlower = {
    contactUsConfig: {
        background:'',
        colorFonts:'',
        classMainFont: '',
        classNameTitleFont: 't-font-Moderat-Bold',
        classNameDescription: 't-font-Moderat-Medium',
        color: 'brandDark',
        pstText: 'PST',
        mainPageHeadClass1: 't-shelf-heading-flower',
        mainPageHeadClass2: 't-font-Moderat-Mono-Regular',
        mainPageHeadClass3: 't-font-Moderat-Medium',
        mainPageHeadClass4: 't-font-Moderat-Mono-Regular',
        contactFormFont: 'Moderat-Medium, sans-serif',
        contactFormClass: 't-shelf-heading-flower',
        buttonFont: 'Moderat-Bold, sans-serif',
        buttonColor: "",
    },
    checkoutConfig: {
        headingClass: 't-shelf-heading-flower',
        classNameFont1: 't-font-Moderat-Medium',
        fontName1:  't-font-Moderat-Mono-Regular',
        iconName1:  'checked-flower',
        iconName2:  'check-flower',
        steps: {
            font1: 't-font-Moderat-Mono-Regular',
            font2: 't-shelf-heading-flower',
            font3: 't-font-Moderat-Mono-Regular',
            font4: 't-font-Moderat-Bold',
            font5: 't-font-Moderat-Medium',
            font6: 't-font-Moderat-Medium',
            font7: 't-font-Moderat-Mono-Regular',
            color1: flowerTheme.colors.gray2,
            color2: '',
            color3: flowerTheme.colors.brandDark,
            color4: flowerTheme.colors.gray3,
            classNameFont1: '',

        },
        simpleModal: {
            color1: flowerTheme.colors.white,
            color2: ''
        },
        mainFont1: 't-description-flower-light',
        mainFont2: 't-description-flower-light',
        mainFont3: 't-font-Moderat-Medium',
        mainFont4: 't-font-Moderat-Medium',
        iconName3: 'delivery-circle-active-flower',
        mainFont5: 't-paragraph-flower',
        iconName4: 'delivery-normal-box-flower',
        mainFont6: 't-description-flower',
        dark: true,
        mainFont7: 't-font-Moderat-Mono-Regular',
        mainFont8: 't-font-Moderat-Medium',
        mainFont9: 't-font-Moderat-Medium',
        mainText1: 'HOF',
        inputAuth1: {
            font: '',
            color1: '#f5f5f5',
            color2: flowerTheme.colors.gray3,
            color3: flowerTheme.colors.gray2,
            color4: flowerTheme.colors.white,
            color5: flowerTheme.colors.brandDark,
            color6: flowerTheme.colors.gray1,
            color7: flowerTheme.colors.gray3,
            color8: flowerTheme.colors.brandDark,
            font1: 'Moderat-Medium, sans-serif',
            error: flowerTheme.colors.warning,
            color9: rebudTheme.colors.gray3,
            color10: "#fff",
            color11: flowerTheme.colors.gray3
        },
        dropUpload: {
            color1: '',
            color2: flowerTheme.colors.gray1,
            color3: '',
            color4: flowerTheme.colors.brand,
            iconName1: 'img-upload-flower',
            error: flowerTheme.colors.error,
            font: "t-paragraph-flower",
            errorText: rebudTheme.colors.gray4,
        },
        themeLocationSearchInput: {
            font1: 't-font-Moderat-Medium t-form-input',
            font2: 't-font-Moderat-Mono-Regular',
            font3: 't-font-Moderat-Medium',
            zipColor1: flowerTheme.colors.white,
            zipColor2: flowerTheme.colors.gray3,
            padding: '0',
            height1: '36px',
            height2: '26px',
            borderRadius: '0',
            border: `none`,
            borderBottom: `solid 1px ${flowerTheme.colors.flowerBrandDark}`,
            font4: "Moderat-Mono",
            color1: '#ffffff',
            color2: ''
        },
        iconName5: 'store-pickup',
        checkoutStyles: {
            color1: '',
            font1: '',
            color2: '',
            color3: flowerTheme.colors.gray4,
            color4: flowerTheme.colors.brandDark,
            color5: flowerTheme.colors.white,
            color6: flowerTheme.colors.brand,
            color7: flowerTheme.colors.white,
            color8: '',
            color9: '',
            color10: '',
            color11: flowerTheme.colors.gray3,
            color12: flowerTheme.colors.gray3,
            color13: flowerTheme.colors.gray2,
            color14: flowerTheme.colors.gray1,
            color15: flowerTheme.colors.brand,
            color16: flowerTheme.colors.white,
            color17: flowerTheme.colors.black,
            color18: flowerTheme.colors.brandDark,
            color19: '',
            ontSize1: '',
            color20:  flowerTheme.colors.gray3,
            color21: flowerTheme.colors.brandDark,
            color22: '',
            color23: flowerTheme.colors.brandDark,
            color24: flowerTheme.colors.brand,
            color25: '',
            color26: flowerTheme.colors.gray3,
            color27: flowerTheme.colors.gray4,
            color28: '',
            color29: flowerTheme.colors.gray1,
            color30: flowerTheme.colors.gray2,
            color31: flowerTheme.colors.black,
            color32: flowerTheme.colors.white,
            margin: '0 auto',
            overflow: 'initial',
            color33: '#757575',
            color34: flowerTheme.colors.brandDark,
            error: flowerTheme.colors.warning,
            maxWidth: '167px',
            font2: 'Moderat-Mono',
        },
        summaryConfig: {
            font1: 't-font-Moderat-Bold',
            font2: 't-font-Moderat-Medium',
            font3: 't-font-Moderat-Mono-Regular',
            font4: 't-paragraph-flower',
        },
        spinner: {
            font1: '',
            font2: '',
            color1: ''
        },
        priceItemColor: '#222',
        visibleRewards: false
    },
    headerConfig:{
        icon1: "flower-header-logo",
        icon2: (props) => <SVGIcon name="flower-down-icon" {...props}/>,
        className1: 't-shop-flower',
        icon3: "flower-cart-icon",
        className2: 't-font-Moderat-Medium',
        toggleMenu: true,
        visibleCartIcon: true,
        styling: {
            color5: flowerTheme.colors.brandDark,
            fontFamily: "",
            borderBottom1: `1px solid ${flowerTheme.colors.flowerBrandDark}`,
            bgColor1: "",
            fontSize3: "",
            padding1: "10px 8px 16px 4px",
            padding2: "",
            margin2: "",
            width1: "112px",
            height1: "52px",
            width2: "130px",
            height2: "60px",
            margin: "initial",
            fontSize1: "",
            margin1: "10px",
            positionRight: "-10px",
            positionTop: "0",
            padding3: "4px",
            minWidth: "20px",
            height3: "20px",
            color: flowerTheme.colors.white,
            bgColor2: flowerTheme.colors.brand,
            borderRadius: "10px",
            fontSize2: "11px",
            padding4: "8px",
            marginLeft: "20px",
            padding: "1.25rem 12px",
            marginLeft1: "50px",
            bgColor3: flowerTheme.colors.white,
            padding5: "16px 64px 0 32px",
            alignSelf: "flex-end",
        },
        inputSearchAhead: {
            color6: flowerTheme.colors.brandDark,
            icon: "flower-search-icon",
            button1: (props) => <ButtonSecondary {...props}> SEARCH ALL PRODUCTS </ButtonSecondary>,
            padding: "2rem",
            svgPadding: "8px",
            svgWidth: "22px",
            svgHeight: "22px",
            bgColor: "",
            borderBottom: `solid 1px ${flowerTheme.colors.gray4}`,
            inputPositionTop: "50%",
            inputPositionRight: "0",
            color1: flowerTheme.colors.gray3,
            color2: flowerTheme.colors.gray4,
            color3: "",
            bgColor2: flowerTheme.colors.gray1,
            color4: "",
            color5: "",
            bgColor3: "",
        },
        profileDropDownConfig:{
            icon: 'flower-user-icon',
            rewards: false,
            className1: "t-body-small ",
            text: "",
            width: "40px",
            fontFamily: "",
            color: bundleTheme.colors.bundleBlack,
            bgColor: bundleTheme.colors.bundleGrey
        },
        headerMainSecondaryConfig:{
            borderBottom: `1px solid ${flowerTheme.colors.gray2}`,
            padding: `6px 32px`,
            bgColor4: "",
            headerDeliveryInput: ()=> <HeaderDeliveryInput/>
        },
        mainMenu: (props) => <MainMenuFlower {...props} />,
        mobileMenu: (props) => <MobileMenuFlower {...props} />,
    },
    modalAddressConfig: {
        icon1: "flower-header-logo",
        icon3: "delivery-circle-active",
        helloText: false,
        verifyText: <div className="t-sub-display verification__form__label-text">Please verify your delivery address</div>,
        icon2: "close",
        headingText1: false,
        headingText2: <p className="t-sub-display verification__form__age-copy">Are you 21 or Over? or 18 with a medical card?</p>,
        color1: "var(--color-flowerBrandConst)",
        button: (props) => <ButtonOutlineFlower {...props} className="verification__age__button"/>,
        placeholder: '',
        className1: 't-sub-heading',
        className2: '',
        className3: "buttons-container",
        styling: {
            color10: flowerTheme.colors.brand,
            minHeight1: "497px",
            padding1: "20px 10px 40px",
            minHeight2: "unset",
            backgroundRepeat: "no-repeat",
            padding2: "36px 0",
            marginBottom1: "",
            width1: "140px",
            height1: "64px",
            marginBottom2: "1.5rem",
            marginTop3: "2.5rem",
            width2: "200px",
            height2: "80px",
            paddingBottom1: '',
            color: flowerTheme.colors.gray3,
            padding3: "6px 31px",
            bgColor1: "transparent",
            borderColor1: "",
            color1: flowerTheme.colors.brandDark,
            fontSize1: "var(--type-scale-18)",
            width: "",
            bgColor2: flowerTheme.colors.brandDark,
            borderColor2: flowerTheme.colors.brand,
            bgColor3: "transparent",
            borderColor3: flowerTheme.colors.gray3,
            color2: flowerTheme.colors.gray3,
            marginTop: "",
            marginLeft: "16px",
            width3: "",
            fontSize2: "var(--type-scale-14)",
            padding: "8px 44px",
            maxWidth: "512px",
            marginBottom3: "46px",
            marginTop1: "34px",
            maxWidth1: "290px",
            marginBottom4: "90px",
            color3: flowerTheme.colors.brandDark,
            color4: "inherit",
            fontWeight: "var(--weight-bold)",
            marginBottom5: "24px",
            marginBottom6: "26px",
            borderBottom2: "",
            lineHeight: "",
            fontFamily: "",
            fontSize3: "",
            linHeight2: "",
            color5: flowerTheme.colors.brandDark,
            right: "0",
            top: "0.625rem",
            fill: flowerTheme.colors.gray3,
            width4: "inherit",
            height4: "inherit",
            top2: "0.325rem",
            margin: "0 8px 3% 8px",
            maxWidth2: "727px",
            bgColor: "",
            border: "",
            borderRadius: "",
            maxWidth3: "",
            width5: "",
            minHeight3: "",
            width6: "132px",
            minHeight4: "",
            border2: `1px solid ${flowerTheme.colors.brandDark}`,
            marginLeft2: "",
            borderRadius2: "",
            color6: flowerTheme.colors.white,
            bgColor4: "transparent",
            color7: "",
            border3: "",
            borderColor: flowerTheme.colors.brand,
            paddingRight: "40px",
            padding5: "",
            width7: "",
            height: "",
            fontSize: '',
            padding4: "",
            width8: "",
            height5: "",
            borderRadius3: "",
            fontSize5: "",
            margin3: "",
            paddingLeft: "",
            marginBottom: ".75rem",
            marginBottom7: ".25rem",
            fontSize4: "",
            fontSize6: "",
            fontSize7: "",
            padding6: "",
            fontSize8: "",
            padding7: "",
            width9: "16px",
            height6: "16px",
            alignSelf: "center",
            top3: "",
            right3: "",
            fill2: "",
            width10: "",
            height7: "",
            width11: "",
            height8: "",
            marginBottom8: "",
            fontSize9: "",
            bgImage: "url(/images/Verification_Image.jpg)",
            fontFamily5: "var(--font-Moderat-Medium)",
            marginLeft3: "20px",
            fontFamily1: "var(--font-Moderat-Bold)",
            fontWeight1: "var(--weight-bold)",
            radius: "0",
            color12: flowerTheme.colors.brandDark,
            color13: ""
        }
    },
    cartItemsStyles:{
        color1: flowerTheme.colors.webGrey4,
        color2: flowerTheme.colors.gray4,
    },
    cartConfig: {
        mainConfig: {
            button1: (props) => <Button {...props} />,
            className1: "t-shelf-heading-flower",
            className2: "t-eyebrow-flower",
            className3: "t-eyebrow-flower t-font-Moderat-Bold",
            className4: "t-font-Moderat-Bold",
            className5: "t-font-Moderat-Medium",
            className6: "t-font-Moderat-Bold",
            className7: "checkoutBtn",
            dark: false,
            className8: "t-font-Moderat-Medium",
            className9: "t-font-Moderat-Medium",
            className10: "",
            promoComponent: true,
        },
        mainStyled:{
            font1: "",
            bgColor: "",
            color1: flowerTheme.colors.gray3,
            color2: "",
            bgColor2: "",
            bgColor3: "",
            color3: "",
            color4: flowerTheme.colors.gray1,
            color5: "",
            borderBottom: `1px solid ${flowerTheme.colors.gray2}`,
            font2: "",
            color6: "",
            color7: flowerTheme.colors.gray3,
            color8: flowerTheme.colors.gray4,
            bgColor4: flowerTheme.colors.gray1,
            color9: flowerTheme.colors.brand,
            bgColor5: "",
            color10: "",
            color11: "#888888",
        },
        emptyCart: {
            className1: "t-shelf-heading-flower",
            className2: "t-font-Moderat-Medium",
            color1: flowerTheme.colors.gray3,
        },
        addOnsProps: {
            className1: "t-body-flower",
            className2: "t-font-Moderat-Medium",
            color1: "",
            color2: flowerTheme.colors.gray3,
        },
        addOnsShelfProps: {
            className1: "t-card-copy",
            bgColor: flowerTheme.colors.white
        },
        cartItemProps: {
            className1: "t-font-Moderat-Medium",
            className2: "t-font-Moderat-Bold",
            className3: "t-font-Moderat-Mono-Regular",
            className4: "t-font-Moderat-Medium",
            className5: "t-font-Moderat-Mono-Regular t-list-footnote",
            className6: "t-font-Moderat-Medium",
            className7: "t-font-Moderat-Bold",
        },
        productCounterProps: {
            color1: rebudTheme.colors.gray2,
            color2: "#fff",
        },
        cartItemPromotionProps: {
            className1: "t-body-flower ",
            button: (props) => <ButtonSecondary {...props} />,
            className2: "t-body-flower",
            fontFamily: "var(--font-Moderat-Mono)",
            bgColor: "",
            bgColor2: flowerTheme.colors.brandDark,
            color1: flowerTheme.colors.white,
            color2: flowerTheme.colors.brandDark,
            fill: flowerTheme.colors.brandDark,
            bgColor3: "var(--background-color)",
        }
    },
    productDetailsConfig:{
        viewPersonality: false,
        button: (props) => <Button {...props} dark />,
        className1: "t-paragraph-flower",
        className2: "t-shop-flower",
        className3: "t-font-Moderat-Medium",
        className4: "t-footer-flower",
        className5: "t-footer-flower t-font-Moderat-Bold",
        className6: "t-eyebrow-flower t-font-Moderat-Bold",
        className7: "t-shelf-heading-flower",
        className8: "t-paragraph-flower",
        className9: "t-lineage-flower",
        className10: "t-paragraph-flower",
        className11: "t-shelf-heading-flower",
        className12: "t-font-Moderat-Medium",
        cardText: "ADD TO BAG",
        productDetailsConfigStyled: {
            font: "",
            marginTop: "",
            marginBottom: "",
            margin: "25px 0 -30px 0",
            color1: "",
            color2: "",
            color3: "",
            color4: "",
            color5: "",
        },
        isFirst: "Personality",
        productDescriptionDetailsConfigStyled: {
            font: "var(--font-Moderat-Bold)",
            fontWeight: "var(--weight-bold)",
            lineHeight1: "1.17",
            fontSize1: "var(--type-scale-22)",
            lineHeight2: "1.33",
            fontSize2: "var(--type-scale-24)",
            lineHeight3: "",
            letterSpacing: ".5px",
            transition: "var(--button-transition)",
            font2: "var(--font-Moderat-Medium)",
            fontSize3: "var(--type-scale-16)",
            fontSize4: "var(--type-scale-18)",
        },
        productCardConfig:{
            className1: history.location.pathname === '/' ? "t-footer-flower" : "t-paragraph-flower",
            className2: history.location.pathname === '/' ? "t-footer-flower" : "t-shelf-link-bold-flower",
            className3:  history.location.pathname === '/' ? "t-footer-flower" : "t-shelf-link-bold-flower",
            className4: "t-eyebrow-flower t-font-Moderat-Bold",
            className5: history.location.pathname === '/' ? "t-paragraph-flower" : "t-lineage-flower",
            className6: history.location.pathname === '/' ? "t-shelf-link-light-flower" : "t-paragraph-flower",
            className7: "",
            className8: "t-font-Moderat-Mono-Regular",
            className9: "t-font-Moderat-Bold",
            className10: history.location.pathname === '/' ? "t-eyebrow-flower t-font-Moderat-Medium" : "t-footnote t-font-Moderat-Medium",
            productCardConfigStyled: {
                color1: "",
                paddingLeft: "",
                paddingRight: "",
                marginRight: "6px",
                color2: flowerTheme.colors.gray7,
                borderRadius: "50px",
                font: "var(--font-Moderat-Medium)",
                bgColor1: flowerTheme.colors.brandDark,
                borderColor1: flowerTheme.colors.brandDark,
                color: flowerTheme.colors.white,
                bgColor2: flowerTheme.colors.brandDark,
                color3: flowerTheme.colors.brandDark,
                lineHeight: 1.8,
                color4: "",
                color5: "",
                color6: "",
                color7: "",
                color8: "",
            },
            cardText: "ADD TO BAG",
            className11: "t-font-Moderat-Mono-Regular",
        },
    },
    homeConfig: {
        personalityActive: false,
        dynamicShelfActive: false,
        heroBannerActive:  true,
        brandSpotlightActive:  true,
        productSalesActive:  true,
        staffPicksActive:  true,
        discoverAllActive:  true,
        styles: {
            bgColor: flowerTheme.colors.white,
            fontFamily: '',
            gridTemplate: "repeat(auto-fill, minmax(222px, 1fr))",
        },
        heroBanner:{
            bannerText: false,
            styles:{
                display: "",
                gridTemplateColumns: "",
                marginTop: "",
                minHeight: "350px",
                marginTop1: "",
                marginBottom: "",
                marginLeft: "",
                width: "",
                marginLeft1: "",
                width1: "",
                hover: "",
                maxWidth: "",
            }
        },
        sectionShelfGridConfig: {
            className1: "t-shelf-heading-flower",
            className2: "t-shelf-link-mono-flower",
            styles:{
                fontFamily: "",
                marginTop: "2.5em",
                color: flowerTheme.colors.brandDark,
                display: "inline-flex",
                paddingBottom: "",
                backgroundImage: ``,
                backgroundPosition: "", /*OR bottom left*/
                backgroundSize: "",
                backgroundRepeat: "",
                transition: "",
                position: "",
                color1: everscoreTheme.colors.gray3,
                margin1: "8px",
                backgroundPosition1: "",
                backgroundSize1: "",
                textDecoration: "underline",
                marginTop1: "4px",
                borderColor: everscoreTheme.colors.brandDark,
                fontSize: "",
                fontFamily1: "",
                fontSize1: "",
                fontSize2: "",
            }
        },
        brandSpotlightConfig:{
            subHeading: true,
            presentation: true,
            className1: "t-shelf-link-light-flower",
            className2: "t-shelf-heading-flower",
            className3: "t-paragraph-flower",
            className4: "t-paragraph-flower",
            styles: {
                bgColor: flowerTheme.colors.brandLight,
                marginLeft: "0",
                width: "calc(100% - 32px)",
                maxWidth: "100%",
                marginLeft1: "0",
                padding: "0 0 0 54px",
                maxWidth1: "1744px",
                width1: "calc(100% - 64px)",
                gridTemplateColumns: "2fr 3fr",
                gridColumnGap: "50px",
                marginLeft2: "0",
                maxWidth2: "1744px",
                width2: "100%",
                padding1: "",
                gridColumn: 1,
                color: flowerTheme.colors.brandDark,
                fontFamily: "",
                textTransform: "",
                paddingBottom: "1.5rem",
                marginBottom: "auto",
                color1: flowerTheme.colors.brandDark,
                fontSize: "",
                fontFamily1: "",
                fontSize1: "",
                padding2: "12px 10px",
                maxWidth3: "225px",
                width3: "100%",
                border: `1px solid #161115`,
                borderRadius: "22px",
                fontSize2: "",
                color3: "",
                bgColor1: "",
                width4: "",
                padding3: "",
                fontSize3: "",
                bgColor2: flowerTheme.colors.brand,
                gridColumn2: 2,
                display: "",
                justifyContent: "",
                alignItems: "",
                padding4: "",
                paddingTop: "4.75rem",
                color2: flowerTheme.colors.brandDark,
                lineHeight: "",
                color4: flowerTheme.colors.brandDark,
                borderColor: flowerTheme.colors.brand,
                color5: flowerTheme.colors.white
            }
        },
        brandCardConfig:{
            className1: "t-footer-everscore",
            visibleBrandDesc: true,
            styles: {
                paddingLeft : "",
                color: flowerTheme.colors.brandDark,
                color1: flowerTheme.colors.gray3,
                border: `3px solid ${flowerTheme.colors.white}`,
                borderRadius: "24px",
                width: "100%",
                margin: "",
                paddingLeft1: "",
                marginLeft1: "",
                fontSize: "18px",
                fontFamily: "",

            }
        }

    },
    privacyConfig:{
        className1: 't-shelf-heading-flower',
        fontFamily: 'Moderat-Medium',
        className2: 't-shelf-link-mono-flower t-bold',
    },
    termsConfig:{
        className1: 't-shelf-heading-flower',
        fontFamily: 'Moderat-Medium',
        className2: 't-shelf-link-mono-flower t-bold',
        className3: 't-font-Moderat-Bold',
        className4: 't-font-Moderat-Medium',
    },
    orderDetailsConfig: {
        mainConfig: {
            className1: 't-font-Moderat-Mono-Regular',
            className2: 't-font-Moderat-Bold',
            className3: 't-font-Moderat-Medium',
        },
        orderRateConfig: {
            logoName: 'flower-header-logo',
            className1: '',
            className2: '',
            button: (props) => <ButtonOutline {...props} />,
        },
        timeLineStyledConfig: {
            bgColor: flowerTheme.colors.brandDark
        }
    }
}
export default configFlower;