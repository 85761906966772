import { userService } from '../services';
import {
    GET_LANDING_DATA_LOADING,
    GET_LANDING_DATA,
    GET_LANDING_DATA_FAILED,
    SET_LANDING
} from "../config/actionTypes";


export const landingActions = {
    getLandingData,
};

function getLandingData(landing_url) {
    return dispatch => {
        dispatch(setLanding())
        dispatch(landingDataLoading());
        userService.GetInfo(`/landing-pages/${landing_url}`, '')
            .then(
                landingData => {
                    dispatch(success(landingData));
                },
            (err) =>{
            dispatch(failed(err));
        }
            );
    };
    function landingDataLoading() { return { type: GET_LANDING_DATA_LOADING, loading: true } }
    function success(data) { return { type: GET_LANDING_DATA, payload: data, loading: false } }
    function failed(error) { return { type: GET_LANDING_DATA_FAILED, error: error}}
    function setLanding() { return {type: SET_LANDING}}
}

