import PropTypes from 'prop-types';

export const aeroPayFastLinkPT = PropTypes.arrayOf(PropTypes.exact({
  bankAccounts: PropTypes.arrayOf(PropTypes.exact({
    accountLast4: PropTypes.string,
    accountType: PropTypes.string,
    bankAccountId: PropTypes.string,
    bankName: PropTypes.string,
    externalBankAccountId: PropTypes.string,
    isSelected: PropTypes.string,
    name: PropTypes.string,
    plaidToken: PropTypes.string,
    status: PropTypes.string,
    userId: PropTypes.string,
  })),
  fastlinkURL: PropTypes.string,
  success: PropTypes.bool,
  token: PropTypes.string,
  username: PropTypes.string,
}));

