import styled from "styled-components";

const StyledEmailSubcriptionCTAEverscore = styled.section`
  padding: 0 32px 62.56px;
  background-color: ${({theme}) => theme.colors.brandDark};
  color: ${({theme}) => theme.colors.brandLight};
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  @media (max-width: 859px) {
    padding: 0 32px 38.2px;
  }
      
  .email-sub__heading {
    margin-bottom: 11.18px;
    margin-top: 56.85px;
    font-size: 24px;
    line-height: 28.8px;
    color:  ${({theme}) => theme.colors.everscoreSauvignon};
    font-family: var(--font-NHaasGroteskDSPro-65Md);
    @media (max-width: 859px) {
      margin-bottom: 6.56px;
      margin-top: 25.99px;
    }
  }

  .email-sub__copy {
    font-size: 16px;
    line-height: 19.2px;
    color:  ${({theme}) => theme.colors.everscoreSauvignon};
    font-family: var(--font-NHaasGroteskDSPro-55Rg);
    @media (max-width: 859px) {
      font-size: 14px;
      line-height: 16.8px;
    }
  }

  .email-sub__form {
    margin-top: 34.86px;
    display: flex;
    width: 100%;
    font-family: var(--font-NHaasGroteskDSPro-55Rg);

    @media (max-width: 859px) {
      flex-direction: column;
      align-items: center;
      max-width: 400px;
    }
    
    @media (min-width: 860px) {
      max-width: 600px;
    }
  }

  .email-sub__input {
    padding: 0.875rem 16px;
    flex-shrink: 0;
    background-color: ${({ theme }) => theme.colors.brandDark};
    border: 1px solid ${({ theme }) => theme.colors.everscoreSauvignon};
    color:  ${({theme}) => theme.colors.everscoreSauvignon};
    font-family: var(--font-NHaasGroteskDSPro-55Rg);
    font-size: var(--type-scale-16);
    transition: var(--button-transition);

    @media (max-width: 859px) {
      width: 100%;
    }
    @media (min-width: 860px) {
      width: calc(50% - .5rem);
    }

    &::placeholder {
      opacity: .68;
      color: ${({ theme }) => theme.colors.brandLight};
      font-weight: var(--weight-normal);
      @media (max-width: 859px) {
        text-align: center;
      }
    }

    &:hover,
    &:focus,
    &:active {
      outline: none;
      background-color: ${({ theme }) => theme.colors.brand};

      .email-sub__svg {
        opacity: 1;
      }
    }
    &:disabled {
      background-color: ${({ theme }) => theme.colors.gray2};
      border: 1px solid ${({ theme }) => theme.colors.gray2};
      color: ${({ theme }) => theme.colors.white};
      cursor: no-drop;
    }
  }

  .email-sub__button {
    padding: 0.875rem 2rem;
    flex-shrink: 0;
    text-align: left;
    background-color: ${({ theme }) => theme.colors.brandLight};
    border: 1px solid transparent;
    color: ${({ theme }) => theme.colors.brandDark};
    font-size: var(--type-scale-16);
    //font-weight: var(--weight-bold);
    transition: var(--button-transition);

    &:hover,
    &:focus {
      outline: none;
      color: ${({ theme }) => theme.colors.brandLight};
      background-color: ${({ theme }) => theme.colors.brandDark};
      border-color: ${({ theme }) => theme.colors.brandLight};
    }
    &:disabled {
      background-color: ${({ theme }) => theme.colors.gray2};
      border: 1px solid ${({ theme }) => theme.colors.gray2};
      color: ${({ theme }) => theme.colors.white};
      cursor: no-drop;
    }

    @media (max-width: 859px) {
      margin-top: 13.6px;
      width: 100%;
      text-align: center;
    }
    @media (min-width: 860px) {
      margin-left: 1rem;
      width: calc(50% - .5rem);
    }
  }
`;

export default StyledEmailSubcriptionCTAEverscore;
