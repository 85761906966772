import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  padding: ${props => props.small ? "0.625rem" : "0.875rem"} 16px;
  ${props => props.small ? "" : "width: 100%"};
  border: 1px solid ${({theme: {colors} }) => colors.brandDark};
  background-color: ${({ theme: {colors} }) => colors.white};
  color: ${({ theme: {colors} }) => colors.brandDark};
  font-size: ${props => props.small ? "var(--type-scale-14)" : "var(--type-scale-16)"};
  font-weight: var(--weight-bold);
  letter-spacing: 1px;
  text-align: center;
  flex-shrink: 0;
  transition: var(--button-transition);

  &:hover {
    outline: none;
    background-color: ${({ theme }) => theme.colors.highLightLight};
  }

  &:focus {
    background-color: ${({ theme }) => theme.colors.brandHighLight};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.gray2};
    border: 1px solid ${({ theme }) => theme.colors.gray2};
    color: ${({ theme }) => theme.colors.white};
    cursor: no-drop;
  }
`;

StyledButton.propTypes = {
  small: PropTypes.bool,
  dark: PropTypes.bool,
  outline: PropTypes.bool,
  noFocus: PropTypes.bool,
};

export default React.memo(props => <StyledButton {...props} />);
