import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import StyledHeroPromo from "./HeroPromo.styled";

const HeroPromo = React.memo(props => {
  /* const { heading, copy, imgSrc, url } = props; */
  const { copy, isEverscoreTheme } = props;
  return (
    <StyledHeroPromo>
      <div className="hero-promo__wrap">
        {/* <h2 className="t-section-heading hero-promo__heading">{heading}</h2> */}
        <p className="t-promo-display hero-promo__copy"><span className="hero-promo__copy-wrap">{copy}</span> <Link to="/promotions" className={isEverscoreTheme ? 't-copyright-everscore' : "hero-promo__link"}>See All Deals</Link></p>
      </div>
    </StyledHeroPromo>
  );
});

HeroPromo.propTypes = {
  copy: PropTypes.string.isRequired,
  isEverscoreTheme: PropTypes.bool
};

export default HeroPromo;
