import {
    POST_MEDICAL_ID,
    POST_CARD_ID,
    GET_MEDICAL_ID,
    GET_CARD_ID,
    POST_MEDICAL_ID_REQUEST,
    POST_MEDICAL_ID_FAIL,
    POST_CARD_ID_REQUEST,
    POST_CARD_ID_FAIL,
    GET_MEDICAL_ID_REQUEST,
    GET_MEDICAL_ID_FAIL,
    GET_CARD_ID_REQUEST, GET_CARD_ID_FAIL
} from '../config/actionTypes';

export function uploadMedicalId(state = {}, action) {
    switch (action.type) {
        case POST_MEDICAL_ID:
            return {
                medicalId: action.medicalId,
                loading: action.loading
            };
        case POST_MEDICAL_ID_REQUEST:
            return {
                medicalId: action.medicalId,
                loading: action.loading
            };
        case POST_MEDICAL_ID_FAIL:
            return {
                medicalId: action.err,
                loading: action.loading
            };
        default:
            return state
    }
}

export function uploadCardId(state = {}, action) {
    switch (action.type) {
        case POST_CARD_ID:
            return {
                cardId: action.cardId,
                loading: false
            };
        case POST_CARD_ID_REQUEST:
            return {
                medicalId: action.cardId,
                loading: true
            };
        case POST_CARD_ID_FAIL:
            return {
                medicalId: action.err,
                loading: false
            };
        default:
            return state
    }
}

export function getMedicalId(state = {}, action) {
    switch (action.type) {
        case GET_MEDICAL_ID:
            return {
                medicalId: action.medicalId,
                loading: action.loading
            };
        case GET_MEDICAL_ID_REQUEST:
            return {
                ...state,
                loading: action.loading
            };
        case GET_MEDICAL_ID_FAIL:
            return {
                medicalId: action.err,
                loading: action.loading
            };
        default:
            return state
    }
}

export function getCardId(state = {}, action) {
    switch (action.type) {
        case GET_CARD_ID:
            return {
                cardId: action.cardId,
                loading: false
            };
        case GET_CARD_ID_REQUEST:
            return {
                medicalId: action.medicalId,
                loading: true
            };
        case GET_CARD_ID_FAIL:
            return {
                medicalId: action.err,
                loading: false
            };
        default:
            return state
    }
}