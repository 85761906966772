import { userService } from '../services';
import {
    GET_FAST_LINK,
    GET_FAST_LINK_LOADING,
    AEROPAY_USER_BANK_INFO,
    AEROPAY_USER_BANK_INFO_LOADING,
    EMPTY_FAST_LINK, EMPTY_AEROPAY_USER_BANK_INFO, START_LOADING_CHANGE_AEROPAY, END_LOADING_CHANGE_AEROPAY
} from '../config/actionTypes';
import * as toast from "../components/utility/toast";
import {ordersActions} from "./orders.actions";
import {sendMessageApi} from "../helpers/printerMessage";

export const aeropayActions = {
    getFastLink,
    postAeroPayAccountAggregate,
    editOrderDetailsPaymentToAeropay,
    emptyData,
    disconnectAeropay
};

function getFastLink() {
    return dispatch => {
        dispatch(fastLinkLoading());
        return userService.GetInfo('/aeropay/fast-link', '')
            .then(
                fastLink => {
                    dispatch(success(fastLink));
                },
                error => {
                    if(error && error.message){
                        toast.error({ text: error.message })
                    }
                }
            );
    };
    function fastLinkLoading() { return { type: GET_FAST_LINK_LOADING, loading: true } }
    function success(fastLink) { return { type: GET_FAST_LINK, fastLink } }
};

function postAeroPayAccountAggregate(data) {
    return dispatch => {
        dispatch(loading());
        dispatch(loadingOrder());
        userService.PostInfo('/aeropay/account-aggregate', data)
            .then(
                bankInfo => {
                    dispatch(success(bankInfo));
                },
                error => {
                    if(error && error.message){
                        toast.error({ text: error.message })
                        dispatch(endLoading());
                    }
                }
            );
    };
    function loadingOrder() { return { type: START_LOADING_CHANGE_AEROPAY } }
    function endLoading() { return { type: END_LOADING_CHANGE_AEROPAY } }
    function loading() { return { type: AEROPAY_USER_BANK_INFO_LOADING, loading: true } }
    function success(bankInfo) { return { type: AEROPAY_USER_BANK_INFO, bankInfo } }
}
function disconnectAeropay(id) {
    return dispatch => {
        return userService.DeleteInfo(`/aeropay/bank-account/${id}`, '')
            .then(
                data => {}
            )
            .catch( (err) => {}
            );
    }


}
function editOrderDetailsPaymentToAeropay(orderId) {
    return dispatch => {
        dispatch(loading())
        return userService.PostInfo(`/aeropay/change/${orderId}`, {})
            .then(
                data => {
                    const urlParams = {
                        orderId
                    };
                    const queryParams = null;
                    dispatch(ordersActions.getUserOrderById("",queryParams,urlParams));
                    dispatch(emptyAeroPayFastLink())
                    dispatch(emptyAeroPayBankData())
                    toast.success({ text: 'Payment method successfully changed.'})
                },
                error => {
                    dispatch(emptyAeroPayFastLink())
                    dispatch(emptyAeroPayBankData())
                    dispatch(endLoading())
                    if(error && error.message){
                        toast.error({ text: error.message })
                    }
                }
            );
    };
    function emptyAeroPayFastLink() { return { type: EMPTY_FAST_LINK } }
    function loading() { return { type: START_LOADING_CHANGE_AEROPAY } }
    function endLoading() { return { type: END_LOADING_CHANGE_AEROPAY } }
    function emptyAeroPayBankData() { return { type: EMPTY_AEROPAY_USER_BANK_INFO } }
}
function emptyData() {
    return dispatch => {
        dispatch(emptyAeroPayFastLink())
        dispatch(emptyAeroPayBankData())
        dispatch(endLoading())

    };
    function emptyAeroPayFastLink() { return { type: EMPTY_FAST_LINK } }
    function emptyAeroPayBankData() { return { type: EMPTY_AEROPAY_USER_BANK_INFO } }
    function endLoading() { return { type: END_LOADING_CHANGE_AEROPAY } }
}
