import { GET_BLOG_POSTS,
         GET_BLOG_POSTS_LOADING,
         GET_BLOG_POSTS_CATEGORIES,
         GET_BLOG_POSTS_CATEGORIES_LOADING,
         GET_BLOG_POST_DETAILS_LOADING,
         GET_BLOG_POST_DETAILS
        } from '../config/actionTypes';

export function blogPosts(state = {}, action) {
    switch (action.type) {
        case GET_BLOG_POSTS:
            return {
                blogPosts: action.blogPosts,
                loading: action.loading
            };
        case GET_BLOG_POSTS_LOADING:
            return {
                blogPosts: action.blogPosts,
                loading: action.loading
            };
        default:
            return state
    };
};

export function blogPostsCategories(state = {}, action) {
    switch (action.type) {
        case GET_BLOG_POSTS_CATEGORIES:
            return {
                blogPostsCategories: action.blogPostsCategories,
                loading: action.loading
            };
        case GET_BLOG_POSTS_CATEGORIES_LOADING:
            return {
                blogPostsCategories: action.blogPostsCategories,
                loading: action.loading
            };
        default:
            return state
    };
};

export function blogPostDetails(state = {}, action) {
    switch (action.type) {
        case GET_BLOG_POST_DETAILS:
            return {
                blogPostDetails: action.blogPostDetails,
                loading: action.loading
            };
        case GET_BLOG_POST_DETAILS_LOADING:
            return {
                blogPostDetails: action.blogPostDetails,
                loading: action.loading
            };
        default:
            return state
    };
};
