import PropTypes from "prop-types";
import { connect } from "react-redux";
import React from "react";
import StyledHeaderSimple from "./HeaderSimple.styled";
import { Link } from "react-router-dom";
import SVGIcon from "../utility/SVGIcon"
import { storeType, storeName } from "../../config/constants";
import { useLocation } from "react-router-dom";
import { storeInfoPT }  from "../../config/prop-types";

const HeaderSimple = React.memo(props => {
    const { className, storeInfo } = props;

    // Look up current route
    const currentRoute = useLocation();
    const formatPathName = (str) => {
        //Replace all "/" from pathname with space
        let lettersOnly = ('' + str).replace(/[/]/g, ' ');
        // Uppercase first letter of string
        let formatPath = lettersOnly.charAt(1).toUpperCase() + lettersOnly.slice(2);
        
        return formatPath;
    };

    return (
        <StyledHeaderSimple className={className}>
            <h1 className="t-hidden">
                {storeName}
                {currentRoute.pathname === "/" ?
                    ""
                    :
                    `- ${formatPathName(currentRoute.pathname)}`
                }
            </h1>
            <Link to="/" className="header-simple__link">
                {storeType === 'rebud' ?
                <>
                    <SVGIcon name="flower-header-logo" className="header-simple__logo" />
                    </>
                    :
                    <img src={storeInfo && storeInfo.marketplace_logo} alt={storeInfo && storeInfo.name} className="header-simple__logo-other" />
                }
                <span className="t-hidden">{storeName} home</span>
            </Link>
        </StyledHeaderSimple>
    );
});

const mapStateToProps = ({ storeInfo }) => ({
    storeInfo: storeInfo.storeInfo
});

HeaderSimple.propTypes = {
    className: PropTypes.string,
    storeInfo: storeInfoPT,
};

export default connect(mapStateToProps)(HeaderSimple);
