import PropTypes from 'prop-types';

export const promoBarPT = PropTypes.arrayOf(PropTypes.exact({
  created_at: PropTypes.string,
  id: PropTypes.string,
  store_id: PropTypes.string,
  title: PropTypes.string,
  updated_at: PropTypes.string,
}));

