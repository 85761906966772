import styled from "styled-components";

const StyledHeaderMainSecondary = styled.div`
  width: 100%;
  border-bottom: ${(props) => props.themeProps.borderBottom};

  .header-main__wrap {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${(props) => props.themeProps.bgColor4};
    
    @media (max-width: 859px) {
      padding: 1rem 8px;
      justify-content: center;
    }
    @media (min-width: 860px) {
      padding: ${(props) => props.themeProps.padding};
      min-height: 60px;
    }
  }

  .autocomplete-dropdown-container {
    top: 2.375rem;
  }
`;

export default StyledHeaderMainSecondary;
