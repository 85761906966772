import {
    GET_SEARCH_RESULTS,
    GET_SEARCH_RESULTS_CATALOG,
    LOAD_MORE_SEARCH_RESULTS_CATALOG,
    CHANGE_PRODUCT_SEARCH,
    GET_SEARCH_RESULTS_CATALOG_LOADING,
    SEARCH_BRANDS, GET_SHOP_BANNER
} from '../config/actionTypes';
import {search} from "../actions";

export function searchResult(state = {}, action) {
    switch (action.type) {
        case GET_SEARCH_RESULTS:
            return {
                result: action.result,
            };
        default:
            return state
    }
}
export function getShopBanner(state = {}, action) {
    switch (action.type) {
        case GET_SHOP_BANNER:
            return {
                shopBanner: action.shopBanner?.pictures ?
                    {
                        generalBanner : action.shopBanner?.pictures.find(item=> item.type === 'General') ,
                        mobileBanner : action.shopBanner?.pictures.find(item=> item.type === 'Mobile'),
                        bannerUrl : action.shopBanner?.banner_url
                    }
                    : '',
            };
        default:
            return state
    }
}
export function searchResultShop(state = {}, action) {
    const listChange = (list)=>{
        const newList = list.map(item=>{
            if(item.quantity==="0" && item.children){
               let l;
               item.children.every(item1=>{
                    if(item1.quantity){
                        l = {...item, selectedProduct: item1}
                    } else {
                        return true
                    }
                })
                return l;
            } else  return item
        })
        return newList
    }
    switch (action.type) {


        case GET_SEARCH_RESULTS_CATALOG:
            return {
                result: {...action.result, list : listChange(action.result.list)},
                loading: action.loading,
            };
        case GET_SEARCH_RESULTS_CATALOG_LOADING:
            return {
                result: action.result,
                loading: action.loading,
            };
        case LOAD_MORE_SEARCH_RESULTS_CATALOG:
            return {
                result: {
                    ...state.result,
                    next: action.result.next,
                    list: state.result.list.concat(listChange(action.result.list))
                },
            };
        case CHANGE_PRODUCT_SEARCH:
             const list = state.result.list.map(item=>
                 {
                     if(action.changeProduct.product && (Number(action.changeProduct.product.parent_id) === Number(item.id) || Number(action.changeProduct.product.id) === Number(item.id))){
                         return {...item, selectedProduct: action.changeProduct.product}
                     }
                     else return item
                 }
             );
            return {
                result: {
                    ...state.result,
                    list
                },
            };
        default:
            return state
    }
}
export function searchBrands(state = '', action) {
    switch (action.type) {
        case SEARCH_BRANDS:
            return {
                searchValue: action.searchValue,
            };
        default:
            return state
    }
}