import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const StyledButtonOutlineCurrentLocation = styled.button`
  padding: 4px 10px;
  margin-top: 7px;
  border-radius: 4px;
  border: 1px solid ${({ secondary, theme: { colors } }) => secondary ? colors.gray1 : colors.brandDark};
  background-color: ${({ secondary, theme: { colors } }) => secondary ? colors.gray1 : colors.white};
  color: ${({ secondary, theme: { colors } }) => secondary ? colors.brand : colors.brandDark};
  font-size: var(--type-scale-14);
  font-weight: var(--weight-bold);
  text-align: center;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  transition: var(--button-transition);
  svg{
    padding-right: 5px;
    fill: ${({ theme: { colors } }) => colors.brandDark};
  }
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.brand};
    border-color: ${({ theme }) => theme.colors.brand};
    color: ${({ theme }) => theme.colors.white};
    svg{
      fill: ${({ theme }) => theme.colors.white};
    }
    
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.gray3};
    color: ${({ theme }) => theme.colors.gray3};
    cursor: default;
  }
`;


export default React.memo(props => <StyledButtonOutlineCurrentLocation {...props} />);