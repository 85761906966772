import { userService } from '../services';
import {
    GET_USER_ORDERS,
    GET_USER_ORDERS_SUCCESS,
    GET_USER_ORDER_BY_ID,
    GET_USER_ORDER_BY_ID_SUCCESS,
    POST_ORDER_DRIVER_RATE,
    POST_ORDER_DRIVER_RATE_SUCCESS,
    POST_ORDER_DRIVER_RATE_FAIL,
    CLEAR_RATE, END_LOADING_CHANGE_AEROPAY
} from '../config/actionTypes';
import qs from 'qs';
import {history} from "../helpers";

export const ordersActions = {
    getUserOrders,
    getUserOrderById,
    rateOrderDriver,
    cancelingOrders,
    clearRate
};

function clearRate() {
    return dispatch => {
        dispatch(clear())
    };
    function clear() { return { type: CLEAR_RATE } }
}

function getUserOrders(data, queryParams, urlParams) {
    let endpoint = '/orders/history/:page';
    if (queryParams) {
        endpoint += '?' + qs.stringify(queryParams)
    }
    if (urlParams) {
        Object.keys(urlParams).forEach(k => {
            endpoint = endpoint.replace(':' + k, urlParams[k]);
        });
    }
    return dispatch => {
        dispatch(request())
        userService.GetInfo(endpoint, '')
          .then(
            data => dispatch(success(data))
          );
    };
    function request() { return { type: GET_USER_ORDERS } }
    function success(orders) { return { type: GET_USER_ORDERS_SUCCESS, orders } }
}

function getUserOrderById(data, queryParams, urlParams) {
    let endpoint = '/orders/details/:orderId';
    if (queryParams) {
        endpoint += '?' + qs.stringify(queryParams)
    }
    if (urlParams) {
        Object.keys(urlParams).forEach(k => {
            endpoint = endpoint.replace(':' + k, urlParams[k]);
        });
    }
    return dispatch => {
        dispatch(request())
        userService.GetInfo(endpoint, '')
          .then(
            data => {
                dispatch(endLoading())
                dispatch(success(data))
            }
          );
    };
    function endLoading() { return { type: END_LOADING_CHANGE_AEROPAY } }

    function request() { return { type: GET_USER_ORDER_BY_ID } }
    function success(orderById) { return { type: GET_USER_ORDER_BY_ID_SUCCESS, orderById } }
}

function rateOrderDriver(data, queryParams, urlParams) {
    let endpoint = '/orders/rate-driver/:orderId';
    if (queryParams) {
        endpoint += '?' + qs.stringify(queryParams)
    }
    if (urlParams) {
        Object.keys(urlParams).forEach(k => {
            endpoint = endpoint.replace(':' + k, urlParams[k]);
        });
    }
    return dispatch => {
        dispatch(request())
        userService.PostInfo(endpoint, data)
          .then(
            rate => dispatch(success(rate))
          ).catch(err=>dispatch(fail(err)))
    };
    function request() { return { type: POST_ORDER_DRIVER_RATE } }
    function success(rate) { return { type: POST_ORDER_DRIVER_RATE_SUCCESS, rate } }
    function fail(err) { return { type: POST_ORDER_DRIVER_RATE_FAIL, err } }
}

function cancelingOrders(data, queryParams, urlParams) {
    let endpoint = '/orders/cancel/:orderId';
    if (urlParams) {
        Object.keys(urlParams).forEach(k => {
            endpoint = endpoint.replace(':' + k, urlParams[k]);
        });
    }
    return () => userService.PostInfo(endpoint, {})
          .then(
            () =>  history.push('/account/orders')
          );
}