import PropTypes, { func } from 'prop-types';

const productPT = PropTypes.exact({
  active: PropTypes.bool,
  blob_presence: PropTypes.number,
  brand: PropTypes.string,
  brand_id: PropTypes.string,
  categories: PropTypes.string,
  categories_id: PropTypes.string,
  description: PropTypes.string,
  disable_expiration_sale: PropTypes.bool,
  expirationdates: PropTypes.arrayOf(PropTypes.string),
  government_limit: PropTypes.number,
  id: PropTypes.string,
  instructions: PropTypes.string,
  is_canabis: PropTypes.bool,
  is_staff_pick: PropTypes.bool,
  locationaddress: PropTypes.string,
  locationid: PropTypes.string,
  locationname: PropTypes.string,
  name: PropTypes.string,
  net_weight: PropTypes.number,
  net_weight_g: PropTypes.string,
  orders_count: PropTypes.string,
  parent_id: PropTypes.string,
  personalitiesname: PropTypes.string,
  pictures: PropTypes.arrayOf(PropTypes.string),
  product_cbd: PropTypes.number,
  product_cbd_content: PropTypes.number,
  product_cbg: PropTypes.number,
  product_cbn: PropTypes.number,
  product_delta_8: PropTypes.number,
  product_delta_9: PropTypes.number,
  product_discount_price: PropTypes.number,
  product_discount_quantity: PropTypes.number,
  product_thc: PropTypes.number,
  product_thc_content: PropTypes.number,
  product_thca: PropTypes.number,
  product_type: PropTypes.string,
  product_type_name: PropTypes.string,
  quantity: PropTypes.string,
  sales_discount_percentage: PropTypes.number,
  sales_price: PropTypes.number,
  selling_quantity: PropTypes.number,
  tasting_notes: PropTypes.arrayOf(PropTypes.string),
  thc: PropTypes.string,
  totalQuantity: PropTypes.number,
  unit_price:PropTypes.number,
  uri_param: PropTypes.string,
  variation: PropTypes.string,
  variation_type: PropTypes.string,
});

const orderItemsPT = PropTypes.arrayOf(PropTypes.exact({
  cost: PropTypes.number,
  created_at: PropTypes.string,
  discount_amount: PropTypes.number,
  id: PropTypes.string,
  metrc_label: PropTypes.string,
  order_id: PropTypes.string,
  product: productPT,
  product_id: PropTypes.string,
  quantity: PropTypes.string,
  single_price: PropTypes.bool,
  total_grams: PropTypes.string,
  unit: PropTypes.string,
  updated_at: PropTypes.string,
}));

export const orderPT = PropTypes.exact({
  created_at: PropTypes.string,
  delivery_address: PropTypes.string,
  delivery_cost: PropTypes.number,
  excise_tax: PropTypes.number,
  non_medical_fee: PropTypes.number,
  orderItems: orderItemsPT,
  order_id: PropTypes.string,
  paid: PropTypes.bool,
  sales_tax: PropTypes.number,
  status: PropTypes.string,
  store_id: PropTypes.string,
  subtotal: PropTypes.number,
  tip: PropTypes.number,
  total_cost: PropTypes.string,
});

export const ordersListPT = PropTypes.exact({
  list: PropTypes.arrayOf(orderPT),
  next: PropTypes.bool,
  page: PropTypes.string,
  pages: PropTypes.number,
  previous: PropTypes.bool,
  total: PropTypes.number,
});

export const orderDataPT = PropTypes.exact({
  city_tax: PropTypes.string,
  comment: PropTypes.string,
  created_at: PropTypes.string,
  debit_fee: PropTypes.func,
  delivery_address: PropTypes.string,
  delivery_cost: PropTypes.string,
  discount_amount: PropTypes.string,
  discount_amount_yotpo: PropTypes.string,
  discount_percent: PropTypes.string,
  driver_id: PropTypes.string,
  eta_order: PropTypes.func,
  excise_tax: PropTypes.string,
  in_store_pickup: PropTypes.bool,
  is_driver_rated: PropTypes.bool,
  lat: PropTypes.string,
  lng: PropTypes.string,
  non_medical_fee: PropTypes.string,
  orderItems: orderItemsPT,
  order_id: PropTypes.string,
  paid: PropTypes.bool,
  pay_tender_fee: PropTypes.string,
  paybotic_fee: PropTypes.func,
  payment_type: PropTypes.string,
  referral: PropTypes.func,
  requested_delivery_time: PropTypes.string,
  sales_tax: PropTypes.string,
  sequence_num: PropTypes.func,
  status: PropTypes.string,
  store_id: PropTypes.string,
  subtotal: PropTypes.number,
  tip: PropTypes.string,
  total_cost: PropTypes.string,
  tour_arrival: PropTypes.func,
});

