import React, { useEffect, useRef } from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {userActions} from '../../../actions/index';
import StyledEmailSubscriptionCTA from "./EmailSubscriptionCTAEnjoyable.styled";

const EmailSubscriptionCTAEnjoyable = props => {
   const {dispatch, message} = props;
   const input = useRef();
   const handleSubscribe = e  => {
        e.preventDefault();
        const { email } = e.target;
        const formData = {
            email: email.value.trim()
        };
        return dispatch(userActions.subscribeEmailFooter(formData));
   };
    useEffect(() => {
        if(message && message === 'Success'){
            input.current.value='';
        }
    }, [message]);
  return (
    <StyledEmailSubscriptionCTA>
      <h3 className="email-sub__heading t-section-heading">Friends With Benefits</h3>
      <p className="email-sub__copy t-body">Subscribe for fancy discounts, new arrivals, and other pleasantries</p>
      <form className="email-sub__form"  onSubmit={handleSubscribe} >
        <label className="t-hidden" htmlFor="subEmail">Enter your email address</label>
        <input className="t-form-input email-sub__input" id="subEmail" name="email" type="email" required placeholder="EMAIL ADDRESS" ref={input} />
        <button type="submit" className="email-sub__button">SUBSCRIBE</button>
      </form>
    </StyledEmailSubscriptionCTA>
  );
};

const mapStateToProps = ({alert}) => ({
    message: alert.message
});

EmailSubscriptionCTAEnjoyable.propTypes = {
  message: PropTypes.string,
};

export default connect(mapStateToProps)(EmailSubscriptionCTAEnjoyable);
