import PropTypes from 'prop-types';

export const welcomePromoPT = PropTypes.exact({
  active: PropTypes.bool,
  amount: PropTypes.number,
  amount_spent: PropTypes.number,
  code: PropTypes.string,
  created_at: PropTypes.string,
  deleted_at: PropTypes.string,
  description: PropTypes.string,
  expiration: PropTypes.string,
  id: PropTypes.string,
  is_influencer: PropTypes.bool,
  is_mobile: PropTypes.bool,
  is_percent: PropTypes.bool,
  is_web_visible: PropTypes.bool,
  multi_use: PropTypes.bool,
  picture: PropTypes.string,
  promo_code_type_id: PropTypes.string,
  quantity: PropTypes.number,
  start_date: PropTypes.string,
  store_id: PropTypes.string,
  updated_at: PropTypes.string,
});

