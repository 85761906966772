import {POST_USER_AVATAR, POST_SEND_EMAIL} from '../config/actionTypes';

export function uploadAvatar(state = {}, action) {
    switch (action.type) {
        case POST_USER_AVATAR:
            return {
                avatar: action.avatar,
            };
        default:
            return state
    }
}

export function emailSend(state = {}, action) {
    switch (action.type) {
        case POST_SEND_EMAIL:
            return {
                data: action.data,
            };
        default:
            return state
    }
}