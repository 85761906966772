let user = JSON.parse(localStorage.getItem('user_token') );
const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case 'LOGIN_REQUEST':
      return {
        user: action.user
      };
    case 'LOGIN-SUCCESS':
      return {
        loggedIn: true,
      };
    case 'LOGIN-FAILURE':
      return {
      };
    case 'LOGOUT':
      return {
          loggedIn: false
      };
    default:
      return state
  }
}