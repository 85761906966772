import React, { useState, useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import StyledProfileDropdown from "./ProfileDropdown.styled";
import { Link } from "react-router-dom";
import { authActions } from '../../actions/index';
import SVGIcon from "../utility/SVGIcon";
import {ThemePramsContext} from "../../App";

const ProfileDropdown = React.memo(props => {
  const { className, dispatch, storeInfo } = props;
  const [ clicked, setClicked ] = useState(false);
  const node = useRef();

  const toggleDropdown = () => setClicked (!clicked);

  const handleClick = e => {
    if (node?.current?.contains(e.target)) {
      return;
    }
    setClicked(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);
  // Get Login Status for app from local storage
  const LoginStatus = JSON.parse(localStorage.getItem('LoginStatus'));
  const {headerConfig: {profileDropDownConfig}} = useContext(ThemePramsContext);
  return (
    <StyledProfileDropdown
        aria-haspopup="true"
        aria-expanded={clicked}
        className={className}
        ref={node}
        themeProps={profileDropDownConfig}
    >
      <button className="profile-dropdown__media" onClick={toggleDropdown}>
        {profileDropDownConfig.icon ? <SVGIcon name={profileDropDownConfig.icon} /> : profileDropDownConfig.text}
        <span className="t-hidden">user profile options</span>
      </button>

      <ul className="profile-dropdown__options" role="menu" data-profile-dropdown-state={clicked} onClick={toggleDropdown}>
        { LoginStatus ?
          <>
            {
              storeInfo?.features?.rewardsEnable &&
                <li className="profile-dropdown__option">
                  <Link to="/rewards" refresh="true" role="menuitem" className="t-form-button profile-dropdown__option__link">Rewards</Link>
                </li>
            }

          <li className="profile-dropdown__option">
            <Link to="/account" role="menuitem" className="t-form-button profile-dropdown__option__link">Account</Link>
          </li>
          <li className="profile-dropdown__option">
            <Link to="/account/orders" role="menuitem" className="t-form-button profile-dropdown__option__link">Orders</Link>
          </li>
          <li className="profile-dropdown__option">
            <button role="menuitem"
                    className={`${profileDropDownConfig.className1} profile-dropdown__option__sign-out`}
                    onClick={()=> dispatch(authActions.logOut()) }
            >Log out</button>
          </li>
          </>
          :
          <>
            {
              storeInfo?.features?.rewardsEnable &&
                <li className="profile-dropdown__option">
                  <Link to="/rewards" refresh="true" role="menuitem"
                        className="t-form-button profile-dropdown__option__link">Rewards</Link>
                </li>
            }
          <li className="profile-dropdown__option">
            <Link to="/login" role="menuitem" className="t-form-button profile-dropdown__option__link">Log In</Link>
          </li>
          <li className="profile-dropdown__option">
            <Link to="/registration" role="menuitem" className="t-form-button profile-dropdown__option__link">Register</Link>
          </li>
          </>
        }
      </ul>
    </StyledProfileDropdown>
  );
});

ProfileDropdown.propTypes = {
  className: PropTypes.string
};

export default ProfileDropdown;