import PropTypes from 'prop-types';
import { productsPT } from "./productPropTypes";

export const searchCriteriaPT = PropTypes.exact({
  brand: PropTypes.arrayOf(PropTypes.string),
  page: PropTypes.number,
  product_ids: PropTypes.arrayOf(PropTypes.string),
  total: PropTypes.number,
  uri_param: PropTypes.string,
});

export const brandProductsPT = PropTypes.exact({
  description: PropTypes.string,
  id: PropTypes.string,
  list: productsPT,
  meta_alt_tag: PropTypes.string,
  meta_description: PropTypes.string,
  meta_title: PropTypes.string,
  name: PropTypes.string,
  next: PropTypes.bool,
  page: PropTypes.number,
  pages: PropTypes.number,
  picture: PropTypes.string,
  previous: PropTypes.bool,
  searchCriteria: searchCriteriaPT,
  total: PropTypes.number,
  uri_param: PropTypes.string,
});

export const brandsSearchListPT = PropTypes.arrayOf(PropTypes.exact({
  description: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  picture: PropTypes.string,
  uri_param: PropTypes.string,
}));