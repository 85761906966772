import styled from "styled-components";

const StyledMobileMainMenuCard = styled.nav`
  width: 100%;
  
  .collapse__element {
    padding: 0 10px;
    cursor: pointer;
  }

  .collapse__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
    padding: 8px 10px;
    color: ${({theme}) => theme.colors.brandDark};
  }

  .plus-minus_icon {
    display: flex;
    justify-content: center;
    flex-direction: column;
    transform: rotate(180deg);
  }

  .collapse__body {
    margin: 10px 25px;
    color: ${({theme}) => theme.colors.brandDark};
    overflow: hidden;
    transition: all 0.4s ease-in-out;
    height: 0;
    
    &.hidden {
      margin: 0 25px
    }
  }

  .collapse__item {
    width: 100%;
    padding: 5px 0;
    display: block;
    text-decoration: none;
    color: ${({theme}) => theme.colors.brandDark};
  }
`;

export default StyledMobileMainMenuCard;